import { Box, List } from '@mui/material';
import LoadingScreen from 'components/loading-screen';
import BlankPage from 'pages/dashboard/BlankPage';
import PropTypes from 'prop-types';
import { useGetOneUserById } from 'services/userServices';
import { useAuthContext } from '../../../auth/useAuthContext';
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgColor from '../../svg-color';
import NavList from './NavList';
import { StyledSubheader } from './styles';

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  whatsapp: icon('ic_whatsapp'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  category: icon('ic_category'),
  subcategory: icon('ic_sub_category'),
  supercategory: icon('ic_super_category'),
};

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavSectionVertical({ isCollapse = false, ...other }) {
  const { userdata } = useAuthContext();

  const userId = localStorage.getItem('userId');
  // console.log('oneUserData', userId);

  const { data: user, isLoading, isError } = useGetOneUserById(userId);

  if (isLoading) return <LoadingScreen />;

  if (isError) return <BlankPage />;

  // console.log('oneUserData', user);

  return (
    <Box {...other}>
      {user?.designations?.addstaff === true ? (
        <List disablePadding sx={{ px: 2 }}>
          <StyledSubheader>MANAGEMENT</StyledSubheader>
          <NavList
            data={{ title: 'Dashboard', path: PATH_DASHBOARD.root, icon: ICONS.dashboard }}
            depth={1}
          />
          {user?.designations?.addstaff === true ? (
            <NavList
              data={{
                title: 'Staff Management',
                path: PATH_DASHBOARD.staff.list,
                icon: ICONS.user,
              }}
              depth={1}
            />
          ) : null}

          {/* {user?.designations?.dealerManagement === true ? (
            <NavList
              data={{
                title: 'Reporter Management',
                path: PATH_DASHBOARD.reporter.list,
                icon: ICONS.user,
              }}
              depth={1}
            />
          ) : null} */}
        </List>
      ) : null}

      {user?.designations?.news === true || user?.designations?.advertisement === true ? (
        <List disablePadding sx={{ px: 2 }}>
          <StyledSubheader>POSTS</StyledSubheader>
          {user?.designations?.news === true ? (
            <NavList
              data={{
                title: 'News Management',
                path: PATH_DASHBOARD.news.list,
                icon: ICONS.user,
              }}
              depth={1}
            />
          ) : null}

          {user?.designations?.advertisement === true ? (
            <NavList
              data={{
                title: 'Advertisement Management',
                path: PATH_DASHBOARD.advertisement.list,
                icon: ICONS.user,
              }}
              depth={1}
            />
          ) : null}
        </List>
      ) : null}

      {user?.designations?.reporterenquiry === true ||
      user?.designations?.contactenquiries === true ? (
        <List disablePadding sx={{ px: 2 }}>
          <StyledSubheader>ENQUIRY</StyledSubheader>
          {user?.designations?.reporterenquiry === true ? (
            <NavList
              data={{
                title: 'Reporter Enquries',
                path: PATH_DASHBOARD.reporterenquries.list,
                icon: ICONS.chat,
              }}
              depth={1}
            />
          ) : null}

          {user?.designations?.contactenquiries === true ? (
            <NavList
              data={{
                title: 'Contact Enquries',
                path: PATH_DASHBOARD.contactenquries.list,
                icon: ICONS.chat,
              }}
              depth={1}
            />
          ) : null}
        </List>
      ) : null}

      {user?.designations?.category === true || user?.designations?.designation === true ? (
        <List disablePadding sx={{ px: 2 }}>
          <StyledSubheader>CONFIGURATION</StyledSubheader>
          {user?.designations?.category === true ? (
            <NavList
              data={{
                title: 'Category',
                path: PATH_DASHBOARD.category.list,
                icon: ICONS.category,
              }}
              depth={1}
            />
          ) : null}

          {user?.designations?.whatsappGroup === true ? (
            <NavList
              data={{
                title: 'Whatsapp Groups',
                path: PATH_DASHBOARD.whatsappgroup.list,
                icon: ICONS.whatsapp,
              }}
              depth={1}
            />
          ) : null}
          {user?.designations?.designation === true ? (
            <NavList
              data={{
                title: 'Designation',
                path: PATH_DASHBOARD.designation.list,
                icon: ICONS.user,
              }}
              depth={1}
            />
          ) : null}
        </List>
      ) : null}
    </Box>
  );
}
