/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable arrow-body-style */

import PropTypes from 'prop-types';

import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useCreateProductImageUrl } from 'services/productServices';
// @mui
// components
import { Upload } from '../../../../components/upload';

// ----------------------------------------------------------------------

AddProductImages.propTypes = {
  tag: PropTypes.string,
};

export default function AddProductImages({ tag }) {
  const { trigger } = useForm();
  const { control, setValue, watch, resetField } = useFormContext();
  const values = watch();

  function mergeArray(arr) {
    const result = [];
    const map = new Map();

    for (const obj of arr) {
      if (map.has(obj.title)) {
        const existingObj = map.get(obj.title);
        // eslint-disable-next-line no-restricted-syntax
        for (const value of obj.values) {
          if (!existingObj.values.some((v) => v.id === value.id)) {
            existingObj.values.push(value);
          }
        }
      } else {
        const newObj = { title: obj.title, values: obj.values };
        result.push(newObj);
        map.set(obj.title, newObj);
      }
    }

    return result;
  }
  useEffect(() => {
    if (values?.images?.length > 0) {
      // const mergedData = mergeArray(values?.images);
      const mergedData = handleLocalImages(values?.images);
      setValue('images', []);
      setValue('images', mergedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.images.length]);

  const handleLocalImages = (allImages) => {
    const mergedData = allImages?.reduce((acc, curr) => {
      const existingData = acc.find((data) => data.title === curr.title);

      if (existingData) {
        existingData.values = [...new Set([...existingData.values, ...curr.values])];
      } else {
        acc.push(curr);
      }

      return acc;
    }, []);

    return mergedData;
  };

  const { mutate: createImageUrl } = useCreateProductImageUrl();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'images',
  });

  const [files, setFiles] = useState([]);

  const watchFieldArray = watch('images');

  const controlledFiles = watchFieldArray?.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  console.log('controlledFiles', controlledFiles);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      const formData = new FormData();
      newFiles.forEach((file) => {
        formData.append('productPictures', file);
      });

      createImageUrl(formData, {
        onSuccess: (imageData) => {
          trigger('images');
          console.log('IMAGES123', imageData.data.images);
          handleSetValue(imageData?.data?.images);
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files]
  );

  const handleSetValue = (allS3Images) => {
    const requiredData = allS3Images.map((image) => {
      return {
        id: image.key,
        url: image.location,
      };
    });

    const newImages = [
      {
        title: tag,
        values: requiredData,
      },
    ];
    trigger();

    append(newImages);
  };

  const handleUpload = (imageName) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('productPictures', file);
    });

    createImageUrl(formData, {
      onSuccess: (imgUrl) => {
        console.log('IMAGES', imgUrl);
        const newImages = [
          {
            title: imageName,
            values: imgUrl?.data?.images,
          },
        ];
        append(newImages);
        setValue('images', newImages);
      },
    });
  };

  const handleRemoveFile = (keyValue) => {
    console.log('keyValue', keyValue);
    console.log('files', files);
    const newFiles = files.filter((file) => file?.name !== keyValue.name);
    setFiles(newFiles);
  };

  const handleRemoveAllFiles = (keyValue) => {
    const newImages = values?.images?.filter((image) => image?.title !== keyValue);
    setValue('images', newImages);
  };

  console.log('values123', values, files);

  return (
    <Upload
      multiple
      thumbnail
      files={files}
      onDrop={handleDrop}
      onRemove={handleRemoveFile}
      onRemoveAll={() => handleRemoveAllFiles(tag)}
      onUpload={() => {
        handleUpload(`${tag}`);
      }}
      placeholder={`Upload ${tag} Images`}
    />
  );
}
