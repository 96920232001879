import { lazy, Suspense } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(
  lazy(() => import('../pages/dashboard/general/GeneralAppPage'))
);

// DASHBOARD: BANNER
export const BannerListPage = Loadable(
  lazy(() => import('../pages/dashboard/banner/BannerListPage'))
);
export const BannerCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/banner/BannerCreatePage'))
);
export const BannerEditForm = Loadable(
  lazy(() => import('../pages/dashboard/banner/BannerEditForm'))
);

// DASHBOARD: BLOG
export const BlogListPage = Loadable(lazy(() => import('../pages/dashboard/blog/BlogListPage')));
export const BlogCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/blog/BlogCreatePage'))
);
export const BlogEditForm = Loadable(lazy(() => import('../pages/dashboard/blog/BlogEditForm')));
export const BlogDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/blog/BlogDetailsPage'))
);

//  DASHBOARD: BRAND
export const BrandListPage = Loadable(lazy(() => import('../pages/dashboard/brand/BrandListPage')));
export const BrandCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/brand/BrandCreatePage'))
);
export const BrandEditForm = Loadable(lazy(() => import('../pages/dashboard/brand/BrandEditForm')));

// DASHBOARD: CATEGORY
export const CategoryListPage = Loadable(
  lazy(() => import('../pages/dashboard/category/CategoryListPage'))
);
export const CategoryCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/category/CategoryCreatePage'))
);
export const CategoryEditForm = Loadable(
  lazy(() => import('../pages/dashboard/category/CategoryEditForm'))
);

// DASHBOARD: SUB-CATEGORY
export const SubCategoryListPage = Loadable(
  lazy(() => import('../pages/dashboard/sub-category/SubCategoryListPage'))
);
export const SubCategoryCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/sub-category/SubCategoryCreatePage'))
);
export const SubCategoryEditForm = Loadable(
  lazy(() => import('../pages/dashboard/sub-category/SubCategoryEditForm'))
);

// DASHBOARD: SUPER-SUB-CATEGORY
export const SuperSubCategoryListPage = Loadable(
  lazy(() => import('../pages/dashboard/sup-sub-category/SuperSubCategoryListPage'))
);
export const SuperSubCategoryCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/sup-sub-category/SuperSubCategoryCreatePage'))
);
export const SuperSubCategoryEditForm = Loadable(
  lazy(() => import('../pages/dashboard/sup-sub-category/SuperSubCategoryEditForm'))
);

// DASHBOARD: CONTACT-ENQUIRY
export const ContactEnquriesListPage = Loadable(
  lazy(() => import('../pages/dashboard/enquiry/ContactEnquriesListPage'))
);
// DASHBOARD: REOORTER-ENQUIRY
export const ReporterEnquiryListPage = Loadable(
  lazy(() => import('../pages/dashboard/enquiry/ReporterEnquiryListPage'))
);

// DASHBOARD: NEWS-MANAGEMENT
export const NewsListPage = Loadable(lazy(() => import('../pages/dashboard/news/NewsListPage')));
export const NewsCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/news/NewsCreatePage'))
);
export const NewsEditForm = Loadable(lazy(() => import('../pages/dashboard/news/NewsEditForm')));
export const NewsDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/news/NewsDetailsPage'))
);

// DASHBOARD:Whatsapp Group
export const WhatsappGroupListPage = Loadable(
  lazy(() => import('../pages/dashboard/whatsappgroup/WhatsappGroupListPage'))
);
export const WhatsappGroupCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/whatsappgroup/WhatsappGroupCreatePage'))
);
export const WhatsappGroupEditForm = Loadable(
  lazy(() => import('../pages/dashboard/whatsappgroup/WhatsappGroupEditForm'))
);

// DASHBOARD: ADVERTISEMENT-MANAGEMENT
export const AdvertisementListPage = Loadable(
  lazy(() => import('../pages/dashboard/advertisement/AdvertisementListPage'))
);
export const AdvertisementCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/advertisement/AdvertisementCreatePage'))
);
export const AdvertisementEditForm = Loadable(
  lazy(() => import('../pages/dashboard/advertisement/AdvertisementEditForm'))
);
export const AdvertisementDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/advertisement/AdvertisementDetailsPage'))
);

// DASHBOARD: STAFF
export const StaffListPage = Loadable(lazy(() => import('../pages/dashboard/staff/StaffListPage')));
export const StaffCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/staff/StaffCreatePage'))
);
export const StaffChangePassword = Loadable(
  lazy(() => import('../pages/dashboard/staff/StaffChangePassword'))
);
export const StaffEditForm = Loadable(lazy(() => import('../pages/dashboard/staff/StaffEditForm')));
export const StaffDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/staff/StaffDetailsPage'))
);

// DASHBOARD: CUSTOMER
export const CustomerListPage = Loadable(
  lazy(() => import('../pages/dashboard/customer/CustomerListPage'))
);
export const CustomerDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/customer/CustomerDetailsPage'))
);

// DASHBOARD: Reporter
export const ReporterListPage = Loadable(
  lazy(() => import('../pages/dashboard/reporter/ReporterListPage'))
);
export const ReporterDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/reporter/ReporterDetailsPage'))
);
export const ReporterEditForm = Loadable(
  lazy(() => import('../pages/dashboard/reporter/ReporterEditForm'))
);
export const ReporterCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/reporter/ReporterCreatePage'))
);
export const ReporterChangePassword = Loadable(
  lazy(() => import('../pages/dashboard/reporter/ReporterChangePassword'))
);

//  DASHBOARD: Designation
export const DesignationListPage = Loadable(
  lazy(() => import('../pages/dashboard/desgination/DesignationListPage'))
);
export const DesignationCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/desgination/DesignationCreatePage'))
);
export const DesignationEditForm = Loadable(
  lazy(() => import('../pages/dashboard/desgination/DesignationEditForm'))
);

//  DASHBOARD: DISCOUNT
export const DiscountListPage = Loadable(
  lazy(() => import('../pages/dashboard/discount/DiscountListPage'))
);
export const DiscountCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/discount/DiscountCreatePage'))
);
export const DiscountEditForm = Loadable(
  lazy(() => import('../pages/dashboard/discount/DiscountEditForm'))
);

//  DASHBOARD: OFFER
export const OfferListPage = Loadable(lazy(() => import('../pages/dashboard/offer/OfferListPage')));
export const OfferCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/offer/OfferCreatePage'))
);
export const OfferEditForm = Loadable(lazy(() => import('../pages/dashboard/offer/OfferEditForm')));

// DASHBOARD: TESTIMONIALS
export const TestimonialsListPage = Loadable(
  lazy(() => import('../pages/dashboard/testimonial/TestimonialsListPage'))
);
export const TestimonialsCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/testimonial/TestimonialsCreatePage'))
);
export const TestimonialsEditForm = Loadable(
  lazy(() => import('../pages/dashboard/testimonial/TestimonialsEditForm'))
);

// DASHBOARD: ORDER
export const OrderListPage = Loadable(lazy(() => import('../pages/dashboard/order/OrderListPage')));

// DASHBOARD: PRODUCT
export const ProductListPage = Loadable(
  lazy(() => import('../pages/dashboard/product/ProductListPage'))
);
export const ProductCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/product/ProductCreatePage'))
);

// DASHBOARD: USER
export const UserProfilePage = Loadable(
  lazy(() => import('../pages/dashboard/user/UserProfilePage'))
);
export const UserCardsPage = Loadable(lazy(() => import('../pages/dashboard/user/UserCardsPage')));
export const UserListPage = Loadable(lazy(() => import('../pages/dashboard/user/UserListPage')));
export const UserAccountPage = Loadable(
  lazy(() => import('../pages/dashboard/user/UserAccountPage'))
);
export const UserCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/user/UserCreatePage'))
);
export const UserEditPage = Loadable(lazy(() => import('../pages/dashboard/user/UserEditPage')));

// DASHBOARD: ADMIN-CONFIG
// ADMIN-CONFIG: GENERAL
export const GeneralConfigEditForm = Loadable(
  lazy(() => import('../pages/dashboard/admin-config/GeneralConfigEditForm'))
);
// ADMIN-CONFIG: SUPER-ADMIN
export const SuperAdminConfigEditForm = Loadable(
  lazy(() => import('../pages/dashboard/admin-config/SuperAdminConfigEditForm'))
);

// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));

// MAIN
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
