/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// form
import Radio from '@mui/material/Radio';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';

import {
  allLettersUppercase,
  firstLetterUppercase,
  specialCharactersNotAllowed,
} from 'utils/utils';
// @mui
import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material';
// utils
// components
import { RHFAutocomplete, RHFTextField } from '../../../../components/hook-form';
import Iconify from '../../../../components/iconify';

// ----------------------------------------------------------------------

AddVariants.propTypes = {
  onClose: PropTypes.func,
};

export default function AddVariants({ onClose }) {
  const { control, setValue, watch, resetField } = useFormContext();
  const { trigger } = useForm();
  const [variantOptionsValue, setVariantOptionsValue] = useState([]);
  const [selectedValue, setSelectedValue] = useState('a');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'variants',
  });

  const values = watch();

  useEffect(() => {
    if (fields.length === 0) {
      append({
        title: 'Color',
        options: [],
      });
      setValue('leadVariant', 'Color');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  const handleAdd = () => {
    append({
      title: '',
      options: [],
    });
  };

  const handleRemove = (index) => {
    remove(index);
    setValue('leadVariant', '');
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  console.log('fields', fields);

  const watchFieldArray = watch('variants');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Add Variants
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Stack
        divider={
          <Divider flexItem sx={{ borderStyle: 'dashed', m: 0, p: 0, borderColor: 'red.500' }} />
        }
        // spacing={1}
      >
        {controlledFields?.map((item, index) => (
          <>
            <Stack key={item?.id} direction="row" alignItems="center" justifyItems="center">
              <Stack
                direction="row"
                spacing={{
                  xs: 0,
                  md: 2,
                }}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gridGap: 2,
                  my: 2,
                }}
              >
                <Stack direction="row" alignItems="flex-start" spacing={1}>
                  <Radio
                    checked={values?.leadVariant === item?.title}
                    onChange={(event) => {
                      console.log('EVERNT', event.target);
                      trigger();
                      setValue('leadVariant', event.target.value);
                    }}
                    value={item?.title}
                    name="leadVariant"
                    sx={{
                      color: 'primary.main',
                      m: 0,
                      p: 0,
                      mt: 5.5,
                      '&.Mui-checked': {
                        color: 'primary.main',
                      },
                    }}
                  />
                  <Stack>
                    <Typography
                      sx={{
                        color: 'text.secondary',
                        fontSize: '0.75rem',
                        fontWeight: 'bold',
                        my: 1,
                      }}
                    >
                      Variant Name
                    </Typography>
                    {/* Don't Remove this console it will not update the current fields till find other solution */}
                    {console.log(values)}
                    <RHFTextField
                      name={`variants[${index}].title`}
                      size="small"
                      disabled={index === 0}
                      placeholder={index === 0 ? 'E.g. Color' : 'E.g. Size'}
                      onInput={(e) => {
                        console.log('TRIGGER');
                        firstLetterUppercase(e);
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    gridColumn: 'span 2',
                    w: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    // flex: 1,
                  }}
                >
                  <Stack
                    sx={{
                      flex: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: 'text.secondary',
                        fontSize: '0.75rem',
                        fontWeight: 'bold',
                        my: 1,
                      }}
                    >
                      Variant Options
                    </Typography>
                    <RHFAutocomplete
                      name={`variants[${index}].options`}
                      size="small"
                      placeholder={
                        values.variants[index]?.options?.length === 0
                          ? `${
                              index === 0 ? 'E.g. Red, Green, Blue etc.' : 'E.g. S, L, M, XL etc.'
                            } `
                          : ''
                      }
                      multiple
                      freeSolo
                      fullWidth
                      onInput={(event) => {
                        // special characters are not allowed
                        specialCharactersNotAllowed(event);
                        allLettersUppercase(event);
                      }}
                      options={variantOptionsValue?.map((option) => option)}
                      ChipProps={{ size: 'small' }}
                    />
                  </Stack>
                  {index !== 0 && (
                    <IconButton
                      onClick={() => handleRemove(index)}
                      color="error"
                      sx={{
                        alignSelf: 'flex-end',
                      }}
                    >
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </>
        ))}
      </Stack>

      {/* Add more , reset and submit button */}
      <Stack
        spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        sx={{ mt: 3, justifyContent: 'space-between' }}
      >
        {fields?.length < 3 && (
          <Button
            size="small"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleAdd}
            sx={{ flexShrink: 0 }}
          >
            {fields?.length === 0 ? 'Add Variant' : 'Add Another Variant'}
          </Button>
        )}
        <Stack
          direction="row"
          spacing={2}
          sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}
        >
          <Button
            size="small"
            onClick={() => {
              resetField(`variants`);
              setValue('leadVariant', '');
              onClose();
            }}
            variant="outlined"
            sx={{ flexShrink: 0 }}
          >
            Reset & Close
          </Button>
          <Button
            size="small"
            onClick={() => onClose()}
            variant="contained"
            sx={{ flexShrink: 0 }}
            disabled={values?.leadVariant === ''}
          >
            Submit Variant
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
