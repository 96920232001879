import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from '../components/snackbar';
import api from './api';

export const useGetAllOrders = () => {
  const { data, isError, isLoading } = useQuery(['_getAllOrders'], () => api.get('/order/all'), {
    enabled: true,
  });
  return {
    data: data?.data?.order,
    isLoading,
    isError,
  };
};

export const useGetAllB2COrders = () => {
  const { data, isError, isLoading } = useQuery(
    ['_getAllOrdersB2C'],
    () => api.get('/order/all/b2c'),
    {
      enabled: true,
    }
  );
  return {
    data: data?.data?.orders,
    isLoading,
    isError,
  };
};
export const useGetAllB2BOrders = (currentTab) => {
  const { data, isError, isLoading } = useQuery(
    ['_getAllOrdersB2B', currentTab],
    () => api.get('/order/all/b2b'),
    {
      enabled: true,
    }
  );
  return {
    data: data?.data?.orders,
    isLoading,
    isError,
  };
};

export const useGetOrderById = (id) => {
  console.log('id12', id);
  const { data, isError, isLoading } = useQuery(
    ['_getOrderById', id],
    () => api.get(`/order/one/${id}`),
    { enabled: !!id }
  );
  return {
    data: data?.data?.order,
    isLoading,
    isError,
  };
};

export const useUpdateOrdersById = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    (data) => {
      const formData = data;
      console.log('formData', formData);
      const id = formData?._id;
      return api.put(`/order/update/${id}`, data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['_updateBannerById']);
        enqueueSnackbar(data?.data?.message || 'success');
      },
      onError: (error) => {
        enqueueSnackbar(error || 'error');
        console.log(error);
      },
    }
  );
  return {
    updateOrder: mutate,
    isLoading,
  };
};

export const useDeleteOrdersById = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation(
    (id) => {
      const _id = id;
      return api.delete(`/order/delete/${_id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['_deleteOrderById']);
        enqueueSnackbar(data?.data?.message || 'success');
      },
      onError: (error) => {
        enqueueSnackbar(error || 'error');
        console.log(error);
      },
    }
  );
  return { DeleteBanner: mutate };
};

export const useUpdateOderById = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    (payload) => {
      const formdata = payload;
      return api.put(`/order/updatestatus/`, formdata);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['_updateorderById']);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
  return {
    updateOders: mutate,
  };
};
