/* eslint-disable array-callback-return */
/* eslint import/no-extraneous-dependencies: ["error", {"peerDependencies": true}] */
/* eslint-disable arrow-body-style */
import { DataGrid, GridCellEditStopReasons, GridCellModes, GridToolbar } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useFieldArray, useFormContext } from 'react-hook-form';

VariantsMatrixTable.propTypes = {
  name: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
};

function VariantsMatrixTable({ name, rows, columns }) {
  const { control, setValue, watch, resetField } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const [cellModesModel, setCellModesModel] = useState({});

  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const values = watch();

  const processRowUpdate = (newRow, oldRow) => {
    const rowIndex = values[name].findIndex((row) => row?.id === oldRow.id);
    const newRows = [...values[name]];
    newRows[rowIndex] = newRow;
    setValue(name, newRows);
    return newRow;
  };

  const handleCellClick = useCallback((params) => {
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  return (
    <div style={{ flexGrow: 1 }}>
      <DataGrid
        autoHeight
        density="compact"
        hideFooterPagination
        rows={rows || []}
        columns={columns || []}
        experimentalFeatures={{ newEditingApi: true }}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        processRowUpdate={(newRow, oldRow) => processRowUpdate(newRow, oldRow, name)}
        onCellEditStop={(params, event) => {
          if (params.reason === GridCellEditStopReasons.cellFocusOut) {
            event.defaultMuiPrevented = true;
          }
        }}
        components={{
          Toolbar: GridToolbar,
        }}
        // initialState={{
        //   pinnedColumns: { left: ['variant'] },
        // }}
      />
    </div>
  );
}

export default VariantsMatrixTable;

// export function generateVariants(arr) {
//   return arr.reduce((a, b) => a?.flatMap((d) => b?.map((e) => [d, e]?.flat())), [[]]);
// }

export const generateVariantsUrl = (productID, userType = 'b2c', allVariantsProps) => {
  const initialUrl = `${productID}?userType=${userType}`;
  const allVar = allVariantsProps.reduce(
    (a, b) => {
      return a?.flatMap((d) => {
        return b?.options?.map((e) => {
          return [`${d}&${b?.title?.toLowerCase()}=${e?.toLowerCase()}`]?.flat();
        });
      });
    },
    [[]]
  );

  const finalUrlWithVariant = allVar.map((item) => {
    const url = item;
    const finalUrlValue = `${initialUrl}${item}`;
    const variants = item[0]?.split('&')?.slice(1);

    const variantArr = variants?.map((variant) => {
      const [title, value] = variant.split('=');
      return value;
    });

    const variantObj = variants?.map((variant) => {
      const [title, value] = variant.split('=');
      return { title, value };
    });
    return { url: finalUrlValue, variants: variantObj, variantsCombination: variantArr };
  });

  return finalUrlWithVariant;
};

export const createMatrixWithVariants = (allValues, b2bMatrix = false) => {
  console.log('allValues', allValues);
  const variants = allValues.variants || [];
  const quantityVariants = allValues.quantityVariants || [];

  const variantTitles = variants.map((variant) => variant.title);
  const variantOptions = variants.map((variant) => variant.options);

  const b2cVariants = generateVariantsUrl(allValues?.productId, 'b2c', variants);
  const b2bVariants = generateVariantsUrl(allValues?.productId, 'b2b', variants);

  let columns = [];
  const b2cRows = [];
  const b2bRows = [];
  const result = [];
  const rows = [];

  // if variants is not empty then generate variants like (S, M, L) and (Red, Blue, Green)
  if (variants.length > 0 && quantityVariants.length === 0) {
    columns = [
      { field: 'variant', headerName: 'Variant', width: 150 },
      {
        field: 'stockStatus',
        headerName: 'Stock Status',
        editable: true,
        type: 'singleSelect',
        valueOptions: ['In-Stock', 'Out of Stock', 'Low Stock'],
        width: 100,
      },
      { field: 'stock', headerName: 'Stock', type: 'number', editable: true, width: 100 },
      { field: 'costPrice', headerName: 'Cost Price', type: 'number', editable: true, width: 100 },
      { field: 'mrp', headerName: 'MRP', type: 'number', editable: true },
      {
        field: 'perProductPrice',
        headerName: 'Single Price',
        type: 'number',
        editable: true,
        width: 100,
      },
    ];

    b2cVariants.forEach((item, index) => {
      b2cRows.push({
        id: uuidv4(),
        variant: item.variantsCombination.join(', '),
        url: item.url,
        variantData: item.variants,
        stockStatus: 'In Stock',
        stock: 0,
        costPrice: 0,
        mrp: allValues?.commonMrp || 0,
        perProductPrice: allValues?.perProductPrice || 0,
      });
    });

    b2bVariants.forEach((item, index) => {
      b2bRows.push({
        id: uuidv4(),
        variant: item.variantsCombination.join(', '),
        url: item.url,
        variantData: item.variants,
        stockStatus: 'In Stock',
        stock: 0,
        costPrice: 0,
        mrp: allValues?.commonMrp || 0,
        perProductPrice: allValues?.perProductPrice || 0,
      });
    });
  }

  // If quantity variants is not empty then add the quantity price columns
  if (quantityVariants.length > 0 && variants.length === 0) {
    const quantityPrice = {
      isQuantityPriceAvailable: true,
      quantityPriceType: [],
    };

    columns = [
      {
        field: 'stockStatus',
        headerName: 'Stock Status',
        editable: true,
        type: 'singleSelect',
        valueOptions: ['In-Stock', 'Out of Stock', 'Low Stock'],
        width: 100,
      },
      { field: 'stock', headerName: 'Stock', type: 'number', editable: true, width: 100 },
      { field: 'costPrice', headerName: 'Cost Price', type: 'number', editable: true, width: 100 },
      { field: 'mrp', headerName: 'MRP', type: 'number', editable: true },
      {
        field: 'perProductPrice',
        headerName: 'Single Price',
        type: 'number',
        editable: true,
        width: 100,
      },
    ];

    quantityVariants.forEach((quantityVariant) => {
      columns.push({
        field: `for${quantityVariant}plusQuantityPriceIs`,
        headerName: `${quantityVariant}+ Qty.`,
        type: 'number',
        editable: true,
        width: 100,
      });

      // quantityPrice.quantityPriceType.push({
      //   quantity: quantityVariant,
      //   price: 0,
      // });

      // rows.forEach((row) => {
      //   rows.quantityPrice = quantityPrice;
      // });

      // rows.forEach((row) => {
      //   row[`for${quantityVariant}plusQuantityPriceIs`] = 0;
      // });
    });
    const quanityVarientData = quantityVariants.map((qv) => {
      return {
        quantity: qv,
        // assign allValues.for${quantityVariant}plusQuantityPriceIs to price
        discount: allValues[`for${qv}PlusQuantityDiscountIs`],
      };
    });

    b2cRows.push({
      id: uuidv4(),
      stockStatus: 'In Stock',
      stock: 0,
      costPrice: 0,
      mrp: allValues?.commonMrp || 0,
      perProductPrice: allValues?.perProductPrice || 0,

      // map all the quantity price columns and add the quantity price object
      ...quanityVarientData.reduce((acc, quantityVariant) => {
        acc[`for${quantityVariant?.quantity}plusQuantityPriceIs`] =
          allValues?.discountType === 'percentage'
            ? allValues.commonMrp - (allValues.commonMrp * quantityVariant.discount) / 100
            : allValues.commonMrp - quantityVariant.discount || 0;
        return acc;
      }, {}),
    });

    b2bRows.push({
      id: uuidv4(),
      stockStatus: 'In Stock',
      stock: 0,
      costPrice: 0,
      mrp: allValues?.commonMrp || 0,
      perProductPrice: allValues?.perProductPrice || 0,

      // map all the quantity price columns and add the quantity price object
      ...quanityVarientData.reduce((acc, quantityVariant) => {
        acc[`for${quantityVariant?.quantity}plusQuantityPriceIs`] =
          allValues?.discountType === 'percentage'
            ? allValues.commonMrp - (allValues.commonMrp * quantityVariant.discount) / 100
            : allValues.commonMrp - quantityVariant.discount || 0;
        return acc;
      }, {}),
    });
  }

  // If both variants and quantity variants are not empty then generate variants and add quantity price columns
  if (quantityVariants.length > 0 && variants.length > 0) {
    columns = [
      { field: 'variant', headerName: 'Variant', width: 150 },
      {
        field: 'stockStatus',
        headerName: 'Stock Status',
        editable: true,
        type: 'singleSelect',
        valueOptions: ['In-Stock', 'Out of Stock', 'Low Stock'],
        width: 100,
      },
      { field: 'stock', headerName: 'Stock', type: 'number', editable: true, width: 100 },
      { field: 'costPrice', headerName: 'Cost Price', type: 'number', editable: true, width: 100 },
      { field: 'mrp', headerName: 'MRP', type: 'number', editable: true },
      {
        field: 'perProductPrice',
        headerName: 'Single Price',
        type: 'number',
        editable: true,
        width: 100,
      },
    ];

    const quantityVariantData = quantityVariants.map((qv) => {
      return {
        quantity: qv,
        // assign allValues.for${quantityVariant}plusQuantityPriceIs to price
        discount: allValues[`for${qv}PlusQuantityDiscountIs`],
      };
    });
    console.log('quantityVariantData', quantityVariantData);

    quantityVariants.forEach((quantityVariant) => {
      columns.push({
        field: `for${quantityVariant}plusQuantityPriceIs`,
        headerName: `${quantityVariant}+ Qty.`,
        type: 'number',
        editable: true,
        width: 100,
      });
    });

    b2cVariants.forEach((item, index) => {
      b2cRows.push({
        id: uuidv4(),
        variant: item.variantsCombination.join(', '),
        url: item.url,
        variantData: item.variants,
        stockStatus: 'In Stock',
        stock: 0,
        costPrice: 0,
        mrp: allValues?.commonMrp || 0,
        perProductPrice: allValues?.perProductPrice || 0,

        // map all the quantity price columns and add the quantity price object
        ...quantityVariantData.reduce((acc, quantityVariant) => {
          acc[`for${quantityVariant?.quantity}plusQuantityPriceIs`] =
            allValues?.discountType === 'percentage'
              ? allValues.commonMrp - (allValues.commonMrp * quantityVariant.discount) / 100
              : allValues.commonMrp - quantityVariant.discount || 0;
          return acc;
        }, {}),
      });
    });

    b2bVariants.forEach((item, index) => {
      b2bRows.push({
        id: uuidv4(),
        variant: item.variantsCombination.join(', '),
        url: item.url,
        variantData: item.variants,
        stockStatus: 'In Stock',
        stock: 0,
        costPrice: 0,
        mrp: allValues?.commonMrp || 0,
        perProductPrice: allValues?.perProductPrice || 0,
        // map all the quantity price columns and add the quantity price object
        ...quantityVariantData.reduce((acc, quantityVariant) => {
          acc[`for${quantityVariant?.quantity}plusQuantityPriceIs`] =
            allValues?.discountType === 'percentage'
              ? allValues.commonMrp - (allValues.commonMrp * quantityVariant.discount) / 100
              : allValues.commonMrp - quantityVariant.discount || 0;
          return acc;
        }, {}),
      });
    });
  }

  if (quantityVariants.length === 0 && variants.length === 0) {
    columns = [
      {
        field: 'stockStatus',
        headerName: 'Stock Status',
        editable: true,
        type: 'singleSelect',
        valueOptions: ['In-Stock', 'Out of Stock', 'Low Stock'],
        width: 100,
      },
      { field: 'stock', headerName: 'Stock', type: 'number', editable: true, width: 100 },
      { field: 'costPrice', headerName: 'Cost Price', type: 'number', editable: true, width: 100 },
      { field: 'mrp', headerName: 'MRP', type: 'number', editable: true },
      {
        field: 'perProductPrice',
        headerName: 'Single Price',
        type: 'number',
        editable: true,
        width: 100,
      },
    ];

    b2cRows.push({
      id: uuidv4(),
      stockStatus: 'In Stock',
      stock: 0,
      costPrice: 0,
      mrp: 0,
      perProductPrice: 0,
    });

    b2bRows.push({
      id: uuidv4(),
      stockStatus: 'In Stock',
      stock: 0,
      costPrice: 0,
      mrp: 0,
      perProductPrice: 0,
    });
  }

  // If B2B allowed from the config then add the B2B columns
  if (b2bMatrix) {
    return {
      b2cRows,
      b2cColumns: columns,

      b2bRows,
      b2bColumns: columns,
    };
  }

  return { b2cRows, b2cColumns: columns };
};
