import {
  Box,
  Card,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingScreen from 'components/loading-screen/LoadingScreen';
import BlankPage from 'pages/dashboard/BlankPage';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useGetAddressById } from 'services/addressServices';
import { useGetOrderById } from 'services/orderServices';
import { fCurrency } from 'utils/formatNumber';
import Image from '../../../components/image';
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar';
import { fDate } from '../../../utils/formatTime';

const StyledRowResult = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------
AddressDetails.propTypes = {
  id: PropTypes.string,
};
export function AddressDetails({ id }) {
  const { data, isLoading, isError } = useGetAddressById(id);
  if (isLoading) console.log('Loading...');
  if (isError) console.log('Error...');
  return (
    <Stack spacing={2}>
      <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
        {data?.name}
      </Typography>

      <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
        {data?.delivery_address}
      </Typography>

      <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
        Phone: {data?.contact_no}
      </Typography>
    </Stack>
  );
}
//--------------------------------------------------------------------------
OrderInvoiceDetails.propTypes = {
  invoice: PropTypes.object,
};

export default function OrderInvoiceDetails({ invoice }) {
  const { id } = useParams();

  const { data, isLoading: orderIsLoading, isError: orderIsError } = useGetOrderById(id);

  console.log('data123456', data);

  if (orderIsLoading) return <LoadingScreen />;

  if (orderIsError) return <BlankPage />;
  const {
    _id,
    ord_payid,
    ord_id,
    ord_payment_status,
    cust_name,
    ord_quantity,
    ord_value,
    deliv_city,
    deliv_address,
    ord_status,
    firm_name,
    ord_date,
    created_at,
    cust_type,
    payment_method,
    payment_id,
    ord_product,
    gst_amt,
    gst,
    discount_amt,
    bill_deliv_address,
  } = data;

  const handleGetPrice = (item) => {
    const price = item?.[`${cust_type}Rows`][0]?.perProductPrice;
    return Number(price);
  };

  // const subTotalPrice = ord_product?.cart?.reduce(
  //   (curr, acc) => handleGetPrice(curr) * Number(curr?.quantity)
  // );

  // const totalPrice = subTotalPrice + Number(gst_amt) - Number(discount_amt);

  return (
    <Card sx={{ pt: 5, px: 5 }}>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
          <Image disabledEffect alt="logo" src="/logo/logo_full.svg" sx={{ maxWidth: 120 }} />
        </Grid>

        <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
          <Box sx={{ textAlign: { sm: 'right' } }}>
            <Label
              variant="soft"
              color={
                (ord_status === 'paid' && 'success') ||
                (ord_status === 'unpaid' && 'warning') ||
                (ord_status === 'overdue' && 'error') ||
                'default'
              }
              sx={{ textTransform: 'uppercase', mb: 1 }}
            >
              {ord_status}
            </Label>

            <Typography variant="h6">{`INV - ${ord_id}`}</Typography>
            <Typography variant="h6">{`DATE - ${fDate(ord_date)}`}</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
          <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
            Invoice from
          </Typography>

          <Stack spacing={2}>
            <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
              HERCO
            </Typography>

            <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
              Final Plot No. 14, Sub Plot No. 8, Hadapsar Industrial Estate II, Ramtekdi Industrial
              Area, Pune-411028, Maharashtra, India
            </Typography>

            <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
              Phone: 7030936001
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
          <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
            Invoice from
          </Typography>
          <AddressDetails id={bill_deliv_address} />
        </Grid>
      </Grid>

      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 960 }}>
            <TableHead
              sx={{
                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                '& th': { backgroundColor: 'transparent' },
              }}
            >
              <TableRow>
                <TableCell width={40}>#</TableCell>

                <TableCell align="left">Product</TableCell>

                <TableCell align="left">Qty</TableCell>

                <TableCell align="right">Unit price</TableCell>

                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {ord_product?.cart?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  }}
                >
                  <TableCell>{index + 1}</TableCell>

                  <TableCell align="left">
                    <Box sx={{ maxWidth: 560 }}>
                      <Typography variant="subtitle2">{row?.title}</Typography>
                    </Box>
                  </TableCell>

                  <TableCell align="left">{row.quantity}</TableCell>

                  <TableCell align="right">{fCurrency(handleGetPrice(row))}</TableCell>

                  <TableCell align="right">
                    {fCurrency(handleGetPrice(row) * row.quantity)}
                  </TableCell>
                </TableRow>
              ))}

              <StyledRowResult>
                <TableCell colSpan={3} />

                <TableCell align="right" sx={{ typography: 'body1' }}>
                  <Box sx={{ mt: 2 }} />
                  Subtotal
                </TableCell>

                <TableCell align="right" width={120} sx={{ typography: 'body1' }}>
                  <Box sx={{ mt: 2 }} />
                  {/* {fCurrency(subTotalPrice)} */}
                </TableCell>
              </StyledRowResult>

              <StyledRowResult>
                <TableCell colSpan={3} />

                <TableCell align="right" sx={{ typography: 'body1' }}>
                  Discount
                </TableCell>

                <TableCell
                  align="right"
                  width={120}
                  sx={{ color: 'error.main', typography: 'body1' }}
                >
                  {/* {discount_amt && fCurrency(-discount_amt)} */}
                </TableCell>
              </StyledRowResult>

              <StyledRowResult>
                <TableCell colSpan={3} />

                <TableCell align="right" sx={{ typography: 'body1' }}>
                  Taxes {gst && `(${gst}%)`}
                </TableCell>

                <TableCell align="right" width={120} sx={{ typography: 'body1' }}>
                  {gst_amt && fCurrency(gst_amt)}
                </TableCell>
              </StyledRowResult>

              <StyledRowResult>
                <TableCell colSpan={3} />

                <TableCell align="right" sx={{ typography: 'h6' }}>
                  Grand Total
                </TableCell>

                <TableCell align="right" width={140} sx={{ typography: 'h6' }}>
                  {/* {fCurrency(totalPrice)} */}
                </TableCell>
              </StyledRowResult>
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <Divider sx={{ mt: 5 }} />

      <Grid container>
        <Grid item xs={12} md={9} sx={{ py: 3 }}>
          <Typography variant="subtitle2">Thank you for your business</Typography>

          <Typography variant="body2">WWW.herco.com</Typography>
        </Grid>

        <Grid item xs={12} md={3} sx={{ py: 3, textAlign: 'right' }}>
          <Typography variant="subtitle2">Have a Question?</Typography>

          <Typography variant="body2">support@technolitics.com</Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
