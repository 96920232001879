import OrderInvoiceb2bDetails from 'pages/dashboard/order/OrderInvoiceb2bDetails';
import OrderInvoiceDetails from 'pages/dashboard/order/OrderInvoiceDetails';
import ProductEditPage from 'pages/dashboard/product/ProductEditPage';
import { Navigate, useRoutes } from 'react-router-dom';
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import { PATH_AFTER_LOGIN } from '../config-global';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import MainLayout from '../layouts/main';
import {
  AdvertisementCreatePage,
  AdvertisementDetailsPage,
  AdvertisementEditForm,
  AdvertisementListPage,
  BannerCreatePage,
  BannerEditForm,
  BannerListPage,
  BlankPage,
  BlogCreatePage,
  BlogDetailsPage,
  BlogEditForm,
  BlogListPage,
  BrandCreatePage,
  BrandEditForm,
  BrandListPage,
  CategoryCreatePage,
  CategoryEditForm,
  CategoryListPage,
  ContactEnquriesListPage,
  CustomerDetailsPage,
  CustomerListPage,
  DesignationCreatePage,
  DesignationEditForm,
  DesignationListPage,
  DiscountCreatePage,
  DiscountEditForm,
  DiscountListPage,
  GeneralAppPage,
  GeneralConfigEditForm,
  LoginPage,
  WhatsappGroupCreatePage,
  WhatsappGroupEditForm,
  WhatsappGroupListPage,
  NewPasswordPage,
  NewsCreatePage,
  NewsDetailsPage,
  NewsEditForm,
  NewsListPage,
  OfferCreatePage,
  OfferEditForm,
  OfferListPage,
  OrderListPage,
  ProductCreatePage,
  ProductListPage,
  RegisterPage,
  ReporterChangePassword,
  ReporterCreatePage,
  ReporterDetailsPage,
  ReporterEditForm,
  ReporterEnquiryListPage,
  ReporterListPage,
  ResetPasswordPage,
  StaffChangePassword,
  StaffCreatePage,
  StaffDetailsPage,
  StaffEditForm,
  StaffListPage,
  SubCategoryCreatePage,
  SubCategoryEditForm,
  SubCategoryListPage,
  SuperAdminConfigEditForm,
  SuperSubCategoryCreatePage,
  SuperSubCategoryEditForm,
  SuperSubCategoryListPage,
  TestimonialsCreatePage,
  TestimonialsEditForm,
  TestimonialsListPage,
  UserAccountPage,
  UserCardsPage,
  UserCreatePage,
  UserEditPage,
  UserListPage,
  UserProfilePage,
  VerifyCodePage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Main Page "/" Route
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [{ element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true }],
    },

    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard App
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralAppPage /> },

        // DASHBOARD: Staff Management
        {
          path: 'staff',
          children: [
            { element: <Navigate to="/dashboard/staff/list" replace />, index: true },
            { path: 'list', element: <StaffListPage /> },
            { path: 'new', element: <StaffCreatePage /> },
            { path: 'passwordchange/:id', element: <StaffChangePassword /> },
            { path: 'edit/:id', element: <StaffEditForm /> },
            { path: ':id', element: <StaffDetailsPage /> },
          ],
        },

        // DASHBOARD: Customer Management
        {
          path: 'customer',
          children: [
            { element: <Navigate to="/dashboard/customer/list" replace />, index: true },
            { path: 'list', element: <CustomerListPage /> },
            { path: ':id', element: <CustomerDetailsPage /> },
          ],
        },

        // DASHBOARD: Reporter Management
        {
          path: 'reporter',
          children: [
            { element: <Navigate to="/dashboard/reporter/list" replace />, index: true },
            { path: 'list', element: <ReporterListPage /> },
            { path: 'new', element: <ReporterCreatePage /> },
            { path: ':id', element: <ReporterDetailsPage /> },
            { path: 'edit/:id', element: <ReporterEditForm /> },
            { path: 'passwordchange/:id', element: <ReporterChangePassword /> },
          ],
        },

        // DASHBOARD: Order Management
        {
          path: 'order',
          children: [
            { element: <Navigate to="/dashboard/order/list" replace />, index: true },
            { path: 'list', element: <OrderListPage /> },
            { path: 'invoice/b2c/:id', element: <OrderInvoiceDetails /> },
            { path: 'invoice/b2b/:id', element: <OrderInvoiceb2bDetails /> },
          ],
        },

        // DASHBOARD: Product Management
        {
          path: 'product',
          children: [
            { element: <Navigate to="/dashboard/product/list" replace />, index: true },
            { path: 'list', element: <ProductListPage /> },
            { path: 'new', element: <ProductCreatePage /> },
            { path: 'edit/:id', element: <ProductEditPage /> },
            // { path: ':id', element: <ProductDetailsPage /> },
          ],
        },

        // DASHBOARD: Testimonials
        {
          path: 'testimonials',
          children: [
            { element: <Navigate to="/dashboard/testimonials/list" replace />, index: true },
            { path: 'list', element: <TestimonialsListPage /> },
            { path: 'new', element: <TestimonialsCreatePage /> },
            { path: 'edit/:id', element: <TestimonialsEditForm /> },
          ],
        },

        // DASHBOARD: Blog Management
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/list" replace />, index: true },
            { path: 'list', element: <BlogListPage /> },
            { path: 'new', element: <BlogCreatePage /> },
            { path: 'edit/:id', element: <BlogEditForm /> },
            { path: ':id', element: <BlogDetailsPage /> },
          ],
        },
        // DASHBOARD: banner Management
        {
          path: 'banner',
          children: [
            { element: <Navigate to="/dashboard/banner/list" replace />, index: true },
            { path: 'list', element: <BannerListPage /> },
            { path: 'new', element: <BannerCreatePage /> },
            { path: 'edit/:id', element: <BannerEditForm /> },
          ],
        },
        // DASHBOARD: Category
        {
          path: 'category',
          children: [
            { element: <Navigate to="/dashboard/category/list" replace />, index: true },
            { path: 'list', element: <CategoryListPage /> },
            { path: 'new', element: <CategoryCreatePage /> },
            { path: 'edit/:id', element: <CategoryEditForm /> },
          ],
        },

        // DASHBOARD: Sub Category
        {
          path: 'subcategory',
          children: [
            { element: <Navigate to="/dashboard/subcategory/list" replace />, index: true },
            { path: 'list', element: <SubCategoryListPage /> },
            { path: 'new', element: <SubCategoryCreatePage /> },
            { path: 'edit/:id', element: <SubCategoryEditForm /> },
          ],
        },

        // DASHBOARD: Super Sub Category
        {
          path: 'supersubcategory',
          children: [
            { element: <Navigate to="/dashboard/supersubcategory/list" replace />, index: true },
            { path: 'list', element: <SuperSubCategoryListPage /> },
            { path: 'new', element: <SuperSubCategoryCreatePage /> },
            { path: 'edit/:id', element: <SuperSubCategoryEditForm /> },
          ],
        },

        //  DASHBOARD: Brand Management
        {
          path: 'brand',
          children: [
            { element: <Navigate to="/dashboard/brand/list" replace />, index: true },
            { path: 'list', element: <BrandListPage /> },
            { path: 'new', element: <BrandCreatePage /> },
            { path: 'edit/:id', element: <BrandEditForm /> },
          ],
        },

        //  DASHBOARD: Designation Management
        {
          path: 'designation',
          children: [
            { element: <Navigate to="/dashboard/designation/list" replace />, index: true },
            { path: 'list', element: <DesignationListPage /> },
            { path: 'new', element: <DesignationCreatePage /> },
            { path: 'edit/:id', element: <DesignationEditForm /> },
          ],
        },

        // DASHBOARD: News Management
        {
          path: 'news',
          children: [
            { element: <Navigate to="/dashboard/news/list" replace />, index: true },
            { path: 'list', element: <NewsListPage /> },
            { path: 'new', element: <NewsCreatePage /> },
            { path: 'edit/:id', element: <NewsEditForm /> },
            { path: ':id', element: <NewsDetailsPage /> },
          ],
        },

        // DASHBOARD: Whatsapp Group
        {
          path: 'whatsappgroup',
          children: [
            { element: <Navigate to="/dashboard/whatsappgroup/list" replace />, index: true },
            { path: 'list', element: <WhatsappGroupListPage /> },
            { path: 'new', element: <WhatsappGroupCreatePage /> },
            { path: 'edit/:id', element: <WhatsappGroupEditForm /> },
          ],
        },

        // DASHBOARD: Advertisement Management
        {
          path: 'advertisement',
          children: [
            { element: <Navigate to="/dashboard/advertisement/list" replace />, index: true },
            { path: 'list', element: <AdvertisementListPage /> },
            { path: 'new', element: <AdvertisementCreatePage /> },
            { path: 'edit/:id', element: <AdvertisementEditForm /> },
            { path: ':id', element: <AdvertisementDetailsPage /> },
          ],
        },

        // DASHBOARD: Contact Enquries
        {
          path: 'contactenquries',
          children: [
            { element: <Navigate to="/dashboard/contactenquries/list" replace />, index: true },
            { path: 'list', element: <ContactEnquriesListPage /> },
          ],
        },
        // DASHBOARD: Reporter Enquries
        {
          path: 'reporterenquries',
          children: [
            { element: <Navigate to="/dashboard/reporterenquries/list" replace />, index: true },
            { path: 'list', element: <ReporterEnquiryListPage /> },
          ],
        },

        //  DASHBOARD: Discount Management
        {
          path: 'discount',
          children: [
            { element: <Navigate to="/dashboard/discount/list" replace />, index: true },
            { path: 'list', element: <DiscountListPage /> },
            { path: 'new', element: <DiscountCreatePage /> },
            { path: 'edit/:id', element: <DiscountEditForm /> },
          ],
        },

        //  DASHBOARD: General Config
        {
          path: 'generalconfig',
          children: [
            { element: <Navigate to="/dashboard/generalconfig" replace />, index: true },
            { path: 'edit', element: <GeneralConfigEditForm /> },
          ],
        },

        //  DASHBOARD: Super Admin Config
        {
          path: 'superadminconfig',
          children: [
            { element: <Navigate to="/dashboard/superadminconfig" replace />, index: true },
            { path: 'edit', element: <SuperAdminConfigEditForm /> },
          ],
        },

        // offer
        {
          path: 'offer',
          children: [
            { element: <Navigate to="/dashboard/offer/list" replace />, index: true },
            { path: 'list', element: <OfferListPage /> },
            { path: 'new', element: <OfferCreatePage /> },
            { path: 'edit/:id', element: <OfferEditForm /> },
          ],
        },

        //  DASHBOARD: User Management
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <UserListPage /> },
            { path: 'new', element: <UserCreatePage /> },
            { path: ':name/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },

        { path: 'blank', element: <BlankPage /> },
      ],
    },

    // 404 PAGE NOT FOUND
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
