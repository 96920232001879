// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,

  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },

  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },

  // DASHBOARD: Staff Management
  staff: {
    root: path(ROOTS_DASHBOARD, '/staff'),
    list: path(ROOTS_DASHBOARD, '/staff/list'),
    new: path(ROOTS_DASHBOARD, '/staff/new'),
    passwordchange: (id) => path(ROOTS_DASHBOARD, `/staff/passwordchange/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/staff/edit/${id}/`),
    view: (id) => path(ROOTS_DASHBOARD, `/staff/${id}`),
  },

  // DASHBOARD: Customer
  customer: {
    root: path(ROOTS_DASHBOARD, '/customer'),
    list: path(ROOTS_DASHBOARD, '/customer/list'),
    view: (id) => path(ROOTS_DASHBOARD, `/customer/${id}`),
  },

  // DASHBOARD: Reporter Management
  reporter: {
    root: path(ROOTS_DASHBOARD, '/reporter'),
    list: path(ROOTS_DASHBOARD, '/reporter/list'),
    new: path(ROOTS_DASHBOARD, '/reporter/new'),
    passwordchange: (id) => path(ROOTS_DASHBOARD, `/reporter/passwordchange/${id}`),
    view: (id) => path(ROOTS_DASHBOARD, `/reporter/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/reporter/edit/${id}/`),
  },

  // DASHBOARD: Testimonials
  testimonials: {
    root: path(ROOTS_DASHBOARD, '/testimonials'),
    list: path(ROOTS_DASHBOARD, '/testimonials/list'),
    new: path(ROOTS_DASHBOARD, '/testimonials/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/testimonials/edit/${id}/`),
  },

  // DASHBOARD: News Management
  news: {
    root: path(ROOTS_DASHBOARD, '/news'),
    list: path(ROOTS_DASHBOARD, '/news/list'),
    new: path(ROOTS_DASHBOARD, '/news/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/news/edit/${id}/`),
    view: (id) => path(ROOTS_DASHBOARD, `/news/${id}`),
  },

  // DASHBOARD: Whatsapp Group
  whatsappgroup: {
    root: path(ROOTS_DASHBOARD, '/whatsappgroup'),
    list: path(ROOTS_DASHBOARD, '/whatsappgroup/list'),
    new: path(ROOTS_DASHBOARD, '/whatsappgroup/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/whatsappgroup/edit/${id}/`),
  },

  // DASHBOARD: Advertisement Management
  advertisement: {
    root: path(ROOTS_DASHBOARD, '/advertisement'),
    list: path(ROOTS_DASHBOARD, '/advertisement/list'),
    new: path(ROOTS_DASHBOARD, '/advertisement/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/advertisement/edit/${id}/`),
    view: (id) => path(ROOTS_DASHBOARD, `/advertisement/${id}`),
  },

  // DASHBOARD: Contact Enquries
  contactenquries: {
    root: path(ROOTS_DASHBOARD, '/contactenquries'),
    list: path(ROOTS_DASHBOARD, '/contactenquries/list'),
  },

  // DASHBOARD: Reporter Enquries
  reporterenquries: {
    root: path(ROOTS_DASHBOARD, '/reporterenquries'),
    list: path(ROOTS_DASHBOARD, '/reporterenquries/list'),
  },

  // DASHBOARD: Blog Management
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    list: path(ROOTS_DASHBOARD, '/blog/list'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/blog/edit/${id}/`),
    view: (id) => path(ROOTS_DASHBOARD, `/blog/${id}`),
  },
  // DASHBOARD: banner Management
  banner: {
    root: path(ROOTS_DASHBOARD, '/banner'),
    list: path(ROOTS_DASHBOARD, '/banner/list'),
    new: path(ROOTS_DASHBOARD, '/banner/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/banner/edit/${id}/`),
  },

  // DASHBOARD: Category
  category: {
    root: path(ROOTS_DASHBOARD, '/category'),
    list: path(ROOTS_DASHBOARD, '/category/list'),
    new: path(ROOTS_DASHBOARD, '/category/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/category/edit/${id}/`),
  },

  // DASHBOARD: Sub Category
  subcategory: {
    root: path(ROOTS_DASHBOARD, '/subcategory'),
    list: path(ROOTS_DASHBOARD, '/subcategory/list'),
    new: path(ROOTS_DASHBOARD, '/subcategory/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/subcategory/edit/${id}/`),
  },

  // DASHBOARD: Super Sub Category
  supersubcategory: {
    root: path(ROOTS_DASHBOARD, '/supersubcategory'),
    list: path(ROOTS_DASHBOARD, '/supersubcategory/list'),
    new: path(ROOTS_DASHBOARD, '/supersubcategory/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/supersubcategory/edit/${id}/`),
  },

  //  DASHBOARD: Brand Management
  brand: {
    root: path(ROOTS_DASHBOARD, '/brand'),
    list: path(ROOTS_DASHBOARD, '/brand/list'),
    new: path(ROOTS_DASHBOARD, '/brand/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/brand/edit/${id}/`),
  },
  //  DASHBOARD: Designation
  designation: {
    root: path(ROOTS_DASHBOARD, '/designation'),
    list: path(ROOTS_DASHBOARD, '/designation/list'),
    new: path(ROOTS_DASHBOARD, '/designation/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/designation/edit/${id}/`),
  },

  //  DASHBOARD: Discount
  discount: {
    root: path(ROOTS_DASHBOARD, '/discount'),
    list: path(ROOTS_DASHBOARD, '/discount/list'),
    new: path(ROOTS_DASHBOARD, '/discount/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/discount/edit/${id}/`),
  },

  //  DASHBOARD: General Config
  generalconfig: {
    edit: path(ROOTS_DASHBOARD, '/generalconfig/edit'),
  },

  //  DASHBOARD: Super Admin Config
  superadminconfig: {
    edit: path(ROOTS_DASHBOARD, '/superadminconfig/edit'),
  },

  // order Management
  order: {
    root: path(ROOTS_DASHBOARD, '/order'),
    list: path(ROOTS_DASHBOARD, '/order/list'),
    invoiceb2c: (id) => path(ROOTS_DASHBOARD, `/order/invoice/b2c/${id}/`),
    invoiceb2b: (id) => path(ROOTS_DASHBOARD, `/order/invoice/b2b/${id}/`),
  },

  // offer
  offer: {
    root: path(ROOTS_DASHBOARD, '/offer'),
    list: path(ROOTS_DASHBOARD, '/offer/list'),
    new: path(ROOTS_DASHBOARD, '/offer/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/offer/edit/${id}/`),
    // Product Management
  },

  product: {
    root: path(ROOTS_DASHBOARD, '/product'),
    list: path(ROOTS_DASHBOARD, '/product/list'),
    new: path(ROOTS_DASHBOARD, '/product/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/product/edit/${id}/`),
    view: (id) => path(ROOTS_DASHBOARD, `/product/${id}`),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
