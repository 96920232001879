import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from '../components/snackbar';
import api from './api';

export const useGetAllSuperSubCategories = () => {
  const { data, isError, isLoading } = useQuery(
    ['_getAllSuperSubCategories'],
    () => api.get('/subsubcategory/all'),
    { enabled: true }
  );
  return {
    data: data?.data?.data,
    isLoading,
    isError,
  };
};

export const useGetOneSuperSubCategoriesById = (id) => {
  const { data, isError, isLoading } = useQuery(['_getOneSuperSubCategoriesById'], () =>
    api.get(`/subsubcategory/one/${id}`)
  );
  return {
    data: data?.data.subSubcategory,
    isLoading,
    isError,
  };
};

export const useCreateSuperSubCategories = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    (data) => {
      const formData = data;
      return api.post('/subsubcategory/add', formData);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['_createSuperSubCategories']);
        enqueueSnackbar(data?.data?.message || 'success');
      },
      onError: (error) => {
        enqueueSnackbar(error || 'error');
        console.log(error);
      },
    }
  );
  return {
    createSuperSubCategories: mutate,
    isLoading,
  };
};

export const useUpdateSuperSubCategoriesById = () => {
  // const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    (data) => {
      const formData = data;
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of formData.entries()) {
        console.log('keyvalue', key, value);
      }
      const id = formData.get('id');
      return api.put(`/subsubcategory/update/${id}`, formData);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['_updateSuperSubCategoriesById']);
        // enqueueSnackbar(data?.data?.message || 'success');
      },
      onError: (error) => {
        // enqueueSnackbar(error || 'error');
        console.log(error);
      },
    }
  );
  return {
    updateSuperSubCategories: mutate,
    isLoading,
  };
};

export const useDeleteSuperSubCategoriesById = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (id) => {
      const _id = id;
      return api.delete(`/subsubcategory/delete/${_id}`);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['_deleteSuperSubCategoriesById']);
        enqueueSnackbar(data?.data?.message || 'success');
      },
      onError: (error) => {
        enqueueSnackbar(error || 'error');
        console.log(error);
      },
    }
  );
  return { DeleteSuperSubCategory: mutate };
};
