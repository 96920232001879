/* eslint-disable react/jsx-boolean-value */
import { Divider, IconButton, MenuItem, Select, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Iconify from '../../../components/iconify';
import Image from '../../../components/image';
import MenuPopover from '../../../components/menu-popover/MenuPopover';
import { useStatusUpdateProductById } from '../../../services/productServices';
import { getFormattedImageURL } from 'utils/helper';

ProductTableRow.propTypes = {
  row: PropTypes.object,
  tabValue: PropTypes.string,
  index: PropTypes.number,
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
};

export default function ProductTableRow({ tabValue, row, index, onEditRow, onDeleteRow }) {
  const [statusPage, setStatusPage] = useState(null);
  const [openPopover, setOpenPopover] = useState(null);
  const { _id, title, partNo, images, isActive } = row;
  const rowValues = row?.[`${tabValue}Rows`][0];

  const { updateProduct } = useStatusUpdateProductById();

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  useEffect(() => {
    if (isActive === true) {
      setStatusPage(true);
    } else {
      setStatusPage(false);
    }
  }, [isActive]);

  const onSubmit = async (data) => {
    setStatusPage(data);
    const payload = {
      _id,
      isActive: data,
    };
    updateProduct(payload);
  };

  return (
    <>
      <TableRow hover>
        <TableCell align="left">{index + 1}</TableCell>
        <TableCell sx={{ display: 'flex', alignItems: 'left' }}>
          <Image
            disabledEffect
            alt={title}
            src={
              images !== null
                ? getFormattedImageURL(images[0]?.values[0])
                : 'https://picsum.photos/seed/picsum/200/300'
            }
            sx={{ borderRadius: 1.5, width: 48, height: 48, mr: 2 }}
          />
        </TableCell>

        <TableCell align="left">{title}</TableCell>

        <TableCell align="left">{partNo}</TableCell>
        <TableCell align="left">{rowValues?.mrp}</TableCell>
        <TableCell align="left">{rowValues?.perProductPrice}</TableCell>

        <TableCell align="left">
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            onChange={(e) => onSubmit(e.target.value)}
            value={statusPage}
            sx={{ height: '40px', width: 120 }}
          >
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>InActive</MenuItem>
          </Select>
        </TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            onEditRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Edit
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            onDeleteRow();
            handleClosePopover();
          }}
        >
          {' '}
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>
      </MenuPopover>
    </>
  );
}
