/* eslint-disable */

import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
// next
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Accordion
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

// routes
import { useGetAllCategories } from 'services/categoryServices';
import { useGetAllSubCategories } from 'services/subCategoryServices';
import { useGetAllSuperSubCategories } from 'services/superSubCategoryServices';
// utils
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';
import { allowOnlyNumbers } from 'utils/inputValidation';
// components
import FormProvider, {
  RHFAutocomplete,
  RHFCheckbox,
  RHFEditor,
  RHFRadioGroup,
  RHFSwitch,
  RHFTextField,
} from '../../../components/hook-form';
import Iconify from '../../../components/iconify/Iconify';
import { useCreateProduct, useCreateProductImageUrl } from '../../../services/productServices';
import AddProductImages from './variants/AddProductImages';
import AddQuantityVariants from './variants/AddQuantityVariants';
import AddVariants from './variants/AddVariants';
import VariantsMatrixTable, { createMatrixWithVariants } from './variants/VariantsMatrixTable';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

ProductAddEditForm.propTypes = {
  isEdit: PropTypes.bool,
  currentProduct: PropTypes.object,
};

export default function ProductAddEditForm({ isEdit, currentProduct }) {
  const navigate = useNavigate();
  const [openVariant, setOpenVariant] = useState(false);
  const [openVariantQuantity, setOpenVariantQuantity] = useState(false);
  const [isAutoFill = false, setIsAutoFill] = useState(false);
  const [seoTagsOptions, setSeoTagsOptions] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [supSubCategories, setSupSubCategories] = useState([]);
  const [openUploadFile, setOpenUploadFile] = useState({
    status: false,
    name: '',
  });

  const NewProductSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string(),
    partNo: Yup.string(),
    seoTitle: Yup.string(),
    seoMetaDescription: Yup.string(),
    seoSlug: Yup.string(),
    seoTags: Yup.array(),
    isActive: Yup.boolean().default(true),
    b2cRows: Yup.array(),
    b2cColumns: Yup.array(),
    b2bRows: Yup.array(),
    b2bColumns: Yup.array(),
    // leadVariant: Yup.string().required('Lead Variant is required'),
    categoryIds: Yup.array(),
    subCategoryIds: Yup.array(),
    supSubCategoryIds: Yup.array(),
    variants: Yup.array(),
    quantityVariants: Yup.array(),
    visibleToB2C: Yup.boolean().default(true),
    visibleToB2B: Yup.boolean().default(true),
    brandName: Yup.string(),
    productId: Yup.string(),
    images: Yup.array(),

    isCommonMrp: Yup.boolean().default(false),

    commonMrp: Yup.string().when('isCommonMrp', {
      is: true,
      then: () => Yup.string().required('MRP is required'),
    }),
    perProductPrice: Yup.string().when('isCommonMrp', {
      is: true,
      then: (value) => {
        if (value) {
          return Yup.string()
            .required('Price is required')
            .test(
              'mrp',
              'Price should be less than MRP',
              (value) => Number(value) <= Number(values?.commonMrp)
            );
        }
      },
    }),
  });

  const defaultValues = useMemo(
    () => ({
      title: currentProduct?.title || '',
      description: currentProduct?.description || '',
      partNo: currentProduct?.partNo || '',
      leadVariant: currentProduct?.leadVariant || '',
      variants: currentProduct?.variants || [],
      quantityVariants: currentProduct?.quantityVariants || [],
      b2cRows: currentProduct?.b2cRows || [],
      b2cColumns: currentProduct?.b2cColumns || [],
      b2bRows: currentProduct?.b2bRows || [],
      b2bColumns: currentProduct?.b2bColumns || [],
      seoTitle: currentProduct?.seoTitle || '',
      seoMetaDescription: currentProduct?.seoMetaDescription || '',
      seoTags: currentProduct?.seoTags || [],
      seoSlug: currentProduct?.seoSlug || '',
      isActive: currentProduct?.isActive || true,
      visibleToB2C: currentProduct?.visibleToB2C || true,
      visibleToB2B: currentProduct?.visibleToB2B || true,
      isCommonMrp: currentProduct?.isCommonMrp || false,
      commonMrp: currentProduct?.commonMrp || '',
      perProductPrice: currentProduct?.perProductPrice || '',
      brandName: currentProduct?.brandName || '',
      categoryIds: currentProduct?.categoryIds || [],
      subCategoryIds: currentProduct?.subCategoryIds || [],
      supSubCategoryIds: currentProduct?.supSubCategoryIds || [],
      images: currentProduct?.images || [],
    }),
    [currentProduct]
  );

  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    trigger,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;
  const values = watch();
  const { mutate: createProduct, isLoading: isCreatingProduct } = useCreateProduct();
  const { mutate: createImageUrl } = useCreateProductImageUrl();

  const {
    data: categoryData,
    isLoading: categoryLoading,
    isError: categoryError,
  } = useGetAllCategories();

  const {
    data: subCategoryData,
    isLoading: subCategoryLoading,
    isError: subCategoryError,
  } = useGetAllSubCategories();

  const {
    data: supSubCategoryData,
    isLoading: supSubCategoryLoading,
    isError: supSubCategoryError,
  } = useGetAllSuperSubCategories();

  // This is to set the seo title, meta description, slug and tags
  useEffect(() => {
    if (values?.title !== '') {
      setValue('seoTitle', values?.title);
      setValue('seoMetaDescription', values?.title);
      setValue('seoSlug', uniqueSlugGenerator(values?.title));
      setValue('seoTags', []);
    }
  }, [values?.title]);

  // This is to generate and set the product id
  useEffect(() => {
    if (!isEdit) {
      setValue('productId', uuidv4());
      // setValue('quantityVariants', []);
    }
  }, []);

  // This is to set the categories
  useEffect(() => {
    if (categoryData) {
      const items = categoryData?.map((item) => {
        return {
          title: item?.name,
          value: item?._id,
        };
      });
      setCategories(items);
      setSubCategories([]);
      setSupSubCategories([]);
      setValue('subCategoryIds', []);
      setValue('supSubCategoryIds', []);
    }
  }, [categoryData, values?.categoryIds]);

  // This is to set the sub categories
  useEffect(() => {
    if (subCategoryData) {
      const filterSubCategories = values?.categoryIds?.map((item) => {
        return subCategoryData?.map(
          (subItem) =>
            subItem?.categoryId === item?.value && {
              title: subItem?.name,
              value: subItem?._id,
              ...subItem,
            }
        );
      });

      const filterSubCategoriesArray = filterSubCategories?.map((item) => {
        return item?.filter((subItem) => subItem);
      });

      const finalSubCategories = filterSubCategoriesArray?.flat();
      setSubCategories(finalSubCategories);
      setValue('subCategoryIds', []);
      setSupSubCategories([]);
      setValue('supSubCategoryIds', []);
    }
  }, [subCategoryData, values?.categoryIds.length]);

  // This is to set the super sub categories
  useEffect(() => {
    if (supSubCategoryData) {
      const filterSupSubCategories = values?.subCategoryIds?.map((item) => {
        return supSubCategoryData?.map(
          (subItem) =>
            subItem?.subcategoryId === item?.value && {
              title: subItem?.name,
              value: subItem?._id,
              ...subItem,
            }
        );
      });

      const filterSupSubCategoriesArray = filterSupSubCategories?.map((item) => {
        return item?.filter((subItem) => subItem);
      });

      const finalSupSubCategories = filterSupSubCategoriesArray?.flat();
      setSupSubCategories(finalSupSubCategories);
      setValue('supSubCategoryIds', []);
    }
  }, [supSubCategoryData, values?.subCategoryIds]);

  // This is to prevent form submission on enter key press
  useEffect(() => {
    const form = document.querySelector('form');
    form.addEventListener('keypress', (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    });
  }, []);

  // This is a final form submission
  const onSubmit = async (_data) => {
    console.log('DATATATA', _data);
    if (isEdit) {
      // await updateProduct(id, data);
      // onChangeTab(0);
    } else {
      console.log('ELSEDATATATA', _data);
      await createProduct(_data, {
        onSuccess: () => {
          reset();
          navigate(PATH_DASHBOARD.product.list);
        },
      });
    }
  };

  const handleOpenUploadFile = (tag) => {
    setOpenUploadFile({
      status: true,
      name: tag,
    });
  };

  const handleCloseUploadFile = () => {
    setOpenUploadFile({
      status: false,
      name: '',
    });
  };

  const saveAndGenerateMatrix = (allValuesProps, b2bMatrix = true) => {
    const { b2cRows, b2cColumns, b2bRows, b2bColumns } = createMatrixWithVariants(
      allValuesProps,
      b2bMatrix
    );

    if (
      b2cRows.length > 0 &&
      b2cColumns.length > 0 &&
      b2bRows.length > 0 &&
      b2bColumns.length > 0
    ) {
      setValue('b2cRows', b2cRows);
      setValue('b2cColumns', b2cColumns);
      setValue('b2bRows', b2bRows);
      setValue('b2bColumns', b2bColumns);
    } else {
      setValue('b2cRows', b2cRows);
      setValue('b2cColumns', b2cColumns);
      setValue('b2bRows', []);
      setValue('b2bColumns', []);
    }
  };

  const uniqueSlugGenerator = (title = '') => {
    const slug = title
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');

    return slug;
  };

  const leadVariantArray = values?.variants?.filter(
    (item) => item?.title.toLowerCase() === values?.leadVariant?.toLowerCase()
  );

  console.log('errors', errors);
  console.log('Values', values);
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} direction="row">
          {/* left Side Grid */}
          <Grid item xs={12} md={8}>
            {/*  Basic : Title & Description */}
            <Card sx={{ p: 3, mb: 3 }}>
              <Stack spacing={3}>
                <RHFTextField name="title" label="Title" />
                <Stack spacing={1}>
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    Description
                  </Typography>
                  <RHFEditor simple name="description" />
                </Stack>
                <RHFTextField name="partNo" label="Part No." />
              </Stack>
            </Card>

            {/*  Variants  */}
            <Card sx={{ p: 3, mb: 3 }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Variants (Size, Color, etc.)
                </Typography>

                <Button
                  variant="outlined"
                  size="medium"
                  startIcon={
                    <Iconify
                      icon={values?.variants?.length > 0 ? 'bx:bxs-edit' : 'bx:bxs-plus-circle'}
                      width={20}
                      height={20}
                    />
                  }
                  onClick={() => {
                    setOpenVariant(true);
                  }}
                >
                  {values?.variants?.length > 0 ? 'Edit' : 'Add'}
                </Button>
              </Stack>

              <Stack spacing={2}>
                {values?.variants?.map((item, index) => (
                  <Stack spacing={1} key={index}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      {item.title}
                    </Typography>
                    <Stack direction="row" flexWrap="wrap">
                      {item?.options?.map((tag) => (
                        <Chip
                          size="medium"
                          key={tag}
                          label={tag}
                          sx={{ mr: 1, mb: 1, color: 'text.secondary' }}
                        />
                      ))}
                    </Stack>
                  </Stack>
                ))}
              </Stack>

              <Dialog
                fullWidth
                maxWidth="sm"
                open={openVariant}
                onClose={() => {
                  setOpenVariant(false);
                }}
              >
                <DialogContent>
                  <AddVariants
                    onClose={() => {
                      setOpenVariant(false);
                    }}
                  />
                </DialogContent>
              </Dialog>
            </Card>

            {/* Quantity Variants  */}
            <Card sx={{ p: 3, mb: 3 }}>
              <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Quantity Variants
                </Typography>
                <Button
                  variant="outlined"
                  size="medium"
                  startIcon={
                    <Iconify
                      icon={
                        values?.quantityVariants?.length > 0 ? 'bx:bxs-edit' : 'bx:bxs-plus-circle'
                      }
                      width={20}
                      height={20}
                    />
                  }
                  onClick={() => {
                    setOpenVariantQuantity(true);
                  }}
                >
                  {values?.quantityVariants?.length > 0 ? 'Edit' : 'Add'}
                </Button>
              </Stack>

              <Stack direction="row" flexWrap="wrap">
                {values?.quantityVariants?.length > 0 &&
                  values?.quantityVariants?.map((tag) => (
                    <Chip
                      size="medium"
                      key={tag}
                      label={tag}
                      sx={{ mr: 1, mb: 1, color: 'text.secondary' }}
                    />
                  ))}
              </Stack>

              <Dialog
                fullWidth
                maxWidth="sm"
                open={openVariantQuantity}
                onClose={() => {
                  setOpenVariantQuantity(false);
                }}
              >
                <DialogContent>
                  <AddQuantityVariants
                    onClose={() => {
                      setOpenVariantQuantity(false);
                    }}
                  />
                </DialogContent>
              </Dialog>
            </Card>

            {(values?.quantityVariants?.length > 0 || values?.variants?.length > 0) && (
              <Card sx={{ p: 3, mb: 3 }}>
                <Stack spacing={2}>
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    <RHFCheckbox
                      name="isCommonMrp"
                      label="Do You Have A Common Variants For All Variants?"
                    />
                  </Typography>
                  <Stack
                    spacing={2}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {values?.isCommonMrp && (
                      <Stack
                        spacing={2}
                        direction="row"
                        alignItems="start"
                        justifyContent="space-between"
                      >
                        <RHFTextField
                          name="commonMrp"
                          label="Common MRP"
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                            inputMode: 'numeric',
                          }}
                          onInput={(e) => {
                            allowOnlyNumbers(e);
                          }}
                        />
                        <RHFTextField
                          name="perProductPrice"
                          label="Single Product Price"
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                            inputMode: 'numeric',
                          }}
                          onInput={(e) => {
                            trigger('perProductPrice');
                            trigger('commonMrp');
                            allowOnlyNumbers(e);
                          }}
                        />
                      </Stack>
                    )}
                  </Stack>
                  {values?.commonMrp &&
                    values?.perProductPrice &&
                    values?.quantityVariants?.length > 0 && (
                      <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isAutoFill}
                              onChange={(e) => {
                                setIsAutoFill(e.target.checked);
                              }}
                            />
                          }
                          label="Do you want to auto fill all the varient price?"
                        />
                      </Typography>
                    )}

                  {isAutoFill && (
                    <Stack spacing={2} direction="row" alignItems="center">
                      <FormLabel id="demo-radio-buttons-group-label">Discount Type :</FormLabel>
                      <RHFRadioGroup
                        row
                        spacing={4}
                        name="discountType"
                        options={[
                          { value: 'percentage', label: 'Percentage' },
                          { value: 'flat', label: 'Flat' },
                        ]}
                      />
                    </Stack>
                  )}

                  {values?.discountType && (
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid container spacing={2}>
                        {values.quantityVariants?.map((item, index) => (
                          <Grid item lg={6} width="100%" key={item}>
                            <Stack spacing={2} key={index}>
                              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                {values.discountType === 'percentage'
                                  ? `For ${item}+ Quantity Percentage Is`
                                  : `For ${item}+ Quantity Flat Amount Is`}
                              </Typography>

                              <RHFTextField
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {values.discountType === 'percentage' ? '%' : '₹'}
                                    </InputAdornment>
                                  ),
                                }}
                                name={`for${item}PlusQuantityDiscountIs`}
                                label={
                                  values.discountType === 'percentage'
                                    ? 'Enter Percentage'
                                    : 'Enter Flat Amount'
                                }
                                onInput={(e) => {
                                  allowOnlyNumbers(e);
                                  if (values.discountType === 'percentage') {
                                    if (e.target.value > 100) {
                                      e.target.value = 100;
                                    }
                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                  }

                                  if (values.discountType === 'flat') {
                                    if (e.target.value > Number(values.commonMrp)) {
                                      e.target.value = Number(values.commonMrp);
                                    }
                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                  }
                                }}
                              />
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Stack>
                  )}
                </Stack>
              </Card>
            )}

            {/* Save and Proceed for Next  */}
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button
                variant="contained"
                startIcon={<Iconify icon="bx:bxs-check-circle" />}
                // disabled={values.quantityVariants.length ===0 }
                onClick={() => saveAndGenerateMatrix(values)}
              >
                Save and Proceed
              </Button>
            </Stack>

            {/*  B2C Variants Matrix Table  */}
            {values?.b2cRows?.length > 0 && values?.b2cColumns?.length > 0 && (
              <Card sx={{ p: 3, my: 3 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary', pb: 2 }}>
                  Business to Customer (B2C) Variants Matrix
                </Typography>
                <VariantsMatrixTable
                  name="b2cRows"
                  rows={values?.b2cRows}
                  columns={values?.b2cColumns}
                />
              </Card>
            )}

            {/*  B2B Variants Matrix Table  */}
            {values?.b2bRows?.length > 0 && values?.b2bColumns?.length > 0 && (
              <Card sx={{ p: 3, my: 3 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary', pb: 2 }}>
                  Business to Business (B2B) Variants Matrix
                </Typography>
                <VariantsMatrixTable
                  name="b2bRows"
                  rows={values?.b2bRows}
                  columns={values?.b2bColumns}
                />
              </Card>
            )}

            {/* Bottom Image Container  */}
            <Grid container spacing={3} direction="row">
              <Grid item xs={12} md={12}>
                {/*  Images  */}
                <Card sx={{ p: 3, my: 3 }}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Attach Images
                    </Typography>
                    <Stack spacing={2}>
                      <AddProductImages tag="base" />
                      {leadVariantArray?.length > 0 &&
                        leadVariantArray[0]?.options?.map((tag, index) => (
                          <AddProductImages tag={tag} />
                        ))}
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          {/* right Side Grid */}
          <Grid item xs={12} md={4}>
            {/*  Product Categories  */}
            <Card sx={{ p: 3, mb: 3 }}>
              <Stack spacing={2}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Product Status
                </Typography>
                <RHFAutocomplete
                  name="categoryIds"
                  label="Categories"
                  multiple
                  options={categories}
                  isOptionEqualToValue={(option, value) => option.title === value.title}
                  getOptionLabel={(option) => option.title}
                  filterSelectedOptions
                  ChipProps={{ size: 'small' }}
                />
                <RHFAutocomplete
                  name="subCategoryIds"
                  label="Sub Categories"
                  multiple
                  options={subCategories}
                  isOptionEqualToValue={(option, value) => option.title === value.title}
                  getOptionLabel={(option) => option.title}
                  filterSelectedOptions
                  ChipProps={{ size: 'small' }}
                />
                <RHFAutocomplete
                  name="supSubCategoryIds"
                  label="Super Sub Categories"
                  multiple
                  options={supSubCategories}
                  isOptionEqualToValue={(option, value) => option.title === value.title}
                  getOptionLabel={(option) => option.title}
                  filterSelectedOptions
                  ChipProps={{ size: 'small' }}
                />

                <RHFAutocomplete
                  name="brandName"
                  label="Brand Name"
                  // multiple
                  freeSolo
                  options={['C1', 'C2'].map((option) => option)}
                  ChipProps={{ size: 'small' }}
                />
              </Stack>
            </Card>

            {/*  SEO Fields  */}
            <Accordion>
              <AccordionSummary
                expandIcon={<Iconify icon="material-symbols:keyboard-arrow-down" />}
              >
                <Typography>SEO : Search Engine Optimization fields</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={3}>
                  <RHFTextField name="seoTitle" label="Page Title" />
                  <RHFTextField name="seoSlug" label="URL Link (SlUG)" />
                  <RHFTextField
                    name="seoMetaDescription"
                    label="Meta Description"
                    multiline
                    rows={2}
                  />
                  <RHFAutocomplete
                    name="seoTags"
                    size="large"
                    placeholder="Add Tags (Press Enter to add)"
                    multiple
                    freeSolo
                    fullWidth
                    options={seoTagsOptions.map((option) => option)}
                    ChipProps={{ size: 'large' }}
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Card sx={{ p: 3, mb: 3 }}>
              <Stack spacing={2}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Product Visible to
                </Typography>
                <Stack spacing={3}>
                  <div>
                    <RHFSwitch
                      name="visibleToB2C"
                      label="Business to Customer"
                      labelPlacement="start"
                      sx={{ mb: 1, mx: 0, width: 1, justifyContent: 'space-between' }}
                    />

                    <RHFSwitch
                      name="visibleToB2B"
                      label="Business to Business"
                      labelPlacement="start"
                      sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                    />
                  </div>
                </Stack>
              </Stack>
            </Card>

            {/* Final Submit  */}
            {}

            <Stack spacing={2}>
              <LoadingButton
                variant="contained"
                size="large"
                startIcon={<Iconify icon="bx:bxs-check-circle" />}
                type="submit"
                loading={isSubmitting || isCreatingProduct}
                disabled={values.b2cRows.length === 0 && values.b2bRows.length === 0}
              >
                Submit & Add Product
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
