import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from '../components/snackbar';
import api from './api';

export const useGetAllProducts = () => {
  const { data, isError, isLoading } = useQuery(
    ['_getGetAllProducts'],
    () => api.get('/product/all'),
    {
      enabled: true,
    }
  );
  return {
    data: data?.data?.product,
    isLoading,
    isError,
  };
};

export const useGetAllProductEnquiry = () => {
  const { data, isError, isLoading } = useQuery(
    ['_getGetAllProductEnquiry'],
    () => api.get('/product/productEnquiryAll'),
    {
      enabled: true,
    }
  );
  return {
    data: data?.data?.productenquiry,
    isLoading,
    isError,
  };
};

export const useGetProductById = (id) => {
  const { data, isError, isLoading } = useQuery(
    ['_getProductById', id],
    () => api.get(`/product/oneProduct/${id}`),
    { enabled: !!id }
  );
  return {
    data: data?.data?.oneproduct,
    isLoading,
    isError,
  };
};

export const useCreateProductImageUrl = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (data) => {
      const formData = data;
      console.log('www', formData);
      return api.post('/product/uploadImage', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['_createProductImageUrl']);
        console.log(data?.data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
  return {
    mutate,
  };
};

export const useCreateProduct = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (data) => {
      const formData = data;
      return api.post('/product/add', formData);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['_createProduct']);
        enqueueSnackbar(data?.data?.message || 'success');
      },
      onError: (error) => {
        enqueueSnackbar(error || 'error');
        console.log(error);
      },
    }
  );
  return {
    mutate,
  };
};

export const useUpdateProductById = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (data) => {
      const formData = data;
      const id = formData.get('id');
      return api.put(`/adminuser/update/${id}`, formData);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['_updateProductById']);
        enqueueSnackbar(data?.data?.message || 'success');
      },
      onError: (error) => {
        enqueueSnackbar(error || 'error');
        console.log(error);
      },
    }
  );
  return {
    updateProduct: mutate,
  };
};

export const useDeleteProductById = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation(
    (id) => {
      const _id = id;
      return api.delete(`/product/delete/${_id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['_deleteProductById']);
        enqueueSnackbar(data?.data?.message || 'success');
      },
      onError: (error) => {
        enqueueSnackbar(error || 'error');
        console.log(error);
      },
    }
  );
  return { deleteProduct: mutate };
};

export const useStatusUpdateProductById = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    (data) => {
      const formData = data;
      const id = formData?._id;
      return api.put(`/product/updateProductStatus/${id}`, data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['_statusUpdateProductById']);
        enqueueSnackbar(data?.data?.message || 'success');
      },
      onError: (error) => {
        enqueueSnackbar(error || 'error');
        console.log(error);
      },
    }
  );
  return {
    updateProduct: mutate,
    isLoading,
  };
};

export const useUpdateProductEnquiryStatus = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    (payload) => {
      const formdata = payload;
      return api.put(`/product/productEnquiryUpdateViewStatus`, formdata);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['_updateProductEnquiryStatus']);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
  return {
    updateProductEnquiryStatus: mutate,
  };
};
