import { m } from 'framer-motion';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, LinearProgress } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// hooks
import useResponsive from '../../hooks/useResponsive';
// config
import { NAV } from '../../config-global';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
//
import ProgressBar from '../progress-bar';
import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 9998,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function LoadingScreen() {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isInitialized } = useAuthContext();

  const { themeLayout } = useSettingsContext();

  const isDashboard = isInitialized && pathname.includes('/dashboard') && isDesktop;

  const size =
    (themeLayout === 'mini' && NAV.W_DASHBOARD_MINI) ||
    (themeLayout === 'vertical' && NAV.W_DASHBOARD) ||
    128;

  return (
    <>
      <ProgressBar />

      <StyledRoot
        sx={{
          ...(isDashboard && {
            width: `calc(100% - ${size}px)`,
            ...(themeLayout === 'horizontal' && {
              width: 1,
              height: `calc(100% - ${size}px)`,
            }),
          }),
        }}
      >
        {isDashboard ? (
          <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
        ) : (
          <>
            <m.div
              animate={{
                scale: [1, 0.9, 0.9, 1, 1],
                opacity: [1, 0.48, 0.48, 1, 1],
              }}
              transition={{
                duration: 2,
                ease: 'easeInOut',
                repeatDelay: 1,
                repeat: Infinity,
              }}
            >
              <Box sx={{ width: 100, height: 100, cursor: 'pointer' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="170"
                  height="100"
                  viewBox="15 120 800 214"
                  version="1.1"
                >
                  <image
                    id="image0"
                    width="500"
                    height="500"
                    x="0"
                    y="0"
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAAH0CAYAAADL1t+KAAAA/GlDQ1BpY2MAABiVY2BgPMEABCwG
DAy5eSVFQe5OChGRUQoMSCAxubiAATdgZGD4dg1EMjBc1sWjDhfgTEktTgbSH4BYpQhoOdBIESBb
JB3C1gCxkyBsGxC7vKSgBMgOALGLQoKcgewUIFsjHYmdhMROLigCqe8Bsm1yc0qTEe5m4EnNCw0G
0hxALMNQzBDE4M7ghPB//iIGBouvDAzMExBiSTMZGLa3MjBI3EKIqSxgYOBvYWDYdh4hhgiTgsSi
RLAQCxAzpaUxMHxazsDAG8nAIHyBgYErGhYQONymAHabO0M+EKYz5DCkAkU8GfIYkhn0gCwjBgMG
QwYzAB5/Plbfq/o4AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwA
AAAGYktHRAD/AP8A/6C9p5MAAIAASURBVHja7N1ndBPHFgDgO7Nq7hWMwcj03juB0EMJndAh9BJ6
b4EkhBIgBBJqCD0ESAi9ht57Db240W2Muy1Z0u7OvB+W8hzH2LItW8bc7x0f8qTVamYl7d2ZnbkD
gBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQ
QgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEII
IYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGE
EEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBC
CCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQggh
hBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQ
QgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEII
IYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGE
EEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBC
CCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQggh
hBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEHr/MMaIvcuAEEIoCbV3AdD7i3NO
MKgjhBBC77ElS5ZMGDNmzGpRFBX2LgtCCCEAPBmjDGGMEUop12g0ht27d/eoXr36ZQBYb+9yIYQQ
QiiDGGPkxYsXfkWKFImsWrVqUHh4eH57lwkhhBBCmdSrV69dWq3WNHbs2FV4Lx0hhBB6DzHGyPnz
5xv4+/vH+fv76w4dOtTW3mVCCCGEUCbIskw7d+58RKvV8goVKrwODAwsYe8yIYQQQiiDGGPk0qVL
H2m12gR/f3/WoUOHE5IkCdj9jhBCCL1HGGNEkiTapk2bs/7+/lyr1Ypz5syZjQEdIYQQeg/t27ev
o1arNWi1Wu7v76/fuHHjIMYYkWUZExchhBBC7wtJkoSWLVte0Gq13BzU43fs2NHN3uVCCCGEUAYw
xsiNGzdqlChRIsYS1IsWLRq/YMGCr0wmk9Le5UMIIYSQlRhjZPjw4RstAb1w4cJcq9Umjhs3bhUO
lEMIIYTeE7Is07t371bSarWJlqBu7n43jB49eo1Op3O0dxkRQgghZKUJEyasSB7QzX+sVatWF549
e+YvyzLF1jpCCCGUizHGSExMjNtHH310J5WgzitWrPjy4MGDbRljuOwqQgghlJsxxsjdu3crVa1a
NSi1oO7v7x8/YcKEFW/fvs1n77IihBBCKA2MMXL9+vWaJUqUiEwtqGu1WqlSpUrPt2/f3lMURQW2
1hFCCKFcijFGtm3b1lOr1erf0VLnWq1W361bt8MPHz4sj0EdIYSyBjN5oWxBKeVdunT5vXPnzusB
wJTyec45AIDDxYsXW3z66afnV65cOc4yvQ2DO0IIZRyeOFG2iomJcevcufP+J0+e1AIA9bu245yL
5cqVuzV69Oh5rVq12ksp5fYuO0IIvU8woKNsFxMT4zZt2rQfDx482B0AHFI+TwixtNiBc24sVqzY
k06dOm3u0qXL5gIFCoRSSjljjGCQRwihd8OAjnKELMt07dq1w7777rsFnHMnK15icnR0jO7bt++q
zz//fK2fn99Le9cBIYRyMwzoKEcdPHiw7bhx4zYYDAavd23DOQdC/vlqykqlMq5169a7+vTps7pq
1arXBUFg9q4HQgjlNhjQUY6SZZmePHnyk5EjR27S6/X5CSH/6nJPAyeEJNSqVevysGHDFtapU+e8
o6NjoqUrXpZlSgjh2C2PEPpQYUBHdvHgwYNyo0aNWh8YGFiRc25VnvdkLXeDl5dXeNu2bbd/+umn
u6tVq3ZVpVKJeJ8dIfQhw4CO7EYURcWmTZsGrFq1akJ4eHgpK1rpKckAYCxUqNCLxo0b/1W1atVr
devWPefr6/sKu+URQh8aDOjI7uLi4lwmTZq04vDhw59Z21pPBQMAEyFE9vPze9GqVavdHTp0+KN0
6dIPlEqlZJnbji14hFBehQEd5QqW1vqSJUu+iomJ8bPBLiVCiNHHxyesZcuWe1u0aLGvdu3a5xUK
hWzvuiKEUHbAgI5yldDQ0ALz58//eu/evb0ZYy423LWxZMmSD/v27buiXbt2O9zd3WPwnjtCKC/B
gI5yHcYYuXHjRvUZM2b89PDhw2qcc4dk09iySnR3dw/r0aPH+lGjRi10dnbW2bu+CCFkCxjQUa5l
MBjUR44cabl169aBV69ebSjLsqsNdy95eHi8Hjdu3OzPP/98PQ6iQwi97zCgo1xPlmX6+PHjMmvX
rh25a9euvoyxzA6cS42pWbNme3788cfBbm5ucfauK0IIZRYGdJTrybJMLS3ou3fvVlq6dOnU48eP
t5FlOUv32JMltGFly5a9unHjxk6+vr6h9q4vQghlBgZ09F4KCgos9ccf2/pt3769d1RUVD4A0CR/
3srsc/9SsmTJa9u2bfvU29s7wt71QwihjMKAjt47MpMIIQCcETAYDI779u377Lfffht6//79Cpxz
yjl3IpkcRVe/fv19Gzdu7KxSqUR71xMhhDICAzrKExhj5NWrV9rz5883uXbtWr3Tp083iYiIyAdJ
y7UKGdiVadSoUbMmTJjwHU5pQwi9TzCgo1yFMVkJhFPCJQ0AB86JCIQaKFVZPQqdMUYkSVI8ePCg
/JEjR9ocO3asdWBgYClZlp0ppar0uuIVCkXc5s2bP/3oo48u2Pt4IISQtTCgI7tiXKaEyQ6J8W+r
Jsa9baiPj2pA9PElRYPeQTIZCFCqU6odopRKxWulo8sdlUu+6y7efleIxuUtper/ZH2TZUYJgX+t
uiZJkvDy5Uu/nTt3dt27d2+3Z8+eFeWcqwDA+V3lKly48P2DBw/Wc3d3j7X3MUIIIWtgQEd2wZhB
kxgbXiMu8lkHXfjbBiAZfQiY8nFTooMCZOAAwDgDkEWgwIEYEkA0xAHhEhhB9crBW3vH1a/y7+5+
pY4pXAqEpdw/Z4yQVLrMGWPk7t27Ff7666+2R48ebRsUFFSWMeZGKf3PILru3bsvmT9//jjsekcI
vQ8woKMcFxceWCfmxZPPpcTYagIovYAyLy5IDkTUO6hFEUTCAWQZlMwEXNSDUR8LxKADwkzAZREA
lMCIAkQqR0uC2yP3krV25K/cZBNXekUqMxB8GWPk8OHDrebPnz/r6dOnpSFFi51SGrd58+bW9evX
P2/vY4YQQunBgI5yBGOioIt8Xjn29fO6TBdRnkj6Skw2FRZM8X5UNlEwxAMkxgPIRlCYjMAkEZhR
DywhDqhJBCASgEBBpBRAoEAECowLQJgMjHDR6Ox326PUR4c8qrddq/L0e5GRsplMJuX58+frr1u3
bvj58+dbAICLpbWeP3/+wN27dzcuXLjwS3sfQ4QQSgsGdJStGDMJLOZFhYgbf03RhwXXpaZYfxYf
RqhRBwrgQAgBRiSgiTpwTBSBGY2gi9eBykEDXOCgdnQEhUIJJlkEJkpAjDJwowm4zEBmEoiyAJJS
AFFFQFCrweBU8GH+5kN+zF+n00ai0GRo6pksy/To0aPNx4wZ86vBYMhvebx69epHd+7c2RK73hFC
uRkGdJRtTJwRKfhctxf7l/5MRb27kpiAEgICUBCAASUMBMYBTAZQxOtAF6cDhZsTuBTIB5KYCCxR
B1yXCCzRBERiAKIJQJKAcQAJCDBCATgAUA6cUjCKFBgH0FGFIV+nCd/lbzVsviBkfD75jh07Ok+Y
MGED59zSBW9YtWpV508//fSgvY8pQgi9i8LeBUB5k8wkKr663STswE/rlbLBQSHIIAhKAAAQKAGB
AFAgQCQTqBL0YDCZwKlcCVCrKEgRb4FFRAHRJYKQKAPoRJCMDIxMhkQOwIECAw4cOChlAEEgQCgF
jSCAUU3ASSlron+bO4tJKoFJxnlUoTZmpOydOnXaGRYW5rtw4cL5nHNHANCsWLFisiRJh3E9dYRQ
boUBHdmczBmRX99q+nrX/O2McwelUgECUKACAYFSoMBBAACByQDxejDqABzLlwJOTCAHvAAeGQEQ
K4EploChQCEQyvqCSlsc3Dw9wcvDPSmUJzXTgUsG0Ee8Bn10OBievwIeHQMQGQ4kIhYiF335DdOL
5bik70cUjnpry08p5Yyx5fHx8a4rV66cTghxuHv3bo1du3Z1AYA/7H18EUIoNRjQkc2Znl5p/frQ
svVENripBQ4qBQFZ4QgqwgAogIIzUMgMJJ0OZM7BpXxRMMVHAr0TAonhJhC0pYDWKwWuNaqCs6ML
CBxAEhgwQsEIAIQDcMZAogQEUQYo4C06mYwGx2qmaBLx1C3ueZBG4VD+ITjm18dHv637/P7fo7ls
+p4I1ienoZRyWZbnmUwm1bp16yYAgNPMmTMX379//2758uXv2/sYI4RQShjQkU2Jbx/VfLZ97i9U
jMtHlQpQqJRAqQwC5UA4ABAAwhkIogkM+gRwKpgP5NDXQB++hkSNF7gNawfMJz8QogROlaBgJuCE
gYIJQIADMAAGMjAug8rEwUBkEJhJSUCvZDERLpHh0eDVaNR8Y/5iZwyyhruYmC+XdEUiIiJqA8Cl
jNRFEAQmSdKs0NBQv0OHDvXW6XS+Y8aMWafX6xs7Ojom2vtYI4RQchjQkc0wMd7l1c6ZS4khqqBS
pQCiUAIBBjJRghIYKAQKDGQQgIMhLgZcVAqAtxEgvYmDRG0pcG/XHphKAwoQQFZSoJwAEAEYBQAu
ASEAwBgAT/qTCAOlLANnEvC4OIiNSYACLYYc06t9wzhTllaCnE9UcG8Z1G5RMXFCYmJCoIOD89uM
1EmhUMgRERGTL1++3DAqKqr4kydPKq9Zs2aYLMs/CoKAo94RQrkGtXcBUN6hD77a2vDqcXWlIIAC
BFAAACUU1IIACoUaKFGACjjQtxFA43VAqAygT4Q4hQ94tO0IsoMGiKAAJghAeNJXkxAGAmEggAyE
mYCABISJIMgiCMwEhBlBMOkgIS4G8n3cPVoWnEoopLjJCjlmGpUShirkxJ4CkVoIgtQkMiqiaWbq
5e3tHTlo0KClAKAHAM1PP/30VXBwcAl7H2+EEEoOAzqyCS7qnOJvHeujokRJQABRAcCVBBRqBSgo
ByobgcWGgvTqBUBMHKi83YBKFHSJGsjXsSUwjQoIJ0AZBUo5EGAAwJKCuDEBWGI8SAY9yBxApmpg
CgfgCiVwQsBgMIJzxbYvRdCIzBRRlBnDCzIx0odJMd6cxbkoxDgPtcnwkTEmZojRkFAsM/Xr3bv3
r15eXi8BAGRZdp8xY8ZixhhO+0QI5RrY5Y6yTJIZ0d090iHhyuFWCo0ASrUTKFUECAEgwIBJIhAm
gxok4JQAuKiAUgCDTMC5ZVsgTt4ARACioMA4BWAiENkAwGQQlc5AXfKBQqUBhaAAgKSc65wzYNwI
JFEPau9qD1mhjzooZd5MwfhoSUwszYzxoDDGgWjQgZHrQZZFAKZo/Pa1aj5j7HNKaYamsrm7u8de
vHix/+eff75PFEWvy5cvN9q9e3dnANhu7+OPEEIAmFgG2QA3JjgH/zTwvBByozJVCaBUCECVFASl
AAolBaVAQRAEYAoBuMRA46QEWRaAl6kMyoLlQBKUQIkCKGfAmQGYyQTMwQ2UrvkBqAoIIcCJAgA4
EM4BuAzARZBkCZjE9Uq/+p+JxDkUOJvCOXSSGVfLsgySKALjMjCTDFyKBUNiBCTojTHFSlbs4ODk
fSYjdWSMEUopX7NmzdBZs2YtJoQ4FipU6MHp06erqtVqk70/A4QQwi53lGXiq3u12PN7lUGpAqJU
AVUpQaFSgUKpAqJSA1FrgKuUQJQKUDiogWk0wAsVAYVvGeBEAAUnQAkBkCVgkgyCZ2FQufkCCI4A
VAVAlUAoARAoMKoATpXAqRo4UYDgW3WppHANVlDyGVDhEwJETQGAAAFKadJAOoGDLLgAqAuDxqWI
c0y81CCjdTTPTSf9+vVbV7du3ZMAwF69elVk1apVI+19/BFCCAADOsoimUs07tHVlioiAVOpQKUQ
AJQKICoBiFIFCqUyqbWuUAJVqIA6OYLs6g2qYhVAFpwABCWAQgnAJRABQPAuCqB2A06VQCgAoQQ4
JcCpEoCqgQhKIELSamvcveQpQeVzhHLaAAjvQznxBpL0GkoBKCVAQQBKFUApBUoJEAIKvU7XVJZl
t4zWlVLKlUql9N13341Vq9VvAcBx+fLl04KDgzN1Xx4hhGwJAzrKEiIbNPpHF1qCggJVMuAqBYBK
BYJCA1ShBlCqQVZrgGkcQaGiwJy9QKGtDEzlDgoKQIgAhMggcQJKjwJAqAqS0sgR4FQBnCgBiBoI
KIBAUopXiaoSwMnnosrJZysnClcg0BKA+xNCgBDL6HiS9EfN/xL4549zXs5kMmV6lHrRokVDRo0a
NQ8AEoxGo8e33347HwfIIYTsDQfFoSzhMa+KsbDgilzlCgqlCFSpBkGpBKYmQBVKEJQCUIUAnKpB
8soPyvz+wF3yA6cKoCRpURUuS6Bw8gBZcAaBcEjK8i4AAEm65CQAQATgwDinqheCQ4HNROm5iwP1
5kA+AZAaAydJAZ8kC+bJ/pK635P+ZYzlS0hI6Mg5v0UIsTp7nIUgCEwUxRVHjx5tf+fOncanTp1q
u3HjxsEAsNrenwdC6MOFLXSUJYkRLyuJFICoFSBoVEDUSqAaBXClI3C1K4hOnmDy9gNlqSogFKkB
3NkHCBWSAqugAqAK4Co3IEpnoFRI6lInKgBQAqcaYETDZKKJl5WqV0xd4FfqXKYTUeabywl9QQgp
QoA3pkA9ARTACTcH////EQLACAdGOHCa9N9AARITE+txztWZrbcgCPK0adO+BoBEzrlm3rx584OC
gorb+/NACH24sIWOskQ26YvKBYqABjhwBxWAkwtwjRLUGg0oVY4AKjVwBxfgDh5AKQAIAgAVgFEh
aaAbcCBqt6QWO3DgRMk4EeI4VcYRlWsgCC43BYXDY6DqQE7pM0bgJQHOCCe+QHgpQlhxYAJwACCE
g8QkkJgEsiyDJEnAGANRkkCSJBBFE4hi0n9LRCoESZ37mVanTp2LTZo0OXDy5MkuRqPRY8aMGT8x
xtrhuukIIXvAgI6yROVV+I1jpVZxBGRXBZWT1jgXACTCQCQEFCpHENROIAoqAPMyp4QQAKoAIiiA
alwNROkYRhQOYZxqgkChvkEUzmepoHgNQBLNfyIAFwgAIwAcgDgDQDEOxB84cQXCQGYSmGQxaX46
48AYA845MG6JrUn/MvP/TLJBxTnP9PffPOodZs6cOfnatWv14uPjC164cKHZ5cuX6wHAeXt/Lgih
Dw8GdJRpjDOie3VPKznnc1IolSCr1EnT1FQqUKscQKXSAFWqAQQFABWAEJ4UjgkAJ4IIVHGZKJx/
BCBPGICeA8RzgFgloWKqb8clCkDcGEAJTkhVAlAMCCESk8EkicCJZa10CWQCwAgBRjhIlAGjSf+f
cAqECyBxk8gh6w1prVb7bMSIEfPnz5+/AADUq1evHsMYu4CtdIRQTsOAjrKCgMoZiMZdENQaEFQa
UGgcQVCqgQsCSFQAgSqAEgrEckObAHAgAIICiKAMBU51ABBJCUQSQsS0306gAJCPcqgOAB8zwkpK
TAJRSlqRLSmgJ90n58CAExk4MBBkCpzJwLkMRpYIJi4Ck1kihwyPh/sXS9AWRfHnixcvNjx79uyn
Z86caX7ixIkWjLEjGNQRQjkJAzrKNEoo08VGBIJKL3OFQgBBCUCUQICaW8rkn/8BoSATmjRWjQpA
qVJgQIEQ2QGSOuOl1N6Dc04AQMU5uHHgRYBDDQDemgOrxRhxNplMwAhP6sZnHIATIIwA4QCEE5BE
EySY9KAz6EBv0IFBNIAomkDF1QZOQMpQhd9BqVRKb9++/aJZs2ZXoqKiik2ePHnVqVOnKgNArL0/
I4TQhwMDOsoSIijDCDAjAHPk5tHkMnBQAiR1r3MAzi3/zZMSvBABktK/gAtwQZkyATHnXAAAFwCe
jwN3AwAfRqTyMpeqJxj11Q3MWFAvJjpEGiNAAglEJoMkmYAzBrKUdJ9clkXQJ+pAFE3ACQEFUYIg
C6AiagDOQa0gwen3CFjPy8srcsqUKTOnTp36S2RkpN+iRYtmAMAke38+CKEPBybDQFliMCSWffUs
8KxKqfRWKJWgUqpAEAQQFAoQqABEEIBSAaiQ9N+E0H/+AOAiMNgAlF0DYGGcCybOuQejsr9JNtXW
S/qPow0x5RJlg48OEhxEk5g0ah2SWt1G2QBAKEiyDMAkAMaAyQRMXIKI+LcgSSLIsgR6kxGYwQRG
gwiiUQImylChUKUzo5oPa0IEIWv97mayLFPGGG3duvW5R48e1VEqlXF79+6tX65cuXvY9Y4QygnY
QkdZolKpXxCFOpRTwRuoMimVq3lqGggCUEEB5jysQIhllhgBzjkQQtw4ZUUIJ2EAgkMCS6gdZYrs
GifqKxtMiU4ikUACCWRgQGQZGGcggQQGZoB4YzxQhQAgScAZB5GJIPGkke5hkWEQnRAFsXFxEBcb
CyKXQUo0gUmUQBIlkA0ylMtX4UVS/4FtEEI4pZQNGDBgxeTJkyuJoug6b9687zZt2tQewAaj7xBC
KB0Y0FGWEEISHZ1cbxmNpoqcKIGBAghYWuICEKBAgULSsqdJWdzMwRw4565ASPFESPR+nRBaL9oY
VYErOMhMBE6SlkgFJgFhDCTOQQIZTCBCRHwEEIGALMsAMgfGJEgEI7yKDIOnr4JBlxgPomyeiy7L
IJkkYKIIoiiCaJKBmgCqlqi8z5bHwTKNrVOnTtu2bdvW5+bNm83OnTvXfNOmTYMB4Bd7f04IobwP
u9xRlsXGxo6MiopaqlKpiFKpBMHcMhcUFBSCkNT1niz9KoB56hrwhBgpJiE4OqhAosoAhAEITAki
kc1zyGVgjIHMGMjcBCLIEBEXAQxY0utlDjLIkCgnwp3AO/A27i0kGhKBEADJHLwlUQYQOXCTBKIo
gmTg4MKddPu/O1Dcy9H7TXYcj6dPnxZp3rz5tcTERG+NRhN97ty5cvnz53+DXe8IoeyEqV9Rljk6
OJwG4LGMyWD541wGzhhwltTS5lxOGoTOATjnwDnIeknnfDfyToE4SLDcAgeRmwAYB87MwZzLIHEJ
JCJBfGIs6E164MCAyQw4Y2BiIjx69hjexL2BWH00SJIERoMRJJMEXAJgIgdRkkGUZZBEDiBRKF+8
0iU3jVtkdh2PIkWKPB01atQ8QohoMplcNm/ePIhzjOUIoeyFAR1lmUJQPtcoHR5wRoDLAFxmwEFO
mvvNOHBOgAEBzmWgMgCjMpiITK+8vQJ6SASJiSDJEshMBhkYGHkiiCAC4zJITAIGMrxJCIfXUaGg
UqmTMsFxBqJkhNcxL+Hx60cQp4sD0cSTWuESA5NJAoPBAJJkAlmUwGSSgBkBDCwe2tVot0lBlTaZ
spYaxhgZMGDAqqJFi97jnCv++OOPgUajUYMrsiGEshMGdJRljEqipDbdjYNYMAoGkAgDmXFgHIBx
BjIXAbgMgkRBJDIQRvntt1dIpCEWZCkpVStnDBhLankTSQFcBBC5BIlcD4FhARATGwMuzs4APGlb
STZBAtfB5ZuXgQODhIQEkCUJRJMIoskEJpMIsiSBJMogmowgiRKYWCJ4EK/4upU/+iu7j4lGozHM
mDFjGiEkPjw8vNCGDRuG2/tzQgjlbRjQUZYJRJno4uq25VX0y7cPQh9AYNQTeBX3Gt7owiGRG8zB
nYBJkEAmEoTEB5Krb6+DpJBAJCKIIIIJRBBBAiOYIJ7EQ1hiGASFB0JQWCBwFQcnR0egnCYFc2YC
EzPBkQtHIE7UQXx8AnBRBpPBBCajCCajCFzioKRKUCtUoFarQSUogADAF22GfeemcY3OzuNBKeWC
ILAmTZoc69q160YAYEuWLJn+5MmTsvb+rBBCeRd2ASKbkDlTPo18+s3LyOdTZJAURmIAxjlIJhmc
NS6gFtSgVGhALVDY83gfqDzU4K5yBaKgwBkAlwCAAxhNBogX48HBUQNKhRIUXAkKIpgzwQFIsgQi
N8K5Kxfg3qu74OTuConRCaAgClCqNeDu5AouLi7goHRIuo/OGIiiCNzIoZBzoYs/DVzaVA1KE7XR
/PM0j4ksU5PJpKpfv/7dt2/flqhUqdLF3bt3N1Qqs6+7HyH04cIWOrIJgVCxsHvhxd6K/L97CF4R
3uADXop84O2aD5RqJTCFDHplDOx9cgieSS8gxhgBb+PeQHjcG4hNjIIEKRYSeQIQDYC3izc4Cc6g
AhUoQAGUJSWPkWUZZCZDQFAwXLt9A9QODiAaJQAG4OvjC9rChcHLywtUSlXStlLSyHbCCAhcgKaV
P9mhIjkTzAGS5qar1Wrj559/vgYApDt37tQ8cOBAJ1mW8UIaIWRzeGJBNhUdF1PpTXjYIEqhKlcS
rciYi1HQeVAAOPviAlx7dA0Kl9aCg0oNalAnTW8jFJQKBQAHEAgFCgowr+IChEBS0hgiAzVRePHs
Bfy681fIVzo/qNVKiIuNg2L+RUGjcgCBUiDAgYgCSDIDSgBA4mAUjVCA+D9YMnRhPRe1a0xOH5M3
b974NGjQ4HZiYqKPn5/fk+PHj1dxdHRMzK73Sz74jlLKZVkmgiBwxhjR6/WORqNRo1arDQ4ODonm
hDj/2gan19me5SJOEIR/HVs83siWMKAjm5I5o6FvwprE6hJqO3KVM1Xx8qIGHHfe2tn0TMBxqFi5
EjioHEFDVKCkSgBzWlgFMS/iwgG4ImmhFQEoyLIMDBgIkgLu3r8HOw7uAJ/S+cHJywGIpADgHDzd
PUFJFUCAApEJKEAJQBiIkgiygYPKpIr+puf0zyr6VTkjkJxpnae0YMGCGStWrPgSAIRJkyZNHzVq
1A/Z9V6MMcI5JyaTSXX27Nmmly9frn/79u0ajx8/LiuKokqWZUGhUEiCIEhFihR5WqtWrfP169c/
VadOnXPOzs46exyfvMz8eYDRaNScP3++ya1bt2oHBgaWDQ0N1RJCeIECBV6ULVv277p1656pWbPm
JYVCIdu7zOj9hAEd2ZzIJNWxa0cXOimdE4IiAroeeHygRKKjESoXrwRuGldwEBxAUAjABQCBJw1W
o5SCZa42ZwCUUADOIT4+Fm7euAl/37sNzyNfgH/FIuDnXxCMegNERkaDr48vqJQqUFAFAAMATpMy
y0kiMBHAgTtHj2s9amDdEnX2CVRptxOlKIqK9u3bn7h3797Hzs7O4YcPH67t5+f33JatM0vLPCAg
oPTmzZsHHjx48LOIiIgCAKCGtG+vyQAgqlQqw0cffXSmYcOGRzt06LDNy8sr2+bqf0iePn1a9Lff
fhuye/fu3hEREfkAQAkA1JI10UwCADl//vyve/TosW7AgAErPTw8snXwJsp7MKCjbBEU+rhmo75N
T4fHRjoWKOYD5SqWgYL5C4KHmycU8CkASo0CiIKCEpRJmeRo0ldRkiR4E/YWXr14BVeuXoLgV8Gg
clOC0lMJ/sX8wdfXF8Jfv4XY6Fjw8vEGjVqTNGiOJS3NygkHJstAZApuouvrcZ3HD6qhrXbUnsEc
ICnYPn78uGybNm3Oi6Lo8vnnny+bNWvWhJRdsFnZv8lkUv3www8z1q1bN1qWZWfI5BgZzrmpQIEC
IX/88UebIkWKBAs5NOYgr2GMkbVr1478/vvv5xiNRhdiSZOYPtHNze3t7Nmzx7Vr1247QNKtE3vX
B+V+GNBRtpAlWQiKDi7V9ZvO5wNeB3pyGUAySUAkAkyWQK3QgJerF7i4ugAhBKKioyAyJiqpla1h
oHRUgtpNA+BIQOOihoIF/cDbzQNCX4RCfEICuHl6gLuzW1JLniWtfU4BABgHxig4E5foKZ0m9qpZ
qMYRqrB/QLLcK506deqPW7ZsGeHs7Bx17dq14rbq4jYajaphw4b9duzYsc6EkJStvwyxvNbX1/fx
77//3rpYsWJB9j5+7xvGGJk7d+7ctWvXjuecqzP6ep601LBh0KBBS6ZPnz4NAzqyBi7OgrKFoBBk
AHh4/en1zwYuHrorODbQw0HtCkw0gcAYSKIEMRANMVJM0sg3FwJO7k4gCBQENQAIBDRODuDi5gr5
vPKDYAIIfhgEMuPg6uYCGoUKZIMIjAAAAaBAQWYEGAdw4+4xkzqP71uzcPXDlNqmBZxVlhPy+PHj
5+3bt6+zTqfz3LZt2+eyLK/OSgvY0s0+d+7cr48fP96JmNelzUqqWcviOaGhoaUHDBiwKygo6DNs
qVvHMn5h27Ztn69Zs2YqZLLRZL6o0qxZs2acSqUyAsA3sixT/AxQWnDaGspW1bRVzuz/bnudPg37
HnIRBFBTBXCBgsLZETSuzqB21oDGSQVObkpQuQmg8VKDo4crePn4gIujKxijEyHg78dw+/4dYJSD
xlENgiAAZ0krr5lYUs52ahLACZxNNQvUPvtd75lta/lXO0SpIlcE8+Ty588fPm7cuLmcc7Zjx44+
nPMs95IdP3685Zo1a8YAgMLWOeODg4MrdevW7WhQUFApTF1rHUII55wTbpsPQ7VixYrJu3fv7iYI
AsPPAKUFvxwoR5h4oupK0I3m2y/uGH71+ZVWOn08KLgATEhaXJWJUtLiLYQAk0UAxkGjdgQHB2dw
cHAEtVIJKoUSHJQacHV0AQeVBpxULuCqdo1yU7rd9PfyP1y8cPFzRby0fyup2mTv+r6L5V5369at
zwYEBJRfsWJF77Zt2+7JzH4AAG7dulWjd+/eB3Q6Xf7sKjMhBDw9PV9s2bLl03Llyt2z4+F7L1ha
6T/++OO0pUuXfgMAyqzcAgEAcHR0jFmyZMnnLVq0OGDv+qHcCwM6ylESE4WHb5/UPnBn/7hHoY+a
6I16TxAABKUAIHNQgBIUCjV4OLmBm8YVnJQOoKACEFAABQoeju6gIqoEIpNQkNlzT2fPC2W0Zfb4
ehS4LVDVe9MduX379u4TJ05co9Vqn545c6YSQMYGPjHGSHR0tGfTpk2vR0dHF2GMgfVjrjLH39//
/u7duxt5eHhEYddv2iwXXPPmzZv9yy+/TAUAIav7pJTqf/rpp/5t2rTZQSnleF8dpYQBHdmFiRlV
0aYY33vP7n5678WDDiERIVWMcqKXg1IluDp6gqPKERREAZxxUAgKUIAgqhXqSGeF81s3R/fAAp6+
xwp7+Z1wc3YPVBLFexdcDAaDukGDBrfevHlTeMWKFb3btGmz19rXWoLFypUrxy5YsOA7ANBkdzC3
qFKlytmtW7d+ivPV0yfLMiWE8HHjxq3dtWtXf0opyepARbVaHbdhw4YO9evXP2Xv+qHcBwM6sjuJ
izRe1HmFR70tb5AT80UlvK0tiiYfxrmGEAIKhTIhn0O+S17OnvfdXNyCHJSOEQCUCYS+d4E8uT17
9nw2evToDfXr1z+1devW9hnJGiaKoqJu3br3w8PDS9miLMm7hC33gN+xKWvbtu1vP/300yBBEGRs
JaZPr9c79OnTZ//Vq1ebZuR1lsGJKTk7O0dt3769cfny5e/Yu24od8GAjnINmUkCASAAAiMAHAgA
50nfUU4YBc4JBSqRPBBEGGOEMUa7d+9+8MqVKx/v27evQdWqVW9Y+/rDhw+3HjJkyJ8A4JjZInDO
CaVU5JxzSqmpXLly99zc3KJdXFziYmJiPIKDg0uEh4cXoJQSWZYdkwUX47Bhw+ZPmTLlWwCcI50e
xhiJi4tza9my5eVXr16VJoQw+PeAZAb/PxfLAMDNj8nmfxUAoEm+z4IFCwbv37+/br58+cIxfSyy
wICOch3OZApAUpygkn9VOeSFoA4AEBQUVKJfv367ihYtGrhx48bPAKwLkK9fvy7Yrl27k+Hh4cUg
KeuYkIHuXBkAJEdHx9jWrVvvad68+f5GjRodVav/PZiQMUZevXpV+K+//uowZ86cBfDvoGKYNm3a
1KFDhy61dzDJ7QHNUr5bt27V+Pzzz/fExcX5QtJnoABzfkMAkPz9/UOaNm16sFy5cnfy588fBgA8
IiIi38GDBzueOHGiHSRl/PtHvXr1jm7YsKGdSqUy5eb6I4Q+QDJnROKcMM6IuQVLAAAY//+/MmdE
5jJlPO+sWJaYmKjp2bPn/nXr1n1x8+bN6umtxsYYI7Is08ePH5epVq3aM61Wm6jVaplWq+VW/Bn9
/f3jJk6cuCIiIsLb2jI2adLkularFS37KVy4MNdqtfrjx483t/fqcbGxsS4mkynLg85ywu3bt6tU
qFDhpVar1RUuXDhBq9XGV6xY8dXu3bu7iqKYal6Qo0ePttRqtXGpfJam6dOn/2jvOtkLTuH7Lzwg
yTDGSEBAQClJkpTZ/Fa2vJrO6mdo7etJGq/JaBnIP/9ySFqVhSfbB+Gg0+ldZVkWCEk6VoRRzgkA
J8AJB04JYU4uTrFZrLstPgduzXOyLAs6nc75nyqSpB4IS6s6MTHR5euvv14UERHh/vvvv7c1JxMh
73gfYr7HTZ48eVJl4sSJSyVJsibVK6eUGocOHfpj27ZttxFCmOVeubk8JJX3As45Wbp06ZTDhw+3
BwCn5M/5+fk9/vnnn3sIgiBZ6gT//T5wSZIUOp3OmRDCk233z7acc6JUKk0ODg56SP37xAEAdDqd
syzL/wRvvV7vOHfu3Fn16tU72717999Sed27jmFWZfh3xzknhBB++fLlhrNnz/6eMabUaDS6H374
YXjx4sXvJe9hSf79ePjwYYUJEyasSXnszdslfPvttxNr1qx5MY1yZaXeua3lzwEAGGPU2dk53t/f
/xn2TvwfBvRkXr165VuvXr2/GWMKSPuLbO1x41b8d2r/3xqZ/exIKv+mFaBJKn/0HY/zZHVJ/t/v
en+abDtLQGHmP5V5u+T3FC3bCSlem3KfFDJ2fPg7HuPpbEPSeM7yOCOEyJzzlOUSkh03o7meanM9
TcnqmbzuqR0/Yk4TCowxxzRGu3Pz+0hWflYkxfPEXD5Nyh0TQhI455Z7vjRZ3SxlZ+ZyEnMmu5Tf
ueSfe8p6cvj3MaCQ1FVtCeqWe8yS+f3B/G/y72ry/WTlt5fWRW1626f8/5b0vJbjlbKeKX8Xavj/
byIlEQBkzrlk/vwVyd6Lwb8/B8tAUmvrnd5vIKPHLb1jx9N53PIvVSgUhr///ru0q6trQibLledg
QE+GMUZevnxZ2GQyqVK0IlLKTEC3tZz47NJ7j/+0xGxwDDjnnI4ePXr9vXv36r9rozJlylz9+eef
e1lZXnt9zy2tLBIcHFx60KBBeyCdVjQhxPDbb7+1LlSo0POMvNH27dt7r1ixYioh5F15w429e/f+
eeDAgStSHJP0Ph/COYfIyMh8Xbp0+QsAXP9TSc7ZjBkzJjZr1mx/in3/s/+///675rhx49ZDKhcE
Fp988smOadOmTTcPGkvtu0UmTZr08/Xr11MdLa7RaBLWrVvXqUCBAs8ppckX47G2rtntn3Jwzkl8
fLyrq6trLKQT4G7dulVz/PjxayGNAZB9+/Zd2q9fv+WWXoC09mfnY5AZqZZZo9Ek+vr6hmIL/f8w
l3sy5i/G89w+yOZD0LFjx8S0nndwcIgrXrx4oL3Laa0HDx6oIKklld5CHdzf3z/I39//WUb2L4ri
nNDQ0EJ79uz5PJXFQMQ2bdpsmzVr1sTMrLXNGCM+Pj6hGo1GbzAYnCBFkhRCCN28efOgbt26bXB3
d49JbR+XLl3ygf+3oFOtgouLS0yJEiWepFWW3r176+DfvTL/MBgMjlOmTFnx66+/tilevHhgbv8d
W1u+Xbt2VUtvm0OHDnWcPHnydBcXF2ytfsAwl3sqcvNJ4AOS5iAnzvl7MQgqBWt+b6ndAkmXIAjy
4sWLh3bu3Hk9AJj+WVuec/D393/4ww8/fEEpzVQucEopd3Jy0jk6Ourg/122//Ls2bNSv/322xBZ
luk73oNA2p+p5ZaENcfwXXWgL1++LNmjR48jT58+LQqQuwdOWXOeYYyRR48eVYB0fg9v374tcOjQ
oU72rhOyLwzoKLdK77uZa0/UabAmEU7y+8hWo5RyQRDYnDlzxrdo0WInIUQy31tPHDdu3FyNRmMw
zzvP1MUqpZQTQtJqYSs2b948KI2ENO8aU2FBwEbdwW/evCm8Zs2a0ZZy22Kf9nTz5s1akH7qWHL9
+vU69i4rsi/scv8AWVotlpMdY4xERETkCw4OLvnw4cOK165dqydJklKn07kAADg6OuocHBx01atX
v+Tv7x9SvHjxx4UKFXpBKeWW9JbJ92cjOXaxmdrxkGVZeP36tV98fLzr06dPi5tMJnXyYEUI4QUL
Fnzp7e0d7u7uHuXp6RmZ/PWW/Vn++9GjR9YWJ0vH0MHBwaDX6wd+/vnn+a5fv97Qx8fneevWrfeY
y5apfTPGiCRJimrVqqkhjcASGhpa6Pbt29WqV69+7V27ykrdrGUZvZ8Xgvnr168LmQN1eudqxbVr
1+rau7zIvjCgf6DMA7WK7dq1q0fLli07BgYGlpIkSSBJeT81qWzP9+zZ0wMAQBAEVrJkySfLly/f
+uDBg6PlypW7AwDcxvcs0wvoNg34nHMSGBhY8uzZs0169+7d5ubNm3UTExOdLNPDkm1qmeYFnHPg
nHOlUml0cHDQ9evX70KVKlWuXb9+/WzVqlWvMsZkgKTA/uDBA4AcGpDk6OiYGBkZ2b1FixYXBg4c
uFyhUIhZ3Wd8fLxrQkKCe1rHnXOuPHr0aGsAuGb1jrNHpi9ecgvLb2n//v2dGWMaa14THBxc3GQy
KVUqVZY/b/R+woD+AUgeaGNiYty3bt3a6ejRo+3Onj3bxHyy+Gfe/buyjZGk+TBqAABZluHRo0eV
Hz16VG7hwoXfFixY8HWjRo0Od+zYcQtj7JKNgnpGR9hn+FgAALx69cpv4cKFA48dO9bhyZMnZSCp
Bao01/md+0mW9xwkSVLGx8c7nzx5stPJkyfbLl68mLm5uUXXrl37fKNGjY7ExsZuf/XqlQ0OSYbq
GHn79u0eWq32qS0Wbnn69Gkx83TOdyKEKI8ePdqWMfZtJr8DtgrC7+PtmP949epVodatW4+F9AdS
/iMuLs4VACLtXXZkHxjQ8zhLl6/JZFKuWbNm1EcffTQpISHBk3OuojTLjVwl51z56tWrYlu2bBm2
devW/hUrVrx78uTJrxs0aHCcUspyW7cn55zIsgycc7pr167uzZo1W6zX670gla7kTK6MpQQAiI2N
LXD06NHOR48e/WzRokXfdu3adT3kUKvRcswrV658y1a9JmfOnGkGyS783iUkJKRYTEyMOwBEp/K0
LaaCWnUIbLgvu5AkSTF+/PjVUVFRha19DeecJiYmOgIG9A8WBvQ8jlLKExISnMaMGbP+2LFjnxFC
BIB/r65lI4Rz7nDnzp1a/fr121OnTp0LU6dOnQbZ1/2aqQAgCAKLiIjwnj59+pK//vqrKyFEYe46
z5b1xAkhJDIysuDPP/88HgAcsulYvJPlPn5WgjrnnJw9e7Y5vDuxyT8YY4rXr1/7wX8Duq2+bNbs
571uoTPGyMSJE5deunTpE8jAxQkhhGs0mkRrt0d5Dwb0PIoxRjjn8Pr1a79u3bptuXv3bn0wJwnJ
AZrLly837dix46kff/xxzpgxYxYkHyCWQ13y/zkelFIeGBhYsnPnzn8GBwdXAfh313l2SHa8M7sq
mlX1sgxcu3Tp0sfx8fFujDHq4uIS16BBgxOUJi0zm9nA/vz5c/9bt26lOxfaXF8aExPjkZlDZcPD
8l4F9OS/C1mW6ZQpU5bs3LmzH1jRI5ICd3Z2xnnoHzAM6HmQJZgHBweX7NWr1943b96UBjuc5GRZ
dvrxxx+/vXz5cuOQkJDhnPNgS/lysive8n4BAQGlunbtejgiIqJoTh+LbKwbvXHjRrWBAwdOOnv2
7CeiKGrg/3O1uaenZ/iMGTO2jRs3bh6lNDIzx37Lli0DUklWkypKKY+KivKy93GB9yioU0q5JEnC
xYsX67Zv337u3bt3a1t7vJNzd3ePUKvVRnvXB9kPBvQ8iFLKL168WH/YsGG/RUdHF7FjOQAAVJcu
XWreokWLW7179145bNiwRfny5XsLkG5gt+mguFevXvn17Nlze2RkZJ4I5owxcuDAgQ5t27Ydf//+
/aqQ1J3/T/es5ZZKVFSU36ZNm0bv2bOn5+bNm2cAwAZrgzpjjDx79qzIp59+2h+sHJjFOacmkynD
wSgDctWYDGukdrwtaaYfPnxY4eLFiw2aNWvWIjg4uBSYe3IycUuMNWrU6HhuG7OCchYG9DzE0nUX
GBhYsnXr1tsNBkOB7OpKziiDweCydu3aKXv27Ol17dq1LjVr1rycxZOP1RWLjo727N27996QkJBK
9j4OWSXLMpFlWTF48OBNx44dawfv6MpPEQyUcXFxvl9++eVKvV7vOGTIkOXWvBfnnMyYMeNHnU6X
LwNF5Gq12mDnw2TXL33KAM45h9jYWJcTJ060un//fqUHDx5UKl++fHVzngcCSV3r/7oIysStMalu
3brnli5das+qIzvDgJ4HzZ079zuDweBDKbX1wLcsi4iI8OvXr9+hEydOdG/UqNFxQRDelWzEJi10
xhgZOnTosqCgoCr2rntWWS7YFi9ePO3YsWMdIQPTmczUixYt+vbvv/++WKVKlZvpbbx9+/ae586d
awpWDIYDAMvAQu7p6ZnaKOvccWWZQyIiIryOHj366dmzZ5s1atSo6rNnz4qZj4FACFGn/F1mdVCm
SqVKaNas2WF71xvZFwb0PObYsWMtT58+3dKcIMYm+ySEAGMs+QmHAwAzL5kpmOeoE2u7CePj4z0G
DRq0d+LEidNDQ0P/8PX1fZ2ZYqX1pCX4bdu2rfeRI0c6go2mMlnqmFZdkyWdsflgu6CgoBJr164d
CRkP5gAAkJiY6Nm/f/+dwcHBzfz9/YMFQfhPVzAAwIsXL7QtW7acBwDO1u7bXFfu5eUVYdNKvydi
Y2Ndz50717hnz54Db968WdtgMDhC0sXQvy6IUvveZPF7wlq1arXHy8srIrcvSIOyFwb0PCQhIcF5
1qxZ8znnTjbcrcQ5lx0cHBIqVKhwp2jRogHOzs7x7u7uUQ4ODvro6Ggvg8HgGBoa6vfkyZPywcHB
JQH+aXG8s2Uny7JmwYIFi7Zu3Tr4zZs3jX18fMJSbJLlSBgREZFv7ty5cyGNJTszghAimi9iGABI
ZcuWfejs7BxPCOFOTk4Jer3eiTFGOeckPDy8wIsXL7TmRWQsa18LkMXf3KxZs743mUyeWdlHZGRk
kQEDBuzYsWPHJwDwr+BrWX7zq6+++lGn0xXI6L4FQRB9fX3flUUnp1rpORrQoqKiPH7++eextWvX
HqzX610hxXiG7KZQKOKGDBmyhHNO0ujxQh8ADOh5yOLFi7988eJFWRvsSlQoFIayZcvebdOmzfYa
NWpcrlmz5uUnT56k+8Lw8HCfu3fvVg0ICChz7ty5ZteuXatnMBhc4R0nuBcvXpTp2rXr8bCwsGYF
ChQIs1ULg3NOvvrqq0VxcXEFM7uPZK1wo7u7e3iLFi321alT53zp0qXvlylT5oE1S5G+efPG58mT
J+VCQkJKBAYGlr5+/Xq9wMDAEgaDwQUA1BkZ/HT16tU6Z86c+QTSX6gjXcHBwVW6det25PHjx71K
liz52HLMExISXMaPH7/69OnTrSGD5wdCCJQsWfKxi4tLfFbL9z5gjJFz5841at++/eJnz56VAytv
TdiYacyYMd9VrFjxTm5eWQ7lDAzoeQBjjAQEBJRq0aLFF5Dxuav/4JyDIAi6Dh06bB0zZsx8f3//
kIwG1/z5878BgMOMsSNDhgxZEhkZ6b1+/frh27dv7/v27dvCnPP/fOeCg4PLjxs3bqPJZGpLKbXk
oc50UGeMkQcPHpQ/fPhwe8hC8OOcyxqNJrp3795rxowZM9/Z2Tkhoy0gHx+fNwDwBgBOWRaySUhI
cP7111+HrFu3blRUVJQvIUSVLKjzd61YtmvXrh6Qhc83pYCAgGodO3Y8fvDgwY8BICQsLKxAp06d
9gUGBlaGTAQnzrmpdevWu3NBl2+2vr95WihZt27d0Llz585njLnaq57t27ffMmLEiMVjxozJEyvL
oax571MkoiRbtmwZyBhzzuy9OEKI5OXlFbJhw4YOixcvHqrVajMczFOilHIvL6+IKVOmfHvu3Lly
U6ZMmZIvX74XqWwHFy5caL5s2bJpsixbvpOZXhkMAGD//v2dOefqLIwjkGrVqnXyyJEjtWfMmDHd
3OrM0vGwrErn6uoaP2rUqEVnz56tOGzYsHkqlSoaACQAMNaoUeOSRqNJdZT4gwcPMhVo05KQkOAz
bdq0ZZcvX67Xpk2b0wEBAVUz+x6EEFOrVq32ZqE4OZlNLkv27dvXac6cOT/YMZhD2bJlr8yfP3+k
JXEQQthCf88xxoher3fcvXt3NwBQZDSAmdfMNjVo0ODQ999/P9zHxyfMFlf6ln1Y/nVwcDAwxn7s
0aPHhlmzZi3ctWvX55xzlaUMAECWLl0608vLK1yW5dWdO3eWIeledYYvOs2pSpsBgCqTFzjikCFD
Fk6dOvVra7rVM3pMLFxdXeMBYObDhw//HDdu3Jry5cvfXrhw4YjUjj9jjBQpUiQ7pt0pLl682Pzi
xYtNICmQC5m5CCKEQOXKlW+UKFEi/fsyWWNN4bI1oAcEBJSeNm3aMs55tmT/e5dkt2fkwoULP1y9
enUPJycnfU6WAeVuGNDfc5RSfvz48XqxsbEZmSv8D0KIsU+fPsumT58+Q6PRZHuWKVdX15hFixYN
zpcv35tVq1ZNhmTfQc45mTlz5g8ajcbIOaeQyRPzixcvtPfv389s8BOHDh26YNq0aV+/q+vb1sqW
LftAr9c3AwD+roup8PDw/JD52wcMknoAFJD6BZISstCVb54FYRg8ePBP5mNm765fll1lkGWZdu/e
fYler8/wgEEbkBQKRWLXrl3XT5069Rt3d/dYO5QB5WIY0POA7du39+GcO2SkNUoIAYVCET979uxR
3bp1+y0nRscmD1Y//PBDqhcPjDGn6dOnL9VoNAJkIoBRSvm6detaQeYClNS3b9+lkydPtiz/mWOB
ydHRMc1FNZ49e1YUMjlKXKFQGN3d3SMjIiLyQSanu6WFc84bNmx4+NNPP92XS0ZZ02fPnhU/duxY
K09Pzwi1Wm0sUaLEY41GY0ye+z4zPVFnz55tcuXKlYY5WBcZAKRChQqFtG3bdnvPnj03aLXap3i/
HKUGA/p7TpIkoUaNGh9ltGuZMcZGjBgxL6eCeUqPHz+uZFnpLCVRFJ1FUczEXpNaUN26desAGQ9c
rHv37qtmzpw5OZcEpX+RJClTLWitVhs8Y8aMydOnT18ONhxQl5yrq+vLBQsWjLTiuOVUEFKcO3eu
1blz5z4hhDBu1qBBg+Dx48dfPXr06G7G2BFICpYgyzK15jNnjJERI0b0hWy4KEpGJIQwQojk4+Pz
unnz5nvr1Klz/pNPPjmkUqnEL7/8MocOIXofYUB/z0VEROSLjIzMl9GAXrp06evDhg370R7BizFG
qlevXjc7sthJkqR4+PBhhqfulStX7uo333wzJTcGc3NO9ZeQ8YAoiaJIR44cuc5kMrlCNswDJ4To
p0yZ8k0mkwNlG0KIgjGmSPb/4enTp5WePn1aaffu3b28vb3Dpk2btq9fv36rCCGPrd3v1atXP7Jh
MTkAiIIgGPPnzx9auXLlG7Vq1TpfpkyZ+8WKFQsoUKBAKMD/l8C19zFFuR8G9PfckydPyhBCaEay
kqlUqpjFixcPdXBwsEvO7devX/tFRER4Z0ee+bCwMN+EhAS3jLxGpVLFzJs3b2RuHWBkXo3rOQDI
lmNm5cUQDQ0NLQhJv3ObHmzz902uXbv2mZ49e/5qoy7gdPeh1+udAdJfvCSt3wPn3OHt27dFt2zZ
MvLPP//s06RJk79evXo1uVChQq/e1RXPGCO3b9+u+vbt20zfOzeX2SgIgrFUqVIPK1SocKtFixb7
q1ates3T0zNSEAS2evXqVF+LXezIGhjQ33Ph4eG+kJR+1dqXSP37919evnz5O/Yq85MnT8oRQrJl
yuTLly/9OecZ6VpmXbt23Vi5cuV0c5vbk0KhkBs3bhwUFBRUFawf+U8hm5KdEEK4RqMJnzlz5qSc
PA6SJCkAMrV4SWoEURQ9jhw50uX69et1Tp48+QUAHE9tQ845uXHjRm3I/DnT6O3t/XLgwIHLu3Tp
ssXLyyvCMugyN/YKofcTzkN/z8XExHiCla0vQgj4+/s/HDNmzHx7nkTOnz/fhBCSLReT4eHhBSAD
32sPD4+XkyZNmvU+tICqVat2FQAyN7jAtmQ3N7fna9eu7VmmTJkHlvn1OSEuLs41G3p2lJGRkcX6
9eu3e8iQIb+HhIQUS9nFTQjhT548KQcZH6jJnJycwsaOHTvz+PHjNYcPH/6TZflgQRAYBnNkSxjQ
33Mmk8mqFph5apE4duzY75ydnXX2Ki9jjJw5c6a5DVpYqe4gLi7O3TzlLe0XJ72/2K1bt1/d3Nxi
squu1jxmrerVq18GAG7PJXEJIUZvb+/AnTt3Nq9fv/4ZSim38mLIJkE/ISHBKbtWECSEOB09erRL
y5YtL58+fboZQNLnZfnMoqOjvSCDty60Wu29Q4cO1RszZsz3Hh4e0ZbHc9sFZPJ64v369xd2ub/n
ZFm26jPknIOHh8ebFi1a7LdjWWlwcHDxwMDA0tn1HkajUW1Ni9EcFKU6deqcy84lZmVZptevX6/9
+vVrPycnp4SXL18+YIw9z8wJvU2bNru/+uqrn0RRtMliM5lgLFOmzPXVq1f38vPze2GPOeeMsWxr
hJi/BzQxMTHfoEGD/jx+/Hh3ADhqWbDG3Btm9fu7u7s/37hxY+eiRYsG58Ygefjw4U+Dg4NLEkL4
kiVLHCVJUjDG5tq7XCjzMKC/55RKpcnKTVndunXPODg4JFq5fbbYtm1bP865gz3LYOHq6hrZoEGD
kymXEM0qWZaJ0WjULF68eOzvv/8+8O3bt76Q1LIjAMCbNm16MCwsbFS+fPneEEKsbq05OTnp6tWr
d/r06dPtwAYLtKQnxcAyQ61atU6tWbOml5ubW4w9WpiMMVK1atVsrzcAgCRJ7uPHj1//+++/tyxT
psz95MUA64K6cfbs2eNLlCgRAJA7W+RhYWG35syZM/f58+elAECglIrFixd/0qFDh+2WC5DcVm6U
Nuxyf88pFAoJrGslmdq0abMzJ+93/qcAJpNqx44dvbPzPVxdXWOtPB5ynTp1ztkytatFQEBAmTZt
2pxetmzZ1xEREcUBwBGSltTUAIDDiRMnOvXq1Wt/XFyce0ZPmJ07d94MAGll9BMBwCazF8zB3CAI
QnTv3r1X/vrrr52zMZinu09KKc+p7H0AADExMQV79uz5V0BAQGlKKXd1dY0mhEjWvLZkyZJ327Zt
uyunypoZBQoUCPPz83sJSd9PNWPMedKkSb8cOnSonb3LhjIHA/p7zsfH5zVYdzIUq1evfsWeZV2z
Zs2oyMjIQjbaXap1dnR01FlzPAghxo8//vikret47969ip06dToaGBhYk3OueUd3Pg0ICKjRsWPH
U48fPy5jbXcsIYS3atVqn1arDXrHJjIAyKVKlboPABmagpfKfXmDs7Pzq+7du685evRone+++26C
k5OT3p4tNp1O52jtmJE06pehDIDR0dGFOnfufPzIkSOtnJ2d4zjnDJJa6Wkx9ezZc31unj9uuTi6
d+9exeSPi6LoPm7cuF8xqL+fsMv9PVewYMGXYM54lRZnZ+e4AgUKhGbn/eJ3kWWZhoaGFmrcuPFk
yOau4uLFiz+25nhwzqF+/fqnbVlHAID27dsv0+l0li72NIWEhFTu2rXr4e3bt7dgjD0BSLuLk1LK
ZVlmAwYMWPHNN98sI4SknJ4nAIBh6NChSy5fvvzR9u3bB3DOrVqghjEGhBAjAIhKpdIwfPjwxQMG
DFhlr+711Oj1ekdRFK1eQ54QYgAAyc3NLcayip2Li0usIAhydHS0F2OM3Lx5s3ZkZKSXOROfknOu
Tnm84uLiCg4dOnRrqVKlHpozz6X3vsZmzZodsnxm9j5u73L9+vVacXFx3ikfNxqNbiNGjPj9p59+
6gsA2+1dTmQ9DOjvuTJlytwXBMEoy7JTWttptdpn9rrvCQB82bJlk41Go2d2v1/+/PnfKJVKgyiK
aR4PpVJpyJ8/f5it3pcQws+ePdv4zp071cG8Yll6gZQxBtHR0f6DBw/+Y+fOnZ94enpGWnMsW7Vq
tW/OnDnzJEnySK1qL1++LDh//vxR0dHRHsePH28DSRcXSkjqkbNcUMmQ1FKVCCGig4NDQv369U93
6tTpjzp16lzw8PCIArDZfO8sY4yR0NBQtSzLVq0Gp1Ao4nv06LGmb9++q5OvAJdaqzkhIcH577//
rr53794uJ06caBkVFeUDSbdIaLL3d3/06FFlSEr7mmbPZr58+UL9/PxeQC534cKFhvDuC2yHSZMm
rbl79+7jihUr2i1nBcoYDOjvOQcHh8RixYoFBgQE1EprO3d39yh7lI9Syu/fv1/hzz//7Au2vcWT
6lndy8srwsfH5/XLly+90nqxp6dn5LvWHc+sDRs2jCSEOFqbtc+yTXBwcOXPP/98z/bt21vIspxI
CEl1Kliyx0InTZq0ddu2bUPhv79heuvWrdqCIMirVq36/NmzZ0Xv3LlT9cqVK/X1er1zTEyMB6WU
ubu7RwmCIFWqVOlmmTJl7pcrV+6us7Nzgh3nRad5wCil/OXLl8TKe+jS0KFDF70rv0Aqj8UDwGkA
OJ2YmKi5efNmzV9++WXsmTNnWjHGki96ZM1yqXL16tWv5vb55aIoKho2bNgTksZ1/AfnHEwmk9vE
iRPXJiYmNlCr1cbc3NuAkmBAzwNq1ap1KSAgoCqksfiGQqGwS0ISWZZpmzZtfpFl2SUn3o9zDvXq
1Tu7bdu20pBGljRHR0e9IAg2GxAXGxvrfuHChYbWzIFPiRBC7t27V3vhwoVff/PNN1Otec2oUaO+
37t3b2eDweCT4inFmTNnmsTFxbm6uLjEFS9ePKB48eIBn3322TaA/88xzsqKY/ZgHpUtE0K4FS10
yc/PL1M9UuZ0yOdkWb5w6tSpZpMnT14ZERFRBKy/VSQXLVo00N7HKy2MMXLx4sW6L168KJHehefD
hw9rTps2bcmiRYuGgf2XxUXpwEFx7zlKKe/Ro8cG8/3PtLaz+WjutFgSVWzatGnQ/fv3a+TU+xJC
oGXLlvsgaf3vtI4Hs2Uwu337djWTyeSY2aQvhBDFhg0bRi9dunSyNXOt/fz8XgwfPnwxIeQ/0xA5
58rjx4+3Sm06XvJEMO9LMLeUVaVSmay8MCVZ7X0RBIE1a9bs6KlTp6pNnjx5squra6i1r7X31ND0
cM7JsmXLphBCrJo+umvXrj6//PLLKHuXG6UPA3oeUL58+bvly5e/ndY2siznyPxdC845efbsWdF5
8+bNhmzKJ54aSimvX7/+aWdn5zRvMdh6+t6jR48qmOudld1oFi1aNHPhwoVfybJM0xshPXz48B+L
Fi36MOXjnHPH1atXj5NlmWYgk1uu5+TkpLM274KtPl9nZ+f4kSNHLj5x4kTVatWqnbLmNRnIDZHj
ZFmmq1atGnXp0qVGYH3KaEVcXJy7vcuO0ocBPY8YOHDgMkhjfrLJZMrONZz/Q6/XOw4ZMuR3g8GQ
PyffV5ZlqlQqxXr16p2DNFrpyVNd2sKTJ0/Kgw1uYRFCND///PPE/fv3d0wvEKtUKvHbb7+dSAhJ
SLEPePjwYfnHjx9neBnZ3EytVhvNeRfSRSm11T1swhgj+fLlC9+xY8cntWvXPpreRZvBYMgViZNS
c+3atdqLFi2aDgBO1r6Gc85dXV1j7F12lD4M6HlEy5Yt9/v6+gYDpN5KfP36daGcmBPLGCOiKCpG
jRq18fHjx9Uz0wVNCEnMly/fK0h7vi9/x2s5AECDBg2OQxoLmYiiqLRlkhJb3o8HAKevv/56YWJi
4jtTvFqC/ccff3y6QYMGx1LZRP3777/3tWGZ7I5Syq0dCyIIglWB34r9MEsvh0KhkFesWPG5v7//
vbReYzAY7JWaN1WWi9eAgICSw4YN2yCKolcGf5fcz8/vub3rgdKHAT0PIIRwBweHxJEjRy4EgMTU
fqxhYWG+lqUnsxNjjH711VeLTp482Q7M07cyiDdu3PiwVqsNgEyuLEYp5c2bNz+oVCoT3rXN27dv
89vyxFuhQoVbYMX8d2vFxMR4HTlypLU1F2ETJ06cRQiJTf4YIUSxb9++znq9Pte2FjPDx8fnjRWb
ccuURFtfxHp7e7/dsmVL28KFC99/xybK4ODgkvY+TslRSnlwcHDxnj177o6KiipKCKHW/i7Nc/7l
4sWLP7HqBciuMKDnAZYWRLdu3TZ//PHHRwD+m/nLZDKpAwICSmXH+1tOmjExMe7Dhg37bevWrUMh
jRH3qbEkC9FoNJETJkyYbX4sQ/uwHAuApBN/796918M7LgoMBoPDs2fPitjqGBQvXvwxIcSWU5WE
4ODgktbc/65YseJtc1rff9U1Ojrad9myZRNzSbYym5ShWLFigZD+hRMzJ1yyycC/+Ph4Z1EUFZb9
+fv7P929e3eTevXq7U95zAGAXL58+WOTyZTh766tWVrmR48ebdmhQ4fjb968Kc05z9B4Fs45FClS
5EmZMmUeZuR1yD4woOchgiDIixYtGubh4fE05RU451x19uzZJtlxcqeUcr1e79C/f//tR48e7QpJ
yTcyxDJ3u0ePHhvLly//tznAp/X9TPNEzRgj48eP/y5//vwh7zpcZ8+ebWKrY1C+fPk7SqXSpqOb
fXx8rB5ZPXbs2PmEkPgUD2tWrlw5MSgoqIQty2VP5cuX/xsAjOl0GXNbjjTfu3dv50GDBm2zBHUA
gPz584dv2rSpU+XKlc9Biu9ifHy8+99//10N4P8ZBHOS5TduMBg033777bzBgwfvjIuL8+ecZ6aH
TmrTps3O3D6vHiXBgJ6HEEJ4/vz533zxxRc/wn9bpg4HDx78LDveNygoqETbtm3P3LhxoynnPNOj
6d3d3Z+PHj16vvn/Zum7SSnlLi4u8VOnTv0aAFI7uTtevnz5Y1sdAzc3t5hKlSrdstX+AIBVrlz5
hjX1BAAoXrx4YOvWrfemciHn8sMPP3xlw3LZVfHixZ8QQiQr0q/aZJQ7Y4wcPny4/alTp1qPGjVq
ffKgTill69ev75ovX76AFK9RXbhwoSFjjNhjMSSTyaT6888/ezZp0uT6hg0bxnPOHc3HJMP7opQa
OnXq9Ic9LkxQxuGHlIdYut4HDBiwytxyAID/D5K7d+9elax2MycfHW40GlXr16//olWrVpcCAgJq
Qia6VS1lI4RIkyZNmu3h4RFlXjgiy99NSinv1KnTn02aNDlgXlTjX65cuVIv+Qk6q1q1arUHMnnf
P6WCBQs+t7ab0/K5T5o0aZZKpYpI8TQ5cuRIuxs3btQAsP09ZRuyqlxlypS5zzln6bXQbXH7gzFG
DAaD5s6dO1UBQHXo0KHPVq1aNcbyGyCEcE9Pz6iZM2dOBgDdPxUhRPXbb7+NuHDhwj8XjNkdEGVZ
prGxsa5//PFH76ZNm16ZOHHiutevX5eDDN76Sqly5crXixcvHmjPVRqR9TCg50FKpVL8+eef+/r5
+d0B+P+VOefcaf369V9k5aRuaRHeunWreocOHU7OnDlzmdFo9M7s/ixlK1Wq1M1u3br9moGXWnWC
oZTyefPmjSlYsOCDlM/pdDr38+fPN8xs2f9VGM5JixYt9kHaS5tay/jpp5/uzchJlDFGtFrtsyFD
hiyFFBcVjDG32bNnL5AkSbDXnHRbBQStVvu8WLFiT9JrodtqBsOtW7dqxMTE5AcAwjl3XLZs2fTr
16/XTr5N69at940YMWI+JFu2NiIiQtu7d+8D3bt3P3D06NFWtpxRYbmgYIyR4ODg4n/88UfvcePG
rapRo0bg5MmTV7948aIyvCOlawaZPvvss815KZdBXoepX/Mg84/v5dOnT9sPGjTojydPntQGAOCc
K7Zt2zaQUsoCAgLWFy9ePDAjP9S3b9/mO3HiRMuBAwe2O3nyZCvOuZN5v/8MassMpVIZP3PmzEkK
hUKylMeWA8x8fHzCNmzY0K1Pnz57wsPDk49Adjp8+HBbxtjJrJ6wCCG8UKFCL5o2bfrXiRMn2kMm
k+kQQkCtVsf269fvl4wEQUsq12HDhv107NixNk+ePPlXbv+bN29+tHXr1r4AsN5WxzUzxwgga8l3
KKV85cqVm+fPn18RkhZQSRVjzCaJlH7++eeJlvchhIDBYPDo27fvvh9++GFQq1at9lu2mzBhwjyF
QiEtXbp0Oufc2VxPl8uXL396+fLlxt7e3mHjx48/UqtWrYsVKlT429fX97WlNyq9MsTFxbnExsZ6
vHz5Uvvw4cMKs2fPLvLq1avC9+7dq/ry5cvCkJSWVgM2GnhoUapUqVufffbZ73369LHlblE2yq3d
b8hGYmNjXXv06HHg/v37Hyfr3jZxzsXatWuf69Wr19ry5cvfLV68eADA/1vgjDGSkJDgHBQUVOrG
jRu19u3b1+3+/fuVJEnScM5tNhWKECJOnjx56rBhw35MfnLr3LnzyatXrzZ+1+uqVKlybt++fQ2s
fR9Zlunp06eb9u/ffycA/JNX3tHR8fXFixcreHp6Rmem/Ml7O6KioryGDh269dq1a00gk8vEcs6l
L774Yt60adO+Sf55ZMS5c+ca9urV6xCkWEzE0dHx9fnz5yt5e3tHZnSfWXXt2rVan3322QkAcH5X
1Tt16rTmp59+GprWfmRZJgaDwaF27doP4uLi/N+xme6vv/6qU7Zs2fuZvVBjjJEHDx5U+PTTTy8R
QpySX4RwzkGpVMauX7++U4MGDf6VPW7Lli39pk+fvgxST9wiA4DJvPiO6OTklODr6xtavHjxx5RS
ZrnNRAhhsbGx7iEhIcV1Op2zTqdzNieGIpDUq6qAbG6MOTo6vt29e3ej0qVLP8TW+fsDW+h5nIuL
S/ymTZs6tmvX7szLly/LAySNeOecq65cudLyypUrDTjnTKFQiEWKFAnu2rVreERERL4SJUoUlSRJ
ae4SV3LOHaxdRSwD5E8//XTb0KFDl2TipJGh7QkhvHHjxsenTp06Y/78+fPB3OrS6/W+ffr02X3u
3LmvP/7447MZrQCllJtMJuXvv//e76effvoyMjLS0mLKFCcnp7eDBw9entoyn9aqV6/e2RYtWuw9
cuRIj2T1B51O571jx44esiyvSC3Pe3ZSKpU2GVsgCAKXZdnQrl27HZs3bx4Hqd82pM+fPy9StmzZ
+xndP8D/L9J27NjRC5K++/96nhACoii6DRkyZPvWrVtbVatW7ZrluZ49e/4aFxfntmDBgvnw39ke
guViWJIkiIuLc4uLiyv0+PHjdNc6yEoPWCYYZ86cOR6D+fsH76F/ADw9PaNmzZo1PvlCHsmXhCSE
OMuy7BEUFFT98uXLrQIDA2tIkuQFAK6cc1fLScjWwbxmzZqnFi5c+IVCofjPvGJbLyZjuQ/4xRdf
LOvWrdtaMKeF5ZyTO3fuNOzVq9dfbdu2PbN27dphsbGxruntjzFGIiIivJcvXz6+UaNGd7766quV
kZGRRSALwRwATCNHjvzB29v7raXMmd3RtGnTZiiVyn8GyJkvxlQbN24cJklSjs+RtiKgW/3lEgSB
jRgxYnEqAwAtaGhoaKHMlpVzTt6+fZt/27ZtfeAdt07M3e+egwYN2h4aGlowedm++OKLpW3atNn2
rtcl/zcDZcpsdTLK+MUXXyzo3r37Zgzm7x8M6Hmc5UfZpEmTY8OHD59HCMkNC0eIpUqVurFq1aqe
zs7OutQ2cHBw0EPaqV8zhXNOvvvuu/GtW7feDAAipf/8BBxv377dYNasWYvr1q0b0Lt3733ffffd
7P3793c6e/Zsk9u3b1c7f/58owMHDnScN2/et717995fr169R99///38Fy9elAEb9HZ5eHi87NOn
zxpbDKAqUqRISNOmTQ+nDByvX78utm3btl62Pq7pUavVNl173tfXN/Trr7+eCgD6VJ5W3rt3r0pm
AxIhhH/zzTcLdTqdb3rbRkdHa8eMGbPeZDKpOOeW1j2fO3fu2GLFil3PwUBsC8bevXuvnDRp0mx7
FwRlDna5fwAsJzZJkr4TBIEtW7bsK855ji7WkoxYp06dE6tWrerl6en5zhXRHBwcdJAU0G160WlO
kMEMBsMXzs7O8du2bRsGyX4HnHONTqfTnD17tu3Zs2c/haT7nsmjIoekVvg/LXEb9Vwkjh49eqGr
q2t8Vndk+bwfPnz43YkTJ1pKkuSdLLBoFi5cOPP58+cntFptjuXnVqvVRrDhetrmWxIbQ0NDC61c
ufLLFOM66I0bN+pkdr33xYsXT/vrr7+6WLMt5xyuXr3auFevXvsWL148pHDhws8JIeDh4REdERHR
atiwYb9duXKlCeTgioOZQQjRjRs3btbIkSMX2XBhG5TDsIX+AaGUsvHjx383c+bM0amto51dknUz
Ghs0aHBo/fr1ndMK5lbKUnBQqVSmBQsWjGnVqtUfKcuaLPgJkHQiVib7U0HWutX/WxHO5Tp16pzq
06fPWlvut3Tp0o8GDRq0nHP+r16ZuLg47fTp03/MyWQh1q6SlhHmufdze/fuvZIQ8q/scSEhIaW/
/PLLH9+8eeMjyzK1ZjlaSZKE77777tulS5d+wznPyLQv5fXr1z/q0qXLnkePHpUBSLol4+HhEfXH
H3+07t279wpCiC4D+8t2yY6VydPTM2TNmjWdx44d+71CoZCxq/39hQH9A2L5ofbt23fNjBkzJlFK
bdoN+i6cc6CU6vr37//TmjVrujs6OuqzvleblIvMmzdvtI+Pj13zVPv6+j5esmTJIKVSadOgRynl
I0aMWOzr6/uvhTUYY3DmzJkWp0+fbprDVbXpIAxLC/ybb76ZVrdu3aM82ZUYIcRh69atXzRp0uTW
xIkTV165cuUjvV7v+K59xcTEuI0aNWrdqlWrvoRMtKYFQTDt2LGjfZkyZR4le4wJgsDmzJkz4ccf
fxzg4ODwNoeOc7o451yhUMR06tRpw9GjR+s0a9bsSC5OOoSshF3uH5hk09JWvnr1qvCGDRvGM8aU
Nh7wlpzs5eX1Ys6cOWNbt269d+bMmVa9iDFGIRunVZqPA2eMxWzYsKFbt27dDsXHx/tl1/u9i7Oz
8+uff/65b/78+a1ZRSwz+0+YOnXqzDFjxvwK5qlU5s/aacGCBd8aDIazGo3GFslw0mQ0Gm1+i8fy
XVapVGJsbGzvtm3bng4JCamarJdFHR8f77tz587BO3fu/NzZ2Tm2R48eN5s3b76/bNmy91xcXOLi
4uLcrl27Vqdly5YDXr9+XQoy1/vC6tSpc/ZdtzA459CpU6c/z58/HzZ69Oh1ERER/pDFDG5ZIAOA
sW7duqe//vrrKWXKlHmAedrzDgzoH7AZM2ZMN5lMqi1btgy15Hu2FfNJ1fDRRx+dWL58eT9PT0+r
5z4zxkivXr1cIe0eJJt1C5YrV+7exo0bPxswYMCfsbGx/lnfo1W4s7Nz+OrVq3tUqVLlRnZdUHHO
Sdu2bfds3rz57LVr11olf+7Ro0dVt2/f3hMANmR3ZfV6vRNk4wWai4tL/Pz580f16tVrvyzLHime
pgDgmJCQ4HjhwoXWFy5caAr/H3BJzMuJZuWCw9CnT5+1mzdvTvWevWWKYN26dc8dP3689tKlSyf+
9ttvQ0RRdIQ0kuPYCiEkkXPOHBwc4ps3b36wW7dum+rWrXueEMJtmcEO2R8G9A+U+cQjA8D4q1ev
/rl06dIpFy5caCzLslsWd21SKpW6OnXqnOvVq9faFi1aHDQn0shQ1rOOHTs6QhoBwFapRJOV62pg
YOAnEyZM+OXWrVsNwMb3yVMhV6pU6epHH310LitzztNDCOGCILBnz54N+/TTT88mJCRokw+Qmz9/
/rdv3rw5ZOU645kWHx/vCtmcyKpOnToXV61a1WvcuHHrEhISfNOYu/2v++NZHYleqFChkEaNGh1P
bztzYI8CgC8jIiIWnzhxosWePXu6Xb16tZ4kSWrzRXVWjpEMACYAkAkhsre3d3jRokWDGjVqdLRK
lSo3qlevflWtVhuTJ4/C1nneggEdQa1atS4zxjoFBQWV+OGHH74+fPhwewBQpJcRLtkJ08Q5l728
vMK6dOmyuU+fPmsKFSr0knNOMhrMLWJiYtxz+jgULVo0aMeOHc0nTJiwavfu3X0JIdn5+1BcuHDh
kwMHDnQAgN3ZNRDJst/ChQs///rrr6dNmjRpHSQLaPHx8b4rVqwYJ8vyl9l5chdFUQnw/zTBqclK
azFZkDq8efPmtj179jyg0+kKZOOtJEuZ5S5dumzWaDTGjFyUeXh4RHbr1m0LY2xrVFSU1969ez+7
cOFC4/v371cxz6EXIKlnwfJH4P/B3nIxLgGApFQqDSVKlAjw8fEJLVu27N3q1atfqV69+jU3N7cY
Sil713cLB7/lPdjdgv5hOSE9e/asyF9//dXuxo0bdS5dutQgISEhZeuKW/7KlSt3t3LlyjeqVKly
vUOHDtsdHBwMlv1wzjPVAmCMkRIlSkRJkuT+rm3KlSt38fDhw/Wyo/6cc7JixYrxK1eunKzX6/PZ
aPcGSCVlZ9GiRe+eOHGiamrJdbKjbi1btjz/6NGjj5I/5+7uHnzy5Mla2ZkS9vfff/98ypQpv3DO
Hd4VZNu1a7d++fLlA21RzwsXLjQYMWLEptjYWG12zgV3cHB4c+rUqWo+Pj5hGfmuy7JMUmbrswzy
e/78ufbOnTvVXr58qY2NjfVIfqFDCGGCIMgFCxZ86eXl9bZmzZqX3NzcYjN74YzyFmyho38kOyGE
AMASxthSAICQkJBib9688TUPVANCCHdyckooV67cPZVKJR4+fPhd+8nUCUav1zuml81Mp9PZ9J5/
ynIzxhZ16NBh+y+//DLm999/HyDLsjPnXMhI+lvztoxSGt+jR48Njx49qnDjxo3GkKw7PyQkpOym
TZsGAcAvtq5Pavr167dq6tSpVSBZnvfY2NiCv/zyyyhZlmdlRyudMUaWLl3qCwA0rWNnMBiyfD85
2Wd45s6dO+169uy5Py4urrCtU6eaZ24YJkyYMLtgwYKvM/r6lMFclmVi+VcQhOcAkGM5AlDegS10
lOvcunWrWvv27S9AGktA+vn53b148WKl7CyHpbX35s2bAhs3bhyyffv2PhERET6QtJSmQClVmBfU
sPyOmPlPAgDm6OgYV79+/VNjx46dV7Zs2fu3b9+u2rFjx5Oc83+llvXw8Hh+5syZSu7u7rHZXR+j
0ahu2rTppZcvX1ZJ/hylNObIkSN1Spcu/Tg73nvOnDmzV69ePRneMSWMcw4NGzbcs3nz5o62eD/L
HPujR4+2GjFixAZRFD1sdQvFfHEgNWzYcP+6deu6qVSqLOWpZ4yRuLg4V5PJpPbw8IgSBAHngqNM
wRY6ynWePXtWDN4xwt3S0sqJ0bnJTqqhAPCtKIpzQ0NDCz548KBiQEBA6VevXmmNRqPGYDA4EEK4
Wq02aDQaQ5EiRYJq1qx5qVSpUg+dnZ11a9cm5YthjN3s1KnT1p07dw6FZBfT0dHRhWfPnj0PAIZn
d30YY8YlS5YM6dKlyxHG2D+jwTnn7uPHj19lMBha2noaG2OMjBo1qhAACO9qKdv6Xrelp4Exdmjv
3r3NVq5cOe7QoUMdzUubZmnAI+dcql279rGlS5cOzGrCHKPRqJo+ffrc7du39zWZTJrChQs/XbBg
wUgAOJvZTHfow4UBHeU6AQEBZeAdvUfJloDN8ROdOfGLpTv0YEZfTynlERERXx06dKiDwWAoYKkL
55zs3Lnz81u3bq2tWrXqzeysgzmoXx83btzcRYsWzQbztCnOOdy5c6f2jz/+OJkxNsfWgeTFixdF
IGm1seysXqr1BYA7jLEB9+/f//GHH3746tSpUy3h3cu4pokQIlWvXv3k+vXru7q4uCRkpWyMMfL9
999P2rJlyzBCiJP5OFUcMmTI1nv37rXinN/L0YOF3nuYKQ7lOnfu3KnBOU/zHro5N3iuwhgjlr93
bePh4RE1YsSIRZzzf+5VE0JAlmXndevWjcyJclJK+aBBg3729/f/1/KihBCHDRs2DHv+/LlN5+Jz
zsmTJ09Kp7edeUGebKtz+fLl765fv77bpk2b2pcvX/4cISQOADLyPZLKli17ef369V1tke3wwoUL
9deuXTsGAJwYY5ZjBTqdrlCfPn12Z2XFOPRhwoCOcg3GGImMjPQ6d+5ck/S6YB0dHXMsF721LEu0
pte67dOnzxoXF5d/DaQihMChQ4c6BgcHF8uJsjo5OelnzJjxFQD86769wWDIP3PmzPm2mhfPGCMX
L16sr9frXdLb1tHRMVvznVNKuSAIrFGjRicPHDjQ6MCBAx/36tVrZYECBR4TQuI553oAMHLORUga
ByFCUsDXA0BCgwYN9v/222+d3N3dYzM7eNAy+C0gIKDk8OHDfzWZTN4A/15WlXMOERERxfr167cz
MjLSKydz7qP3G3a5o1zBEkBOnDjRkjGWbtYulUqVG5aBzTBCCHdxcYlv27btzq1bt46CZBfVkiS5
f//99zMZY31z4t7pJ598cqRly5Z7Dh8+3BP+n4pUOHnyZMubN2/WAIBrqU2vygjOOTl+/HhLSD/V
qezm5had3XW2MAfkO7IsT5wzZ87E8+fPNzx//nzDoKCg0jqdzkmn0zk7OjrqlEqlWKZMmfuffvrp
3ipVqtzM6q0eQRB4WFhYgc6dO/8ZGxtb2JyrIbVNyZMnT6p+8cUXv23cuLEzpL5MLEL/ggEd5RrR
0dGeixcv/gasWBxDpVLlui53a1hyyD98+PCXbdu29UmZpvSvv/7q/Ndff+0AgH05UZbw8PBJ165d
qxMVFVUa4J8xCm7ff//9TEmS2gmCkKX58Y8ePSq7adOmwZDGjAUzWaPR5HivS7KW9inzX6q++uqr
LL8XY4y8fPmycO/evbc9f/68PAAo0umJUl65cqVp3759d0VHR/fw8PDIsQse9H7CrhyUK8iyLIwf
P37169evS1izub+/f5C9y5wVZcuWfdi0adPDKR/nnDvMmDHjx7i4uHS7qG3By8srcvTo0T9wzg3J
B6xdvny5wa5du7pYuogzwjKO4O3bt97Dhw//VZZlT2te5uPjE5oTdbYHWZZpTEyMR9euXQ8+efKk
Oli/OIvq6tWrTaZMmbIsvfEZCGFAR3b1+vXrgsePH28xYcKEVadOnfoUrMuNILu7u7/XrRXGGBk8
ePBSSKUrNTIy0s+c6CXbT96EEP7ZZ59ty58/f8oLJOd58+Z9Gx0dbU0w/qdOsizTJ0+elNm8eXP/
Hj16/BUSElIFrDvPsHz58mVrPnl7YYwRQgifM2fO3NDQ0JLpDfhMztyCVx4+fPizjRs3DsFpbCgt
GNCR3ciyTCdPnrx8wIABe/fs2TMA0u+WtWDlypW7Y+/yZ1WNGjWuVqhQ4QbAfxYIUW3YsGFYVFSU
V3aXgVLKXV1d47/++uvpABCX/LnIyMjCixcvnpqRQVlGo1Hdq1evHV999dXSx48f1wDr53zLlSpV
+ju762sve/bs6bJz586+nHN1RubcJ19IZ9asWQuPHj3aEuD/Y04QSg4DOrIbQRDY7NmzJzg5OUVC
xrIWymXLln2v5+haWlp9+/b9BQAMKU/yCQkJ+ZYvXz4hp8rTunXr/fXr1z8OSYt+AEBS9//WrVsH
/v3331Wt7S3QaDSG/v37r81IilwAAEdHxzitVvssp+qbky5fvlzfksc+K/thjLmMGTNm082bN6tj
Sx2lBgM6siutVvvszz//bOnt7R1i7WsKFiz4rGjRosH2LntWCYLA2rVrt7NQoUKBqTyt3rJly+CQ
kJCiOVWWxYsXD/f19f1nbjohBBhjHpMmTVoeFxeX7rK6lil7Q4cOXdarV69fwHw7wYrALtesWfNy
TtQzp507d67RkCFDthmNRvfM7sM8lS0BAECv1+fr27fv/rNnzzayd91Q7oMBHdkVIYSXL1/+3rx5
80YQQuKteInYvn37P7OaPzu3cHBwMPTs2XMDJK1j/Q/OOZhMJs+tW7f2y6myFChQ4M3ChQtHEUL+
NT4hICCg8pw5c2ZZ282rVCql2bNnT+7SpcsGANBbkR1O161bt00Aeasr+fr167UGDRq0PS4uzjcr
+3Fycnrzyy+/9FYqlZGcc4iJifEdNGjQjhs3btTEgXIoOQzoKFf45JNPDk+cOHEmISTN+baUUv1n
n332u73La0tdunTZSin9VxpRc6uW7N27t7vRaEx3Gp+t1K9f/1znzp23QFJSFUtZHHbt2tXt8ePH
6WZ7A0gaG6FQKOR58+aNa9OmzR/wjjnUliQqnp6eYU2bNj1qTVKe98WbN298Bg0atC0xMdE7K/uh
lOoWLVo0qGXLlvt++umngYIgxFFKwWAweE2ePPkXnU7nlFeOGco6DOjIrpJnVxs+fPhPffv2XQ5J
a4en2lVbunTp+8WLFw/M6PvkZvnz539Ts2bNq6k9FxYWpj1+/HiLnCoLpZRPnTr1W1dX15fJ74PL
suy8Zs2aEdYMkLPM7VapVOIPP/wwsn79+kcAIDFlS928BKmuU6dOfzo4OBhyqo7ZydJa/uabb36I
ioryy+LujGPHjp3VokWLgwAArVu33jdlypQZYL5ACggIqDJ27Ng1JpNJaZlhYO/6I/vCLwDKNQgh
/JtvvpnWsmXLnQBgtOS3Tva8oWPHjn9kx5rd9tagQYPjYL6QSY5zrlm/fv3InOpWZYwRLy+vyD59
+qwlhCQPwo67du3qHhQUVDwj+3N0dExctmzZIK1W+xCSlpb9ByEEBEGIGzx48IqcqFtO2bJlS79D
hw51hkwm7jJfRBl79Ojxy8iRIxclf27IkCHLe/TosRoATIQQOHr0aMcvv/xycU6sPohyPwzoKNew
5Nr+8ccfh7Rv334zpdQA8M/UHbFMmTLXe/To8au9y5kd9W7btu1OSul/MqURQuDatWsfnzt3rmFO
lYVSyocMGbLCz8/vUfLnGGOeY8eOXZ2QkOCUkX16eXlFLVu2bJCrq+vzFO8V+f3334/19fUNy4m6
ZTfGGNmyZUv/6dOnrwDrp2CmJnHAgAE/zpkzZ4JCoZDNx+qfK6vZs2dP6t+//48AoCeECH/++Wf/
adOm/WgymXLs1gxCCFlNkiShQ4cOR7VabUKRIkWiRowYsSajgeR9YRnYVKdOnXtarZan/PP39+e9
evXak5ODnxhj5Pr16zUKFy4ck6IssV9++eX3mRmMde7cufpFixYN1Wq1kSVKlHixdevWXnlhQJfl
WGzatGmAv7+/LrXPMAN/prFjx65K6/gyxogkScKgQYO2FC5cWNRqtbJWq9WNHTv2Z1mWaV44pihz
8INHuY4sy1QQBPby5ctCZ8+ebVyjRo0rJUqUCMyrg38sJ+CePXvuu3jxYpuUz5vvZetOnTpVuUiR
IsFZWSwlo+X6/vvvp69cuXIaADhaHlcoFG927tzZqmrVqrcysj9ZlsmtW7eqBQUFlWzcuPEJLy+v
iJyqS3Yfp3PnzjUaMGDADlEUrc6sl5oCBQrcP3DgQBNvb++3nHOS2u0ly/clPDzcp127dsfDwsLK
QlJva8LChQuHduvWbWtWF9VB7ycM6AjZmeUCZt68ed/8/PPPX0Lqi9NIY8aM+WrcuHELcurChjFG
4uPjXdq0aXP82bNnNZM9JZYpU+bSwYMHmyqVSsnex89eLIE1JCSkWLt27U7Ex8dnah1583x/UKvV
4b///nvbdw2QTE1AQEDJzp07/xUTE1Occw5eXl6B+/fvb1ywYMFXefUCGL0b3kNHyM4EQWCMMeLn
5/cckmVqS0Gxffv2zxljOfab5ZwTFxeX+Llz506klEYkGyCnfPToUeVVq1aNtPexyw1mzZr1XUJC
Qn4r5tunijEGlNLYRYsWfVGtWrXrGXlt8eLFA5cuXToIzOvaR0ZG+o8YMWK9Xq93zMh+UN6AAR2h
XIBSyvPnzx9GCHnnCP7Xr18XyanBcQBJFxqUUl6/fv1z48aNm0cpTZ7r3W3JkiVTHj16VAYgbyWE
ySjzqnmmzL5erVZHLF68eHD79u13Z2a99YYNG57u1q3bRkiaJaG8detW/T59+uyIiIjI9rUAUO6C
AR2hXMLHxycsnVae459//tknp4Mn5xyGDx++tFKlSv/qCjaZTPnmzJkz50MN5pYZAb179944aNCg
ZZRSfSb2Ef/DDz8M7dChw07LPjNaBgCAHj16bAIAo/lhh+vXrzfs1avXrujoaHd7HyeUczCgI5RL
aDSaRID/rLz2L2fOnGkmiqLVy2/agiAIXBAEefTo0QvB3LVreer8+fONzpw50+hDvl9rScZjzopn
VUvdPNdc6t69+7q2bdvuyUzLPLmKFSveFgQh+Xs7PHz4sMagQYO25mSmQWRfGNARyiW8vLwiAdJe
zCQhIcH9+fPnmRp8lRWUUt60adNjDRs2PAL/bwkC59z1q6+++j4uLs4lJ9Zvz62USqW0YMGC0TVq
1DhFCElMb3vOOS9SpMjtqVOnzrTc2rBBMVIef8fr16/XX758+TiAD/u2yIcCAzpCuYRarTYAwDtP
7OaWO71//34le5SPUsrnzJkzydXV9Z9lTjnnyhcvXpT46aefJmZkudS8hjFGHB0d9WvWrOlZokSJ
25AsF35qPDw8gtetW9fD3d091sq3SFNQUFAJxth/em445y7Lly+ffOHChfr2PkYo+2FARyiXSC99
pzlgCn///Xd1e7W2/P39ny9dunSYQqEIT1Zu97Vr1446cOBAW3uUKTew3E/38vKK2rJlS8eSJUu+
c46+j4/Po61bt7YvWbJkgC3emzFGzp492xQAUr0VI8uy5xdffPHb/fv3K2ArPW/DgI5QLvH27dv8
kH5uCOH169eF7VnORo0anRo/fvxcQkhcsla5x4QJE1YEBQUV+5C73gGSFttZs2ZND1dX1xcpn3N3
d3+2ZcuW9uXLl7+fmX2nJjEx0WHDhg1fcM7fOVUtNjbWb/jw4RuMRqMag3rehQEdoVxCp9M5W7GZ
EBYWVsieg9DMud5/rlq16kWebASfwWAoMGXKlKWc8w/2vMIYI5RSXqRIkZCVK1f2UalUUZbnCCEJ
S5cuHVCiRIkAWwVVWZbJwYMH2798+bJY8tXxUqF49uxZqVWrVo3inOMa6nnUB/vDQyi3iYmJ8QAr
sjeGh4cXsPcJWaFQSN9///1opVL5xvIYIURx5cqVekeOHGn5obbSLRda5vn7ZxYvXjxQEIQoAND3
6dNnZYMGDU4l3y6rEhMTHRctWjQdAJws68unwXnXrl09bPn+KHfBgI5QLmHuck9XaGior71PyJxz
UqJEicDu3btvAoAEy+OEEMelS5dOkmU5U0uH5iWUUt66det9M2fOnOjv7/9g4sSJcy2P2+o9fvrp
p6mhoaHFrNycPHv2rFhYWJivvY8Nyh4f/I8Oodzi8ePH5cCKFjrnnJhMJqVKpRKt2G22sCwaEhUV
Nf/UqVONX758WQ0ABABQPXz4sMrcuXO/YYx9Ze8LD3szp/Xd2KlTp+1OTk46Wx6Pq1ev1u7evftw
AHDIwMsUT548KQMAr+x9bJDtYUBHyM4s3eft27f/GFJfmOVfOOdCRESENwCE2rvsnp6e0ffu3evf
rl27o5IkFTQ/7LZx48ZhFSpUuAMAf9q7jPZmDuIJWd6RmSzLRJZlRYcOHRaIouie0emClnvoH/rF
Vl6EXe4I5QKiKCpDQkKKQVIrN02EEJ6burTLlSv3YPz48fMBIMbyGOfc89tvv50XGhpq9/v9eQ0h
BDZs2DD03r17tQkhNKOLwri7u0djMM+bMKAjlAvcuHGjZlxcnNVraQuCkGuWLaWU8qFDh/5co0aN
85As9WlcXFyhqVOnLklvfj2ynizL9Pbt21UXLlz4LQBoANLOLJiSQqEwlC1b1mZT5lDuggEdoVzg
jz/+6AdWdLebcU9Pzygrt80RSqVSWrRo0ShnZ+fnyVaMU586dar53r17O9q7fHmBLMskJibGY9So
UeuNRqPVF3/J1alT57xarTZm5rUo98OAjv6RsmsUu0pzxps3bwocO3asFQCordicU0ollUqV6eU6
s0vRokWfLlu2bCilNCLZw67Tp0//ITAwsIS9y/e+i42N9ejfv//258+fl81kml1d9+7dfwXA33Ze
lWvuw6GcZxkYwxgjnHMSGxvrdvjw4Y/u379fKSQkpESLFi3Kly9f3k+WZYExRimlTKVSmYoXL/6k
RIkSj8qVK3e3WLFiAaVKlXrk4+MTZrkvJ8syEQQB79Glw3JS/frrr8cnJCR4W3uS9vPze2HVhjnM
PPf8dM+ePTf+9ttvIwHAEQCoTqfzmTNnzmxZlntZRscj6zHGiNFoVPft2/e3v//+ux5Y35PzL7Vr
1z7bunXrvQA4Dz2vwqu0D9zz58+1+/fv73TmzJnmV69erWde4IFAUmuRpJF9SoakBSgYAMi+vr6v
GjRocKxq1arXP/7441OFCxfOlUEnN2GMkdDQ0IKNGjW6bjQaC1j5MrlatWqndu3a1Tw3npRlWSbh
4eE+zZs3PxMbG1vK8jghJGrt2rU9Pvnkk6P2LuP7xHLR9/PPP49ZsGDBPDDfN88opVIZs3v37kbl
y5e/SwjhufG7g7IOW+gfEMvJgXNOQkNDCy5evPjLBg0a9JJl2YEQkurCDmm0GgVINiI7NDS0zLZt
20pt27ZNpJSaGjVqFFSjRo0LHTp0+LN27doXKaUMW2f/tWzZsklGo9HD2u0JIVKhQoWe59YTsiAI
nDH2ZtasWdPGjh27lnPuAQDAOff48ssvF0VERDTy9vaOtHc53xecc3Lnzp0qy5YtmwwZDObJLsal
rl27bqxQocKd3Pq9QbaBAf0DwjknOp3OadmyZRN/++23oXq9Ph8AUBsue0kBQM0YUwcFBVUJCgqq
sn379j4FChR4+fHHH5+4cuXKlooVK97WaDSGD/3Ewhgjt27dqm4eDGfNvXMASDpJFypU6EVunkdM
KeWSJO09f/58o+3btw+CpMQnJCwsrPjXX3+9QJKkoQqFQrZ3OXMzy+cbFhbmO2TIkC16vd7aHpx/
WH7Xnp6ez6dMmTLT3nVC2Q8Deh5nOTEYjUbV6tWrh/7yyy9jo6Ki/ABAZUXu50yznEw45y6hoaFl
//zzz7J//vlnP1dX1+jmzZsfOHjw4F/169c/4+bmFmfvY2QPUVFRXiNHjtzAGHPL4EtZrVq1LuXW
YG6hUCjkxMTEyWFhYQXPnz/finPuSAhxOHDgQJdSpUo9ZIwtzu11sCdKKb9z507lzp07b37z5k1x
QgjJ6G+VEAKMMcO33347yVbrrqPcDe+h53GMMZKYmOgwZMiQzefOnWsNmRxQkw30Go0moVGjRkc+
//zztXXq1LmoVCpzzdzq7GIZgDhhwoSfd+/e3ZdzbnXrHABAoVDEXL16taS3t3dERl5nL/Hx8c7t
2rU7FhQUVMfyGCEkat26dd2bNWt2zN7ly60YY2Tbtm29pk6duhIAXDJz4c05Z+3atft12bJlA/Hi
6cOAAT2Pi4qK8uzfv/8ft27dagIAQjpLLNqLrmLFijfHjRs3p379+mc0Gk2enSfLGCP79u3rNGbM
mA2cc5eMvJZzDhUrVjx/6NChj+1dD2vJskwfPnxYrmPHjscsA/845+Dt7f3o1KlTdbDl+G6yLNNV
q1aNWrBgwSwAcM3o6/Ply/fk8OHD9fPly/fW3nVBOQPnoedRjDESHR3t0atXr923bt1qBOYBbLkw
mAMAON29e/fjAQMG7GncuPHNFStWjAkLCyvAGCOWZTjf9+U4GWOEMUYuXrz48aRJk1ZmNJgDABBC
jJ999tkWe9clIwRBYOXKlbvfr1+/VQCgN9cDIiMjCy1fvnwczod+N0EQ2LBhw5aaj50hI610Qohu
0aJFw7y8vCLwGH848IPOo0RRVPTp02fnhQsXWkLu6WZPl7kHwSAIgqF3795rBg4cuLJw4cLPBUFg
7+v8dks3+8WLFz8ePHjw7zqdzjeTF1ZxV65cKePr62v3RVkyKjY21rVFixYnX79+XRXMDQmNRvPq
4MGDjYsVKxb4Pn6uOcESjHv06HHg8uXLn3DOlem9hhCSOHz48HmTJk2awzknOLvkw4Et9DxIFEXF
2LFjV1+4cKEFmAe/5aSsDLQzl1Ujy7L7r7/+Oqlhw4Z3O3XqdGzx4sVTHz58WOF9am1YyiqKonLx
4sVf9u/ff69er89wMDdvLw8cOHD5+xjMAQBcXFziN23a1CN//vwPLI8ZDAbfIUOG/BYZGelt7/Ll
VpRSTinly5cv71emTJlrPP0fl9i7d++VEyZM+I5SyjGYf1jem5MjSp8lgOzdu/ezMWPGbAQAJ1vs
N+VoeEKIyDmX4P8XhJxzDpRSxhhTEkIEzrktp8MBADDOualy5crXBw4cuLR58+YHNRpNYm5v2el0
OseRI0duOH78+GeEkHRXUksN5xzc3NxeHD9+vFb+/PnfvI8DnCzfzb///rtqz549dycmJmrN36nE
ypUrn9+xY0cbtVqd69LZ5hbmW2iePXr0OPjo0aPa7xoL06RJk53r1q3risljPkwY0PMQWZYpIYS3
bNnywqNHj+racNeMEKJzd3ePrF+//smSJUs+Ll68+BNnZ+cEd3f3aACAmJgYj4SEBOeQkJDi4eHh
BZ4+fVrs2rVrdRMTE50haXqkQ1YLkezCwujq6vq2Xbt2f7Zr125nbp3GdfPmzRqTJ09e/vjx4xqZ
DebmepuGDx8+Jy90oTLGyPbt27tNmjTpF/j/QK/4ESNGLJg0adJ3ufFzzA0st23CwsJ8u3bteuTF
ixflU27j5ub24sCBAx8XLlz4+fv+PUGZgwE9D2GMkWPHjrUcPHjwdrBN6zxRqVTqOnXq9Ef37t1/
rVy58i1rE4JYTkBBQUEl/v777+o3b96sdfHixUYvXrwoKsuyA5jv62dlLnzS1FyeWLly5Rtdu3bd
0K5du50uLi7x9ggKlhYopZTHxMS4L1myZMrGjRtHMMacs7p8aMGCBe8dP368jrOzsy6n65UdZFmm
06dPX7h169YvICnfOygUircbNmzo0rBhwzP2Ll9u9/jx4zJdu3Y9GhMTU9jy2xEEIWHjxo3tGjZs
eMre5UP2gwE9D5FlmXbp0uXIjRs3mmUxYYzO0dExpnfv3usGDhy4ysfHJ8zyhLXBMnmAS/7/Q0ND
C548ebL5rl27et67d6+KwWBww5paLQAAE7ZJREFUNaedzfR30XxRYHR0dIyrVavW+e7du29o0KDB
SQcHh8ScaqWYR+QL+/fv7zRnzpzvIiIi/MEGiZsIIQmrV6/u2qJFi79yoh45JTExUdO6devjgYGB
dSHp1g3z8fF5cPTo0Y89PDxi7F2+3IwxRv7+++/q3bt3/8tgMHhTSvVz5swZ2bNnz40AuPDKhwwD
eh5y9uzZRr169dpPCHHO5C4kNze3V4MGDVrWq1evjZY1t7PjBGFJeHPz5s2aly9f/vjChQuNb968
WRsAlJD1Ufmii4tLdL169U5Wq1btcrly5e7WrFnzooODg8HW9QAAuHXrVvWTJ0+2OHjwYOfAwMAK
5jpkGSFE7Nix4/pFixYNz2vdp4wx8uDBg3LdunXbGx8fX9xc34SOHTtuWrx48UgMSunbv39/hwkT
Jqz68ssvZ/Tr129tbk4HjHIGBvQ8ZMyYMat3797dlxCiykQL3VCkSJF769at61msWLGgnBpUk3wJ
15CQkGIHDx7ssHPnzl4hISElAcA5q4lwOOcyIYQJgmCsXr36perVq1+uUaPGpYoVK/7t4eERqVQq
xYzU03IhEhAQUGbfvn2dDx061P7169f+kHQRYpNAbuHr63vv6NGj9fJyetwzZ8406Nev33ZZlvMD
ABBCoufPnz+yR48eW+1dtvdBcHBwcX9//5C8dsGHMgcDeh7AGCMmk0lVpUqVIL1eXygTu0hs1qzZ
vsWLFw91d3ePTdldntNEUVTcunWr+tKlS6eeO3fuE8aYU1ZHzJvzWgMhhAOASCllbm5uMQULFnxZ
rFixxx4eHpElS5Z86OHhESkIgkwI4YwxyjkniYmJDvfv368cFhZWKDAwsExoaKhvQkKCM5h7E7Ij
+56Tk9Or7du3typXrty9vNzqYoyR9evXD5o9e/ZCzrkbAICDg8Pzffv2fVK6dOkn9i5fbma5GJZl
mWJARwAY0PMEc/dl+U8//fQiAGQ0naixa9euaxcsWDAmt62AZanXb7/9NujAgQNdEhIS8nHOlcmC
s63fUgKAlMGTm/9UkEO/F6VSGfnzzz/3btas2ZG8HMwtGGNkypQpi7dt2zaIEOLIGGNly5a9tGPH
jtaurq7x9i4fQu8LDOh5AGOM7N27t7N57rljBl5qatWq1bYVK1b0z23BPHndAAD0er3jzp07u//6
669DAwICKpgH0uWp1QLNFyixK1eu/Lxly5YHAZLSf9q7XDlBp9M59u3b9/crV640I4QoOeeGTp06
bV68ePHID+UYIJRVeeqE+CELCAgoAxn8PCtXrnzhp59+GkIpzbUnzGQtVB0ArBNF8dcbN27UPHHi
RIvTp0+3ePz4cXlI1iuRnUvCZjNesGDBB8uWLetfrVq16ynqnuc5OTnpY2Ji+nTv3n33gwcP6hBC
VLt37+7p7e39VpblbzGoI5Q+DOh5AKWUDx06tDRk7POM++qrr6ap1Wrj+xQ4zEusXgKAS7Isz3ry
5EnpdevWDd+1a1cvSZKczZnq7F3MjDKWLFny1oYNG7r5+fm9APiwgrmFu7t7bEhIyIBWrVqd0uv1
WgBQrlmzZlTp0qUfAsAf9i4fQrkd5nLPA8yD4tSQgVsodevWPVejRo2r9i57VupMCOGlS5d+9MMP
P4w6ffp0lQkTJkzz8/O7DwDZMj0tmyR27Njx1507d7b08/N7wTn/oKce+fv7P1u6dOlQQkgsJJ2f
3ObPn/9NeHh4PnuXDaHcDgN6HpCJAJAwdOjQnzL52lzBsmiFpfxarfb5mDFjFp05c6bqpk2bWjds
2HAPpTSecy7au6zJJes9kPLlyxe4evXqLkuWLBnq7u4ei4tpJH2uzZo1OzZjxowvASAOAGhERIT/
6NGj15hMJptOC0Qor8Eu9zxCoVBYHbhKlCjxoGHDhiff12CeznGQGjRocOrjjz8+HRISUmzPnj1d
9u7d2/XZs2fFIWmamcae5eOcmwghxg4dOvwxa9asic7Ozgn2Pma5jXkq1upXr175rV+/fhQAOF68
eLHJuHHjViYmJo7KrgRBCL3vMKDnAYwxMmfOnKcAIEP6n6m+S5cuW8zzsfOcZBcpHAACGWPzx40b
tyAkJKTYkSNH2ly+fPnjS5cufWwymZwgKcArkw+ks9WgulT2oy9QoMDzVq1a7evUqdPvFStWvJ0X
L6hsRRAEJknSN0qlUly9evVYzrnj/v37u75+/bpwSEjIsKJFi4bYu4wI5Tbv3egh9F+MMbJr165u
48eP3wDptEAJIdFXrlwp974uw5nZ45M8p7xer3e8efNmzTNnzjQ7duxY26dPnxbnnCsopWobz283
UEqNdevWPTd48OAl9evXP6NQKCSA9/dWR06yrB44efLkn7Zv3z6Ac+5ICDF6eHg8//PPP9uVKlUK
E88glAwG9DyAMUbu379fsXXr1hfhHausWVqM/v7+t06fPl3jQ71XmzILniiKipcvXxa+detWzfPn
zzd5+PBhpWfPnhWNj493Nwd2BQAIkP5vhQOAiRAiubu7R5QtW/Ze48aND7do0eKAVqt9Zu5GJrl9
/fbcxHK8oqOj3du0aXP0xYsXlSEpwY9YokSJ69u3b2/j5eUVZe9yIpRbYJd7HlGqVKlHHh4eb6Kj
o4ul9ryl+9fPz+/lhxrMAf7bMjZPgwsx//0JkLQSWEhISPHHjx+Xe/nypTYsLKxgQkKC69u3b30S
ExMdLcuhUkqZi4tLnLe39xsnJ6eEUqVKPaxcufKN0qVLP1QoFFLK98JgnjGW4+Xh4RETHBzcrVu3
bnvCwsIqEEKUgYGBVTp37nwwMDDw8xIlSgTau6y5gSzLhBACmA4WofccY4xMnz79B61WK2q1Wv6O
P3Hy5MlL7V3W95mlhf+u59J6HmWeOQ1w2fLlywdotVqTVquVCxcunFi9evUHV65cqS3L8gc/Y0ev
1ztMmzZt8cKFC6frdLqMZIxECOU2Dx48KFesWLHINAK68dtvv51r73IilFGWC6V79+6V/+ijj25o
tVqjv78/12q1YrFixd7OnTv3mw81iDHGyLVr12o1adLkilar1Wu12sRmzZpdffz4cRl7lw3lrA/+
qjYvKVOmzMPOnTtvgaTR7qkhuTnNK0LvYrl9Ua5cuQc7d+5s7evr+5BzzgkhClmWvX/55ZfJ/fv3
3xYTE+Nm77LmFMYYEUVRsXz58vFdunQ5HhgYWAsAHABA8+TJk5pdu3Y9HBgYWAJ7jT4cGNDzmNGj
R3/v6OgY/o6nqTmjHELvJc458fHxebNw4cLRlNJIzrllfIjj5cuXm7Vv3/74jRs3qtu7nDkhLi7O
dciQIVt/+OGHBYyx/6yyGBMT49+/f/+dsbGx7vYuK8oZGNDzEEopL1iw4KsOHTr8CQByKvOphZiY
GA97lxOhzBIEgVFKeYMGDc7OmjVrkjlFrCWoa0JCQqp169bt0JIlS8bnxcxylnEar1+/LtirV6/9
J06c6AxJszD+g3MOz58/L7NgwYKvGWMExxnkffgB50ETJkyY4+XlFULpvz9ezjmYM6Yh9F5jjJHe
vXv/Om3atOmEkNhk33UqimL+RYsWfduhQ4fDL168KJwXupwtgZxzTnbs2NGjZcuWV+7du1cf0plO
yRhT/fHHH/0vXrz4sb3rgLLfe/9FR6m7detWtcGDB/8eHh5eKvnjGo3mzbVr10q5ubnF2buMCNnC
rl27Ok+fPv1HnU7nZ3nMnHdBdHV1DR00aNCy3r17b/D29o60d1kzy2QyKffu3dt5/fr1I+/fv18L
Mjjl2MnJKWzevHkjO3TosNPedUHZBwN6HiXLMg0MDCzZpUuXozExMdpkTyV+++23o/v377/W3mVE
yBYYY+Tu3buVevbsuTc+Pt6fc54y21+Ci4tLxKBBg1YOHjx4pbOzsy559sDcKHmvwr179ypNmzZt
6b1792pxzjO1FgHnHARBiF+8ePGg9u3bb8ecCHkTBvQ8ypI28+rVq3X69u27JzExMb/luapVq57a
vXt309x8QkMoIxhj5OTJk82GDh26RRTFdy21Gp8vX77XM2bMmNG2bdvd/2vv/mOrqg44gJ/7yo9S
y+hsFRxNi6siEFxAR2Ag3VbmcJJsNlPUPxbXaCaQDNgSNC78sdmwgK6jS7a4CWRgFt0G/lh1qDFg
UiAiTsVOK9AMEFyRBqXAymul7979UZp0CzqVlte1n89/7+a+d899ee987znn3nOGDBmS+VQHuYAy
mUyqoaFham1t7X319fU3ZDKZi0I4/7UGhg8ffnzDhg3fnjlz5vZsnyO9T6APcHEcRxs3brz9nnvu
+V2SJPlnN3esWbOmcu7cuc9mu3zQW+I4jp5//vkbly5dujadTo/5mF3/NXny5N1VVVW/mTdv3l/y
8vLS2Sx396xu3a3y7du3f622tvYnr7322lfiOM4LvVxPFxYWHnzmmWeuGzt27D+zed70PoE+CGQy
mdSSJUserqurq0qSJBVFURg3btzuzZs3X5efn9+W7fJBbzk7ycr0RYsW/b6lpeWLURQN+6h9kyRJ
FxYWHr3pppv+eMcdd6wpLS09kK1eq+bm5rGbN2/+ztNPP33r66+/Pj2EMPwcQwe98hUVFhbuf+qp
p75RWlr6TjbOlb4j0AeBOI6jU6dOjbzzzjs37tq165shhBBFUcf8+fMfXrly5VJzPjNQdI+Nv/fe
e2NWrFhRXVdXd2uSJCP/R1d1Z05OTtvVV1+9e8qUKbumT5++Y9KkSX8vKSk52Nv/je5WeHNzc3FT
U9OE+vr6OTt37izfs2fP5EwmMyL07foaZ6699tr61atX3zVu3LiDfXgcskSgDyLpdDq3qqpq044d
O74VRVEqhHB64cKFq+69995q4+kMNJlMJvXQQw8tfuCBB36WJMnnPmFrNxNC6AwhxGPGjGmuqKjY
PGHChLeuvPLKPVddddVbRUVFxz7Jh/Rc1e/o0aOjm5qaJrz99tuT33jjjS9v27ZtzvHjxy8OXfXv
sHABFsnKyck5dffdd9csW7as+uxrF/EDkEAfRDKZTHT69OmLFi9evG7Lli2VIYShURSdqq2traqs
rPQ4CwNK93PbTzzxxC3Lly+vSafTYz/N+8+26pMQwpkQQhJFUVxUVNQyceLEhhEjRqQLCgo+GDVq
1PGhQ4eeiaIoyWQyOR0dHbmtra2fb21tvfjYsWOX7tu3b0J7e3tu6Kpro9A1NWvPz+/z7yE/P//o
6tWrq66//vrnure5gB+YBPoglE6nc++///6fb9q06XsdHR0jhw0bdmLDhg3fnTVrljtfGXDiOI6a
mprGL1my5LeNjY3TQ49Q7SU9w7Ff1KlRFIU4jjOFhYXvrF279rZrrrnmbyEI8oGuX/z4uLC6uwMP
Hz5csmzZsl/v3LlzdkFBwbFNmzbdOH78+H3ZLh/0hba2tryampr71q9fv6Czs7Mo2+XpY6crKir+
umrVqh9ecsklLYJ8cBDog1wmk0m9/PLLM9avX/+D3bt3T3nssccqy8rKDmS7XNAX4jiOXn311Wkr
V6786SuvvFIeQrjoQnV9XwhRFHXMmDHjxYULF/6ivLx8awha5YOJQCeE0BXse/bsmbBu3bpFK1as
+PGIESM+zHaZoC90j61v3779q9XV1Sv27t07NYqizzQDW39w9oIkk5+f31JdXf2jysrKP4cgyAcj
gc5/aGtryz106FDpxIkT92a7LNBXukO9s7NzyOOPP37bgw8+uPz9998vCSH8vy0v3Jmbm3ti/vz5
65cuXbqyqKjoWM877LNdOC4sgQ4Mei0tLZfW1NTc9+STT96eTqcLQgjD+2BSl/PyXxPNnMnNzW29
+eab/7Bo0aJfFhcXv5vt8pF9/esXC5AF3S32I0eOfOHRRx/9/iOPPHLXyZMni0LXHfH9YpnpKIri
JEnac3Jy2isrK/+0YMGCX5WVlTWF4Llyugh0gB7iOI7a29tzt2zZMreuru6WXbt2zWptbS1KkqTX
51X/hNKjR49+d/bs2VvmzJnz7MyZM+tHjRp1IpVKJf191TguLIEO8BHiOI7iOE5t27bt61u3br3h
hRdemNfc3FwauurOoSGEnD447IchhExZWdneWbNmba2oqHiuvLx8a39eHY7+QaADfISe65KffZ1q
aGiY2tDQMLWxsfFLb7755pTGxsZJSZKkkiTJCV3d893TuX5c/ZoJXcEdhxDiVCrVedlllzVPmzbt
pWnTpr00e/bsF4uLiw+lUqm4uyUeghvd+HgCHeA8nDp1Kn///v1XHD58uLSlpWX0gQMHrjhy5MjY
tra2kSdPnhx15syZoe3t7cPz8vLSBQUFH+Tl5Z0eOXLkibKysn3FxcWHLr/88n+UlJQcLCgoaDUW
zvkQ6ACfQc/x654t6HNtP5dzvTfb5wQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEAv+DeS
kLXz0x3g2QAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMy0wMy0yMVQxMzowODowMiswMTowMPDX900A
AAAldEVYdGRhdGU6bW9kaWZ5ADIwMjMtMDMtMjFUMTM6MDg6MDIrMDE6MDCBik/xAAAAHnRFWHRp
Y2M6Y29weXJpZ2h0AEdvb2dsZSBJbmMuIDIwMTasCzM4AAAAFHRFWHRpY2M6ZGVzY3JpcHRpb24A
c1JHQrqQcwcAAAAASUVORK5CYII="
                  />
                </svg>
              </Box>
            </m.div>

            <Box
              component={m.div}
              animate={{
                scale: [1.6, 1, 1, 1.6, 1.6],
                rotate: [270, 0, 0, 270, 270],
                opacity: [0.25, 1, 1, 1, 0.25],
                borderRadius: ['25%', '25%', '50%', '50%', '25%'],
              }}
              transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
              sx={{
                width: 100,
                height: 100,
                position: 'absolute',
                border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
              }}
            />

            <Box
              component={m.div}
              animate={{
                scale: [1, 1.2, 1.2, 1, 1],
                rotate: [0, 270, 270, 0, 0],
                opacity: [1, 0.25, 0.25, 0.25, 1],
                borderRadius: ['25%', '25%', '50%', '50%', '25%'],
              }}
              transition={{
                ease: 'linear',
                duration: 3.2,
                repeat: Infinity,
              }}
              sx={{
                width: 120,
                height: 120,
                position: 'absolute',
                border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
              }}
            />
          </>
        )}
      </StyledRoot>
    </>
  );
}
