import { Container } from '@mui/material';
import CustomBreadcrumbs from 'components/custom-breadcrumbs';
import LoadingScreen from 'components/loading-screen';
import { useSettingsContext } from 'components/settings';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { PATH_DASHBOARD } from 'routes/paths';
import { ProductAddEditForm } from 'sections/@dashboard/product';
import { useGetProductById } from 'services/productServices';
import BlankPage from '../BlankPage';

export default function ProductEditPage() {
  const { themeStretch } = useSettingsContext();
  const { id } = useParams();
  console.log('id', id);

  const { data, isLoading, isError } = useGetProductById(id);

  if (isLoading) return <LoadingScreen />;

  if (isError) return <BlankPage />;

  console.log('data111', data);

  return (
    <>
      <Helmet>
        <title>Product: Edit Product</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Create a Edit Product"
          links={[
            {
              name: 'Dashboard',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Products',
              href: PATH_DASHBOARD.product.list,
            },
            { name: data[0]?.title },
          ]}
        />
        <ProductAddEditForm isEdit currentProduct={data[0]} />
      </Container>
    </>
  );
}
