function isBlobURL(url) {
  return url.startsWith('blob:');
}

export const getFormattedImageURL = (image = '') => {
  if (image) {
    if (isBlobURL(image)) {
      return image;
    }
    const imageName = image?.split('/')?.pop();
    const S3_BASE_URL = process.env.REACT_APP_S3_BASE_URL;
    const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_FOLDER_PATH;

    const formattedImage = `${S3_BASE_URL}/${S3_BUCKET_NAME}/${imageName}`;
    console.log('first', formattedImage);
    return formattedImage;
  }
  return image;
};
